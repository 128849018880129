<template>
  <div>
    <div style="height: 60px"></div>
    <div
      class="flex items-center justify-center"
      style="
        padding: 0 20px 20px 20px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 60px;
        background-color: #fff;
      "
    >
      <van-button class="flex-1" round @click="$router.go(-1)">取消</van-button>
      <div style="width: 20px"></div>
      <van-button
        :loading="confirmLoading"
        class="flex-1"
        type="info"
        round
        :disabled="!editable"
        color="#aa341b"
        @click="$emit('save')"
      >保存</van-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {Button} from 'vant';

Vue.use(Button);

export default {
  props: {
    confirmLoading: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style lang="scss" scoped></style>
