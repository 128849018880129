<template>
  <div class="tab-body">
    <div class="list-group-item box bac-white">
      <CommonHeader
        title="微信绑定状态"
        :source="$t('feedback_data_hao_online')"
        :no-feedback="true"
      />
      <van-loading v-if="weChatBindLoading" type="spinner" class="loading" />
      <div v-else class="bind-status">
        <div class="bind-item">
          <div class="bind-label">微信绑定：</div>
          <div v-if="pendingUnbindApproval" class="bind-value">
            <img class="check-icon" src="@/assets/lundbeck/icon-check-warning.png" />
            <span>
              申请解除绑定
            </span>
            <Button
              v-if="isDM"
              class="unbind-button"
              type="primary"
              size="mini"
              @click="approveUnbindWechat"
            >
              批准解除
            </Button>
            <Button
              v-if="isDM"
              class="unbind-button"
              type="danger"
              size="mini"
              @click="declineUnbindWechat"
            >
              拒绝
            </Button>
          </div>
          <div v-else-if="isWeixinBind" class="bind-value">
            <div class="flex-detail">
              <img
                class="check-icon"
                src="@/assets/lundbeck/icon-check-yes.png"
              />
              已绑定
              <span v-if="weixinBindDate" class="bind-date">
                {{ weixinBindDate }}绑定
              </span>
            </div>
            <Button
              v-if="!isDM"
              class="unbind-button"
              type="danger"
              size="mini"
              @click="unbindShow = true"
            >
              解除绑定
            </Button>
          </div>
          <div v-if="!isWeixinBind" class="bind-value">
            <img class="check-icon" src="@/assets/lundbeck/icon-check-no.png" />
            未绑定
          </div>
        </div>

        <div class="bind-item">
          <div class="bind-label">知情同意：</div>
          <div v-if="pendingUnconsentApproval" class="bind-value">
            <img class="check-icon" src="@/assets/lundbeck/icon-check-warning.png" />
            <span>
              申请取消签署
            </span>
            <Button
              v-if="isDM"
              class="unbind-button"
              type="primary"
              size="mini"
              @click="approveUnsignConsent"
            >
              批准取消
            </Button>
            <Button
              v-if="isDM"
              class="unbind-button"
              type="danger"
              size="mini"
              @click="declineUnsignConsent"
            >
              拒绝
            </Button>
          </div>
          <div v-else-if="isConsent" class="bind-value">
            <div class="flex-detail">
              <img
                class="check-icon"
                src="@/assets/lundbeck/icon-check-yes.png"
              />
              已签署
              <span v-if="consentDate" class="bind-date">
                {{ consentDate }}签署
              </span>
            </div>
            <Button
              v-if="!isDM"
              class="unbind-button"
              type="danger"
              size="mini"
              @click="unSignShow = true"
            >
              取消签署
            </Button>
          </div>
          <div v-if="!isConsent" class="bind-value">
            <img class="check-icon" src="@/assets/lundbeck/icon-check-no.png" />
            未签署
          </div>
        </div>

        <div class="bind-item">
          <div class="bind-label">内容发送：</div>
          <div class="bind-value"><span class="value">{{ stat.receive_no ? stat.receive_no : '-' }}</span></div>
        </div>

        <div class="bind-item">
          <div class="bind-label">内容打开：</div>
          <div class="bind-value"><span class="value">{{ stat.open_no ? stat.open_no : '-' }}</span></div>
        </div>

        <div class="bind-item">
          <div class="bind-label" @click="shareQrCode">邀请</div>
        </div>
      </div>
    </div>
    <div class="list-group-item box bac-white">
      <CommonHeader
        title="线上互动历史"
        :source="$t('feedback_data_hao_online')"
        :no-feedback="true"
      />
      <van-steps direction="vertical" :active="-1">
        <van-step
          v-for="(itemList, index) in partitionNewsList(pageShowArr)"
          :key="index"
        >
          <div style="text-align: left; color: #333">
            {{ itemList?.[0]?.formatDate }}
          </div>
          <ul class="activity-list">
            <li
              v-for="(item, sonIndex) in itemList?.map((item) => ({
                label: item.content.content_type,
                content: item.content.content_name,
                date: item.formatDate,
                status: item.type,
                duration: item.duration,
                send_time: item.send_time || item.open_time,
              }))"
              :key="sonIndex"
            >
              <span :class="{'type':true, 'type-content-open':item.status === '打开', 'type-content-send':item.status === '发送'}">
                {{ item.status }}
              </span>
              <label v-if="item.send_time" style="font-weight: initial; color: #111;">
                {{ item.send_time.slice(11,16) }}
              </label>
              <div v-if="item.status === '打开'" class="duration-container">
                浏览 <span class="open-value">{{ formatTime(item.duration) }}</span>
              </div>
              <br />
              <div class="info-details flex-detail flex justify-between">
                <span>{{ item.content }}</span>
              </div>
            </li>
          </ul>
        </van-step>
      </van-steps>
      <Nodata
        v-if="!pageShowArr.length"
        :loading="openListLoading && sendListLoading"
        :empty-txt="$t('no_data')"
      />
      <!-- <Button class="tip-button" round type="info" @click="openContentDialog()">
        发送更多内容
      </Button> -->
      <div style="height: 10px"></div>
    </div>
    <van-dialog
      v-model="unbindShow"
      title="解除绑定"
      theme="round-button"
      confirm-button-color="#aa341b"
      show-cancel-button
      cancel-button-color="#bbbbbb"
      close-on-click-overlay
      @confirm="unbindWechat"
    >
      <div style="padding: 0 20px 20px 20px">
        <div class="dialog-close" @click="unbindShow = false">×</div>
        <div style="margin-top: 20px; text-align: left">
          请确认您是否要解除绑定该医生与已绑定微信的关联？<br /><br />解除绑定操作不能恢复，需要重新向该医生发送内容并通过微信好友转发，医生打开确认后，将再次绑定。
        </div>
      </div>
    </van-dialog>
    <van-dialog
      v-model="unSignShow"
      title="取消签署"
      theme="round-button"
      confirm-button-color="#aa341b"
      show-cancel-button
      cancel-button-color="#bbbbbb"
      close-on-click-overlay
      @confirm="unsignConsent"
    >
      <div style="padding: 0 20px 20px 20px">
        <div class="dialog-close" @click="unSignShow = false">×</div>
        <div style="margin-top: 20px; text-align: left">
          请确认您是否要取消签署该医生的知情同意？
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import CommonHeader from '@/components/CommonHeader';
import {Button, Loading} from 'vant';
import Nodata from '@/components/Nodata';
import * as py from '@/api/Physicians';
import {
  getPhysicianWechatDetail,
  getAllContentSend,
  getAllContentOpen,
  getContentStats,
} from '@/api/content';
import {groupBy} from 'lodash';
import {formatterDatenEn} from '@/filters/index';
import * as wx from '@/api/wechat';
import * as moment from 'moment';
import { weixinUnbindApply, weixinUnconsentApply, weixinUnbind, unsignConsent, weixinUnbindFinishApplication, weixinUnconsentFinishApplication } from '@/api/content';
import { createApproval, updateApproval, getSpecificPendingApprovals } from '@/api/approval';
import { getLocalStorage } from "@/utils";
import {wecomSDK} from '@/utils/wecom';

export default {
  name: 'WechatBind',
  components: {
    Nodata,
    Button,
    vanLoading: Loading,
    CommonHeader,
  },
  data() {
    return {
      // 数据
      stat: {},
      weixinData: {},
      isWeixinBind: false,
      isConsent: false,
      isDM: false,
      pendingUnbindApproval: false,
      pendingUnconsentApproval: false,
      weixinBindDate: '',
      consentDate: '',
      physician_id: '',
      weChatBindLoading: true,
      openListLoading: true,
      sendListLoading: true,
      pageShowArr: [], // 线上互动历史
      sendMsg: {},
      unbindShow: false,
      unSignShow: false,
    };
  },
  mounted() {
    if (getLocalStorage('territory_type') === 'DM') {
      this.isDM = true;
    }
    this.physician_id = this.$route.query.physician_id;
    this.getWeChatBind();
    // Get bind url
  },
  methods: {
    async shareQrCode() {
      await wecomSDK.shareQrCode();
    },
    getWeChatBind() {
      this.weChatBindLoading = true;
      this.weixinData = {};
      this.isWeixinBind = false;
      this.isConsent = false;
      this.pendingUnbindApproval = false;
      this.pendingUnconsentApproval = false;
      getPhysicianWechatDetail(this.physician_id)
        .then((res) => {
          if (res && res.data) {
            this.weixinData = res.data;
            if (res.data.open_id) {
              this.isWeixinBind = true;
              this.weixinBindDate = res.data.bind_time
                ? res.data.bind_time.substr(0, 10)
                : '';
              this.pendingUnbindApproval = res.data.unbind_pending_approval;
            }
            if (res.data.is_consent) {
              this.isConsent = true;
              this.consentDate = res.data.consent_time
                ? res.data.consent_time.substr(0, 10)
                : '';
              this.pendingUnconsentApproval = res.data.unconsent_pending_approval;
            }
          }
          this.weChatBindLoading = false;
          this.getList();
        })
        .catch((e) => {
          this.weChatBindLoading = false;
        });
      getContentStats(this.physician_id)
        .then((res) => {
          if (res.value && res.value.length) {
            this.stat = res.value[0];
          }
        });
    },
    getList() {
      this.sendListLoading = true;
      this.openListLoading = true;
      this.pageShowArr = [];
      // 用这两个接口：
      getAllContentSend(this.physician_id).then((res) => {
        this.pageShowArr = [
          ...this.pageShowArr,
          ...res.value.map((item) => ({
            ...item,
            type: '发送',
          })),
        ];
        this.sendListLoading = false;
      });
      getAllContentOpen(this.physician_id).then((res) => {
        this.pageShowArr = [
          ...this.pageShowArr,
          ...res.value.map((item) => ({
            ...item,
            type: '打开',
          })),
        ];
        this.openListLoading = true;
      });
    },
    openContentDialog() {
      this.$router.push({
        path: `/hcp/content`,
      });
    },
    partitionNewsList(list) {
      // 对每个项增加格式化日期
      const groupData = groupBy(
        list.map((item) => ({
          ...item,
          formatDate: formatterDatenEn(item.open_time || item.send_time),
        })),
        (item) => item.formatDate,
      );

      // 获取分组的键（日期）并按倒序排序
      const sortedKeys = Object.keys(groupData).sort((a, b) => {
        const timeA = new Date(a).getTime();
        const timeB = new Date(b).getTime();
        return timeB - timeA; // 按时间倒序
      });

      // 按排序后的键组织分组数据，并对组内数据按时间倒序排序
      const sortedGroups = sortedKeys.map((key) => {
        return groupData[key].sort((a, b) => {
          const timeA = new Date(a.open_time || a.send_time).getTime();
          const timeB = new Date(b.open_time || b.send_time).getTime();
          return timeB - timeA; // 按时间倒序
        });
      });

      return sortedGroups;
    },
    async unbindWechat() {
      const dmRes = await py.findUser({
        user_id: getLocalStorage('user_id'),
      });
      let manager_user_id = null;
      if (dmRes && dmRes.value && dmRes.value.length) {
        manager_user_id = dmRes.value[0].parent_user_id;
      }
      if (manager_user_id) {
        let physicianName = '';
        const physicianRes = await py.getPhysiciansDetail(this.$route.query.physician_id);
        if (physicianRes && physicianRes.value && physicianRes.value.length) {
          physicianName = physicianRes.value[0].physician_name;
        }

        console.log('apply for unbind');
        weixinUnbindApply(this.weixinData.physician_weixin_id).then(res => {
          // 创建approval记录
          createApproval({
            approval_type: 'unbind_weixin',
            status: 2,
            title: '微信解绑 - ' + physicianName,
            source_id: this.physician_id,
            approver_user_id: manager_user_id,
            submit_territory_id: getLocalStorage('territory_id'),
            submit_user_id: getLocalStorage('user_id'),
            submit_user_name: getLocalStorage('user_name'),
            submit_time: new Date(),
          }).then(res => {
            // 发送企业微信通知
            this.notifyDM('unbind');

            // 刷新
            this.getWeChatBind();
          });
        });
      }
    },
    async unsignConsent() {
      const dmRes = await py.findUser({
        user_id: getLocalStorage('user_id'),
      });
      let manager_user_id = null;
      if (dmRes && dmRes.value && dmRes.value.length) {
        manager_user_id = dmRes.value[0].parent_user_id;
      }
      if (manager_user_id) {
        let physicianName = '';
        const physicianRes = await py.getPhysiciansDetail(this.$route.query.physician_id);
        if (physicianRes && physicianRes.value && physicianRes.value.length) {
          physicianName = physicianRes.value[0].physician_name;
        }

        console.log('apply for unsign');
        weixinUnconsentApply(this.weixinData.physician_weixin_id).then(async res => {
          // 创建approval记录
          createApproval({
            approval_type: 'unsign_consent',
            status: 2,
            title: '取消知情同意 - ' + physicianName,
            source_id: this.physician_id,
            approver_user_id: manager_user_id,
            submit_territory_id: getLocalStorage('territory_id'),
            submit_user_id: getLocalStorage('user_id'),
            submit_user_name: getLocalStorage('user_name'),
            submit_time: new Date(),
          }).then(res => {
            // 发送企业微信通知
            this.notifyDM('unbind');

            // 刷新
            this.getWeChatBind();
          });
          this.notifyDM('unsign');
          this.getWeChatBind();
        });
      }
    },
    approveUnbindWechat() {
      console.log('unbind');
      weixinUnbindFinishApplication(this.weixinData.physician_weixin_id).then(res => {
        weixinUnbind({
          physician_id: this.physician_id,
        }).then(res => {
          this.updateApproval('unbind_weixin', 1);
          this.getWeChatBind();
        });
      });
    },
    declineUnbindWechat() {
      weixinUnbindFinishApplication(this.weixinData.physician_weixin_id).then(res => {
        this.updateApproval('unbind_weixin', 0);
        this.getWeChatBind();
      });
    },
    approveUnsignConsent() {
      console.log('unsign');
      weixinUnconsentFinishApplication(this.weixinData.physician_weixin_id).then(res => {
        unsignConsent({
          physician_id: this.physician_id,
        }).then(res => {
          this.updateApproval('unsign_consent', 1);
          this.getWeChatBind();
        });
      });
    },
    declineUnsignConsent() {
      weixinUnconsentFinishApplication(this.weixinData.physician_weixin_id).then(res => {
        this.updateApproval('unsign_consent', 0);
        this.getWeChatBind();
      });
    },
    formatTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      const hourPart = hours > 0 ? `${hours}小时` : '';
      const minutePart = minutes > 0 ? `${minutes}分` : '';
      const secondPart = `${remainingSeconds}秒`;

      return `${hourPart}${minutePart}${secondPart}`;
    },
    async notifyDM(unbindType) {
      let typeLabel = unbindType === 'unbind' ? '微信解绑' : '取消签署知情同意';
      let uName = getLocalStorage('user_name');
      const dmRes = await py.findUser({
        user_id: getLocalStorage('user_id'),
      });
      let manager_user_id = null;
      if (dmRes && dmRes.value && dmRes.value.length) {
        manager_user_id = dmRes.value[0].parent_user_id;
      }

      if (manager_user_id) {
        const physicianRes = await py.getPhysiciansDetail(this.$route.query.physician_id);
        if (physicianRes && physicianRes.value && physicianRes.value.length) {
          const physicianName = physicianRes.value[0].physician_name;
          const institutionName = physicianRes.value[0].institution_name;

          const url = `${window.location.origin}/sso?forward=approval_binding&forward_item_id=${this.$route.query.physician_id}`;

          // Insert message
          let message = {
            user_id: manager_user_id,
            message_title: `[申请${typeLabel}] ${uName}`,
            message_content: `您的同事${uName}申请为${institutionName ? institutionName + '的' : ''}专家${physicianName}${typeLabel}。请点击<a href='${url}'>此处</a>进行进一步操作。`,
            send_time: new Date().toISOString(),
            is_send: 0,
            send_failed_no: 0,
            is_read: 0,
          };
          await py.createMessage(message);
        }
      }
    },
    // 查找并更新审批记录
    async updateApproval(type, status) {
      let res = await getSpecificPendingApprovals(getLocalStorage('user_id'), type, this.physician_id);
      if (res.value && res.value.length > 0) {
        let approval = res.value[0];
        await updateApproval({
          approval_id: approval.approval_id,
          status: status,
          approve_time: new Date(),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  margin-top: 60px;
  margin-bottom: 60px;
}
.tab-body {
  min-height: 600px;
}
.bind-status {
  padding: 12px;

  .bind-item {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    font-size: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    .bind-label {
      color: #666;
      min-width: 80px;
      text-align: left;
    }

    .bind-value {
      color: #333;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .flex-detail {
        display: flex;
        align-items: center;
      }
      .check-icon {
        color: #00b075;
        margin-right: 5px;
        font-size: 16px;
      }

      .bind-date {
        color: #aa341b;
        margin-left: 8px;
        font-size: 10px;
      }
      .unbind-button {
        margin-left: 15px;
      }
      .unbind-pending {
        margin-left: 25px;
        font-size: 12px;
      }
    }
  }

  .bind-code {
    margin-top: 50px;
    margin-bottom: 50px;
    .title {
      text-align: center;
      font-size: 14px;
      color: #333;
    }

    .code-desc {
      text-align: center;
      font-size: 12px;
      color: #22201f;
      margin: 0px 50px;
      line-height: 1.5;
    }

    img {
      width: 200px;
      height: auto;
    }
  }
}

.list-group-item {
  // border-radius: 8px;
  margin-bottom: 12px;
  // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.value {
  color: #aa341b;
}
.send-value {
  color: #ff845f;
}
.open-value {
  color: #17a772;
}

.bac-white {
  background: #fff;
}
.check-icon {
  width: 18px;
  height: 18px;
}
.tip-button {
  height: 28px;
}
.dialog-close {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  cursor: pointer;
}
.duration-container {
  text-align: right; /* 右对齐 */
  float: right;      /* 将浏览时长部分浮动到右侧 */
  font-weight: normal;
  font-size: 10px;
  margin-top: 2px;
  margin-right: 10px;
  .open-value {
    font-size: 13px;
    color: #17a772;    /* 可以根据需求设置文字颜色 */
  }
}
</style>
