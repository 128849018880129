<template>
  <div class="w-full">
    <MultiSelectDisplay
      :list="valueList.map((item) => item.text)"
      @click="showPopup = true"
      @close="
        activeIds = activeIds?.filter(
          (activeIdItem) => activeIdItem !== valueList[$event].id,
        );$emit('input', activeIds)"
    />

    <van-popup v-model="showPopup" position="bottom">
      <div class="flex justify-between" style="margin: 10px 0">
        <span class="van-picker__cancel" @click="showPopup = false">取消</span>
        <span
          class="van-picker__confirm"
          @click="showPopup = false; $emit('input', activeIds)"
        >确认
        </span>
      </div>
      <van-tree-select
        :items="[
          {
            text: '',
            children: items,
            dot: true,
          },
        ]"
        :active-id.sync="activeIds"
        :main-active-index.sync="activeIndex"
        @click-item="$emit('input', activeIds)"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import {TreeSelect, Icon} from 'vant';
import MultiSelectDisplay from './MultiSelectDisplay.vue';

Vue.use(TreeSelect);
Vue.use(Icon);

export default {
  components: {
    MultiSelectDisplay,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: null
  },
  data() {
    return {
      showPopup: false,
      // items: [
      //   {
      //     text: 'Washington',
      //     id: 1,
      //   },
      //   {
      //     text: 'Baltimore',
      //     id: 2,
      //   },
      // ],
      activeIds: [],
      activeIndex: 0,
    };
  },
  computed: {
    items() {
      return this.options?.map?.((item) => ({
        text: item.label,
        id: item.value,
      }));
    },
    valueList() {
      return this.items?.filter?.((item) => this.activeIds.includes(item.id));
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (Array.isArray(value)) {
          this.activeIds = value;
        }
      }
    }
  }
};
</script>
Array.isArray
<style lang="scss" scoped>
::v-deep .van-sidebar {
  display: none;
}

::v-deep .van-tree-select__item--active {
  color: #aa341b;
}
</style>
