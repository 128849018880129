<template>
  <div></div>
</template>
<script>
import {getEnterpriseConfig} from '@/api/wechat';
import { getInfoByCode, getUserInfo, getUserRole, getUserTa } from '@/api/user';
import { getLocalStorage, setLocalStorage } from '@/utils/index';
import * as help from '@/api/help';
import loginMixins from '@/mixins/loginMixins';

export default {
  mixins: [loginMixins],
  data() {
    return {
      loading: false,
      params: {},
    };
  },
  created() {
    let code = this.$route.query.code;
    this.params = this.$route.query;
    console.log(this.$route.query);
    this.loading = true;
    if (code) {
      getInfoByCode(code).then(res => {
        // Fake open_id
        if (code === 'test0123') {
          res = {
            userId: 'MaQing2',
          };
        }
        if (res.userId) {
          getUserInfo(res.userId).then(res => {
            console.log(res, this.loginObj);
            if (res && res.value.length) {
              this.loginObj.userName = res.value[0].user_name;
              this.loginObj.userId = res.value[0].user_id;
              this.userList = res.value;
              this.loginFn('oauth2');
            } else {
              this.loading = false;
            }
          }).catch(e => {
            this.userError = JSON.stringify(e);
            this.loading = false;
          });
        } else {
          this.loading = false;
        }
      }).catch(e => {
        this.resError = 'error=' + JSON.stringify(e);
        this.loading = false;
      });
    } else {
      getEnterpriseConfig().then((res) => {
        if (res) {
          let appid = res.appid;
          let agentid = res.agentid;
          let redirectUrl = encodeURIComponent(
            window.location.origin +
              '/sso?' + new URLSearchParams(this.params).toString(),
          );
          let state = encodeURIComponent(`forward=approval_binding&forward_id=${this.params.forward_id}`);
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
        }
      });
    }
  },

  methods: {
    getUserIdKey(userId) {
      help.encryption(userId).then(res => {
        if (res) {
          setLocalStorage('user_id_key', res);
          console.log(this.params);
          if (this.params.forward) {
            switch (this.params.forward) {
              case 'approval_binding':
                this.$router.push({
                  path: "/hcp/detail",
                  query: {
                    physician_id: this.params.forward_item_id,
                    selectedLabel: 'wechat',
                  },
                });
                break;
            }
          } else {
            const isMsl = getLocalStorage('is_msl') === '1';
            if (isMsl) {
              console.log('isMsl', isMsl);
              document.title = 'KOL 360';
              this.$router.push({
                path: "/kol/home",
                query: {
                  user_id: res,
                  routeTxt: 'home'
                },
              });
            } else {
              this.$router.push({
                path: "/hcp/home",
                query: {
                  user_id: res,
                  routeTxt: 'home'
                },
              });
            }
          }
        }
      });
    },
  }
};
</script>
