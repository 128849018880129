import request from '@/utils/request';

/** 获取企业微信配置 /api/weixin/Config -> /api/weixin/QYWeChat/GetConfig */
export const getEnterpriseConfig = async () => {
  const path = '/api/weixin/QYWeChat/GetConfig';
  const res = await request.get(path);
  return res;
};

/** 获取微信 sdk 签名 /api/webMeetingApi/api/WeChat/CompanyWechatSign -> /api/weixin/QYWeChat/GetSignature */
export const getWecomSdkSign = async () => {
  const path = '/api/weixin/QYWeChat/GetSignature';
  const URL = window.location.href; // + + window.location.search;
  const data = {
    noncestr: Math.random().toString().slice(2),
    timestamp: new Date().getTime(),
    msg_type: 'extends',
    URL,
    // msg_type: "msg_type",
  };
  const sign = await request.post(path, data);
  const config = await getEnterpriseConfig();
  return {
    sign: sign.data,
    ...data,
    appid: config.appid,
  };
};

export const getWechatSdkSign = async () => {
  const path = '/api/weixin/Wechat/GetSignature';
  const URL = window.location.href; // + + window.location.search;
  const data = {
    noncestr: Math.random().toString().slice(2),
    timestamp: new Date().getTime(),
    url: URL,
  };
  const sign = await request.get(`${path}?noncestr=${data.noncestr}&timestamp=${data.timestamp}&url=${URL}`);
  return {
    sign: sign.data,
    ...data,
  };
};

/** 获取微信公众号配置 */
export const getWeChatConfig = async () => {
  const path = '/api/weixin/Wechat/GetConfig';
  const res = await request.get(path);
  return res;
};

// 微信登录
export function getInfoByCode(code) {
  return request({
    url: `/api/weixin/Wechat/GetOpenId?code=${code}`,
    method: 'get',
  });
}
