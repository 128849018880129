<template>
  <div class="w-full">
    <MultiSelectDisplay
      :list="valueList.map((item) => item.text)"
      @click="showPopup = true"
      @close="
        activeIds = activeIds?.filter(
          (activeIdItem) => activeIdItem !== valueList[$event].id,
        );
        $emit('input', activeIds);
      "
    />

    <van-popup v-model="showPopup" position="bottom">
      <div class="flex justify-between" style="margin: 10px 0">
        <span class="van-picker__cancel" @click="showPopup = false">取消</span>
        <span
          class="van-picker__confirm"
          @click="
            showPopup = false;
            $emit('input', activeIds);
          "
        >确认
        </span>
      </div>
      <van-tree-select
        :items="options"
        :active-id.sync="activeIds"
        :main-active-index.sync="activeIndex"
        @click-item="$emit('input', activeIds)"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import {TreeSelect, Icon} from 'vant';
import MultiSelectDisplay from './MultiSelectDisplay.vue';
import {flatten} from 'lodash';

Vue.use(TreeSelect);
Vue.use(Icon);

export default {
  components: {
    MultiSelectDisplay,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: null,
  },
  data() {
    return {
      showPopup: false,
      activeIds: [],
      activeIndex: 0,
    };
  },
  computed: {
    valueList() {
      return flatten(
        this.options?.map((parent) =>
          parent.children.map((childItem) => ({
            ...childItem,
            text: parent.text + ' > ' + childItem.text,
            parentId: parent.id,
            parentText: parent.text,
          })),
        ),
      ).filter((item) => this.activeIds.includes(item.id));
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (Array.isArray(value)) {
          this.activeIds = value;
        }
      },
    },
  },
};
</script>
Array.isArray
<style lang="scss" scoped>
::v-deep .van-tree-select__item--active {
  color: #aa341b;
}
</style>
