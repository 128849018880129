<template>
  <div class="swipe-wrapper">
    <header class="header">
      <p class="h-title e-ellipsis-l1">{{ title }}</p>
      <i class="cubeic-back" @click="back">{{ backtxt }}</i>
    </header>
  </div>
</template>
<script>
export default {
  props: {
    hcpData: {
      type: Object,
      default() {
        return {};
      }
    },
    title: {
      type: String,
      default() {
        return '';
      }
    },
    backtxt: {
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    back() {
      this.$emit('backFn');
    }
  }
};
</script>
<style lang="scss" scoped>
.swipe-wrapper {
  $height: 44px;
  $scroll-height: calc(100% - 44px);
  width: 100%;
  height: $height;
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  background: #f9f9fb;
  // z-index: 99;
  .header {
    color: #2c3e50;
    position: relative;
    height: $height;
    line-height: $height;
    text-align: center;
    background-color: #edf0f4;
    box-shadow: 0 1px 6px #ccc;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 5;
    .h-title {
      font-size: 14px;
    }
    .cubeic-back {
      font-size: 16px;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 15px;
      color: #aa341b;
    }
  }
  .scroll-container {
    height: $scroll-height;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>
