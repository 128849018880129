<template>
  <div>
    <div class="feed-back-fixed" @click="showPopupFn"><i class="cubeic-message"></i></div>
    <div v-show="showStatus" class="swipe-wrapper">
      <cube-popup ref="errorCorrectionPopup" type="my-popup" :mask="true" @mask-click="confirmErrorPopup('close')">
        <div class="cube-extend-popup-content">
          <slot>
            <div class="error-correction-popup">
              <p class="header">{{ $t('feedback_title') }}</p>
              <p class="module-name"><span class="name-title">{{ $t('content_module') }}</span><span class="highlight">{{ moduleName }}</span></p>
              <p v-if="dataSource" class="module-name"><span class="name-title">{{ $t('data_from') }}</span><span class="grey">{{ dataSource }}</span></p>
              <template v-if="!nextStatus">
                <div class="feedback-buttons">
                  <cube-button :outline="true" @click="selected = 'like_this'; nextFn()">{{ $t('like_this') }}</cube-button>
                  <cube-button :outline="true" @click="selected = 'no_like_this'; nextFn()">{{ $t('no_like_this') }}</cube-button>
                  <cube-button :outline="true" @click="selected = 'one_proposal'; nextFn()">{{ $t('one_proposal') }}</cube-button>
                  <cube-button :outline="true" @click="selected = 'one_error'; nextFn()">{{ $t('one_error') }}</cube-button>
                </div>
                <!-- <div class="footer prev-btn">
                  <cube-button class="cube-btn-cancel" @click="confirmErrorPopup('close')">{{ $t('btn_cancel') }}</cube-button>
                  <cube-button @click="nextFn()">下一步</cube-button>
                </div> -->
              </template>
              <!-- <div class="module-name">
                反馈类型：
                <van-radio-group v-model="selected" direction="horizontal">
                  <van-radio name="数据纠错" icon-size="16px" checked-color="#aa341b">数据纠错</van-radio>
                  <van-radio name="功能建议" icon-size="16px" checked-color="#aa341b">功能建议</van-radio>
                </van-radio-group>
              </div> -->
              <template v-if="nextStatus">
                <p v-if="nextStatus" class="sub-header">{{ $t(selected) }}</p>
                <div class="content">
                  <textarea v-model="feedbackContent" rows="8" class="textarea" style="-webkit-user-select: auto;" contenteditable="true"></textarea>
                </div>
                <div class="footer next-btn">
                  <!-- <cube-button class="cube-btn-cancel" @click="confirmErrorPopup('close')">{{ $t('btn_cancel') }}</cube-button> -->
                  <cube-button class="cube-btn-cancel" @click="nextStatus = false">{{ $t('btn_prev') }}</cube-button>
                  <cube-button @click="confirmErrorPopup('confirm')">{{ $t('btn_sure') }}</cube-button>
                </div>
              </template>
            </div>
          </slot>
        </div>
      </cube-popup>
    </div>
  </div>
</template>
<script>
import * as fb from '@/api/feedback';
import { Notify } from 'vant';
export default {
  components: {
  },
  props: {
    moduleName: {
      type: String,
      default() {
        return '';
      }
    },
    dataSource: {
      type: String,
      default() {
        return '';
      }
    },
    needDialogStatus: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  data() {
    return {
      showStatus: false,
      feedbackContent: '',
      selected: '',
      nextStatus: false
    };
  },
  computed: {
    userName() {
      return this.$store.state.user.userName || sessionStorage.getItem('userName');
    },
    userId() {
      return this.$store.state.user.userId || sessionStorage.getItem('userId');
    },
    doctorObj() {
      return this.$store.state.doctor.doctorObj;
    },
    activeLabel() {
      return this.$store.state.user.activeLabel;
    }
  },
  methods: {
    nextFn() {
      if (!this.selected) {
        Notify({ type: 'danger', message: this.$t('choose_feed_type') });
        return;
      }
      this.nextStatus = true;
    },
    showPopupFn() {
      this.selected = '';
      this.nextStatus = false;
      this.$store.dispatch('setDialogStatus', true);
      this.feedbackContent = '';
      this.showStatus = true;
      this.$refs['errorCorrectionPopup'].show();
    },
    closePopupFn() {
      if (this.needDialogStatus) {
        this.$store.dispatch('setDialogStatus', false);
      }
      this.showStatus = false;
    },
    addFeedBackFn() {
      // if (!this.feedbackContent) {
      //   Notify({ type: 'danger', message: '请填写你的反馈' });
      //   return;
      // }
      let data = {
        url: window.location.href,
        page_param: this.doctorObj.physician_name,
        feedback: this.feedbackContent,
        user_id: this.userId,
        user_name: this.userName,
        module: this.moduleName,
        feedback_type: this.$t(this.selected),
        feedback_time: new Date()
      };
      fb.addFeedback(data).then(res => {
        if (res.is_success) {
          Notify({ type: 'success', message: this.$t('submit_sucess') });
        }
        this.closePopupFn();
      }).catch(e => {
        Notify({ type: 'danger', message: this.$t('submit_fail') });
        this.showStatus = false;
      });
    },
    confirmErrorPopup(isClose) {
      if (isClose === 'close') {
        this.closePopupFn();
      }
      if (isClose === 'confirm') {
        this.addFeedBackFn();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.feed-back-fixed {
  font-size: 33px;
  color: #aa341b;
}
.swipe-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: #fff;
  z-index: 99;
  ::v-deep .cube-popup {
    position: absolute !important;
  }
}
.error-correction-popup {
  width: 300px;
  background-color: #ffffff;
  padding: 15px 10px;
  border-radius: 3px;
  .header {
    color: #333333;
    font-size: 14px;
    text-align: center;
    padding: 10px 0;
  }
  .content {
    font-size: 13px;
    margin-top: 10px;
    ::v-deep .cube-checkbox-group {
      .cube-checkbox {
        padding: 0 !important;
      }
    }
  }
  .footer {
    display: flex;
    align-content: center;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 30px;
    .cube-btn-cancel {
      color: #333333;
      background-color: transparent !important;
      border: 1px solid #cccccc;
    }
    &.prev-btn {
      ::v-deep .cube-btn {
        width: 45%;
      }
    }
    &.next-btn {
      ::v-deep .cube-btn {
        width: 45%;
      }
    }
    ::v-deep .cube-btn {
      padding: 6px 0;
      font-size: 12px;
    }
  }
}
.textarea {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 4px;
}
.module-name {
  color: #333333;
  font-size: 12px;
  text-align: left;
  .name-title {
    margin-top: 5px;
    white-space: nowrap;
    vertical-align: top;
    display: inline-block;
  }
  .highlight {
    margin-top: 5px;
    color: #aa341b;
    vertical-align: top;
    display: inline-block;
  }
  .grey {
    margin-top: 5px;
    color: #666;
    vertical-align: top;
    display: inline-block;
  }
}
.feedback-buttons {
  margin-top: 20px;

  .cube-btn {
    margin: 10px 0;
    padding: 10px;
    font-size: 14px;
  }
}
.sub-header {
  color: #000000;
  font-size: 14px;
  text-align: center;
  margin-top: 15px;
  font-weight: bold;
}
</style>
