<template>
  <van-button v-bind="addButtonProps" @click="$emit('click')"><slot></slot></van-button>
</template>

<script>
import {Button} from 'vant';
import Vue from 'vue';

Vue.use(Button);

export default {
  data() {
    return {
      addButtonProps: {
        plain: true,
        color: '#aa341b',
        size: 'small',
        round: true,
        style: {padding: '0 14px'},
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
