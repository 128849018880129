<template>
  <div class="parting">
    <div>
      <div style="margin-top: 20px">
        <CommonHeader
          title="医生分型"
          icon-url="/lundbeck/NBA/physician.png"
          :no-feedback="true"
        >
          <div slot="before">
            <img
              v-if="!isPhysicianFirst && canEditStatus"
              src="@/assets/lundbeck/NBA/edit.png"
              alt=""
              width="16"
              style="margin-top: 6px"
              @click="openPhysicianDialog()"
            />
          </div>
        </CommonHeader>
        <div v-if="isPhysicianFirst">
          <p class="tip-text">请先回答问题来确认医生分型</p>
          <van-button
            v-if="canEditStatus"
            class="tip-button"
            round
            type="info"
            @click="openPhysicianDialog()"
          >
            立即进行问答
          </van-button>
        </div>
        <div v-else>
          <div>
            <p class="parting-type-y-top">品牌认可度</p>
            <div class="parting-type">
              <div class="parting-type-y">
                <div class="parting-type-y-axis">
                  <div class="parting-type-y-left">
                    <p class="parting-type-y-text">高</p>
                    <p class="parting-type-y-text">中</p>
                    <p class="parting-type-y-text">低</p>
                  </div>
                  <img src="@/assets/svg/yAxis.svg" alt="" />
                </div>
              </div>

              <div class="parting-type-cake">
                <div
                  :class="
                    physicianSelectedParting === 'CA'
                      ? 'parting-type-selected'
                      : 'parting-type-unselected'
                  "
                >
                  <div v-if="physicianSelectedParting === 'CA'">
                    <img :src="star" alt="" width="9" />
                  </div>
                </div>
                <div
                  :class="
                    physicianSelectedParting === 'CB'
                      ? 'parting-type-selected'
                      : 'parting-type-unselected'
                  "
                >
                  <div v-if="physicianSelectedParting === 'CB'">
                    <img :src="star" alt="" width="9" />
                  </div>
                </div>
                <div
                  :class="
                    physicianSelectedParting === 'CC'
                      ? 'parting-type-selected'
                      : 'parting-type-unselected'
                  "
                >
                  <div v-if="physicianSelectedParting === 'CC'">
                    <img :src="star" alt="" width="9" />
                  </div>
                </div>
                <div
                  :class="
                    physicianSelectedParting === 'BA'
                      ? 'parting-type-selected'
                      : 'parting-type-unselected'
                  "
                >
                  <div v-if="physicianSelectedParting === 'BA'">
                    <img :src="star" alt="" width="9" />
                  </div>
                </div>
                <div
                  :class="
                    physicianSelectedParting === 'BB'
                      ? 'parting-type-selected'
                      : 'parting-type-unselected'
                  "
                >
                  <div v-if="physicianSelectedParting === 'BB'">
                    <img :src="star" alt="" width="9" />
                  </div>
                </div>
                <div
                  :class="
                    physicianSelectedParting === 'BC'
                      ? 'parting-type-selected'
                      : 'parting-type-unselected'
                  "
                >
                  <div v-if="physicianSelectedParting === 'BC'">
                    <img :src="star" alt="" width="9" />
                  </div>
                </div>
                <div
                  :class="
                    physicianSelectedParting === 'AA'
                      ? 'parting-type-selected'
                      : 'parting-type-unselected'
                  "
                >
                  <div v-if="physicianSelectedParting === 'AA'">
                    <img :src="star" alt="" width="9" />
                  </div>
                </div>
                <div
                  :class="
                    physicianSelectedParting === 'AB'
                      ? 'parting-type-selected'
                      : 'parting-type-unselected'
                  "
                >
                  <div v-if="physicianSelectedParting === 'AB'">
                    <img :src="star" alt="" width="9" />
                  </div>
                </div>
                <div
                  :class="
                    physicianSelectedParting === 'AC'
                      ? 'parting-type-selected'
                      : 'parting-type-unselected'
                  "
                >
                  <div v-if="physicianSelectedParting === 'AC'">
                    <img :src="star" alt="" width="9" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img src="@/assets/svg/xAxis.svg" alt="" style="width: 100%;" />
              <div class="parting-type-x">
                <p class="parting-type-x-text" style="width: 50px">
                  医院停控药
                </p>
                <p class="parting-type-x-text" style="width: 70px">
                  指标远未完成/ 政策限制严格
                </p>
                <p class="parting-type-x-text" style="width: 50px">
                  已完成/ 政策宽松
                </p>
              </div>
              <p class="parting-type-x-end">政策状况</p>
            </div>
          </div>
          <div class="parting-type-history">
            <div class="history-title">
              <div class="title-flag"></div>
              <span class="title-text">分型历史</span>
            </div>
            <div class="history-content">
              <div
                v-for="item in showPhysicianPartingHistorys"
                :key="item.physician_typing_physician_id"
              >
                <div class="history-date">
                  <div class="date-flag"></div>
                  <span class="date-text">{{ getDate(item.create_time) }}</span>
                </div>
                <div class="history-text">
                  <p>{{ item.result }}</p>
                  <p class="modified-name">
                    {{
                      item.modify_user_name
                        ? item.modify_user_name
                        : item.modify_user_id
                    }}
                  </p>
                </div>
              </div>
              <div v-if="showExpand">
                <div
                  v-if="!physicianHistoryExpandStatus"
                  class="expend-btn"
                  @click="expendPhysicianHistory"
                >
                  <span class="arrow-down-flag"></span>
                  展开
                </div>
                <div v-else class="expend-btn" @click="expendPhysicianHistory">
                  <span class="arrow-up-flag"></span>
                  收起
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              !(
                physicianSelectedParting.indexOf('AA') > -1 ||
                physicianSelectedParting.indexOf('AB') > -1
              )
            "
            class="parting-type-action"
          >
            <div class="action-title">
              <div class="title-flag"></div>
              <span class="title-text">行动建议</span>
            </div>
            <div>
              <div class="action-plan">
                <!-- 高认可度-->
                <div v-if="physicianSelectedParting.indexOf('C') === 0">
                  <!-- 推动观念提升 -->
                  <div>
                    <div class="action-progress">
                      <p class="action-progress-text">
                        <img
                          src="@/assets/lundbeck/NBA/concept.png"
                          width="16"
                          alt=""
                        />
                        推动观念提升
                      </p>
                    </div>
                    <p class="action-text">
                      <span class="action-flag"></span>
                      强化
                      <span v-if="physicianSelectedParting.indexOf('C') === 1">
                        易倍申
                      </span>
                      <span v-else>美金刚</span>
                      是AD患者ABC
                      <span class="action-text-emphasize"> 全面症状管理 </span>
                      的优选
                    </p>

                    <div class="proof-link">
                      <h4>建议使用资料</h4>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/专家幻灯：从《阿尔茨海默病ABC全面管理中国专家共识》看盐酸美金刚片的治疗优势_1663940521000.pdf'
                            )
                          "
                        >
                          专家幻灯：从《阿尔茨海默病ABC全面管理中国专家共识》看盐酸美金刚片的治疗优势
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/AAIC 2021 —如何优化阿尔茨海默病的治疗_1663940468000.pdf'
                            )
                          "
                        >
                          专家幻灯：AAIC2021-如何优化阿尔茨海默病的治疗
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/《中国老年期痴呆防治指南》解读-V1.0-20220531_1663940432000.pdf'
                            )
                          "
                        >
                          专家幻灯：2021中国老年期痴呆防治指南解读
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/科室会幻灯：易倍申ABC全面管理的优选_1663940505000.pdf'
                            )
                          "
                        >
                          科室会幻灯：易倍申®ABC全面管理的优选
                        </a>
                      </p>
                    </div>

                    <p class="action-text">
                      <span class="action-flag"></span>
                      强化
                      <span v-if="physicianSelectedParting.indexOf('C') === 1">
                        易倍申
                      </span>
                      <span v-else>美金刚</span>
                      是AD患者改善
                      <span class="action-text-emphasize">
                        BPSD症状的基础
                      </span>
                      用药
                    </p>
                    <div class="proof-link">
                      <h4>建议使用资料</h4>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/专家幻灯：诊疗阿尔茨海默病-关注BPSD_1663940540000.pdf'
                            )
                          "
                        >
                          专家幻灯:诊疗阿尔茨海默病-关注BPSD
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/AD认知障碍的治疗策略_1663940484000.pdf'
                            )
                          "
                        >
                          专家幻灯：AD认知障碍的治疗策略
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/3.科室会 易倍申AD精神行为症状的治疗优选-0324_1663940449000.pdf'
                            )
                          "
                        >
                          科室会幻灯：易倍申AD精神行为症状的治疗优选
                        </a>
                      </p>
                    </div>
                  </div>
                  <!-- 院外处方/购药 -->
                  <div>
                    <div class="action-progress">
                      <p class="action-progress-text">
                        <img
                          src="@/assets/lundbeck/NBA/prescription.png"
                          width="16"
                          alt=""
                        />
                        院外处方/购药
                      </p>
                    </div>
                  </div>
                  <!-- 提升分子份额 (政策宽松没有)-->
                  <div v-if="!(physicianSelectedParting === 'CC')">
                    <div class="action-progress">
                      <p class="action-progress-text">
                        <img
                          src="@/assets/lundbeck/NBA/molecule.png"
                          width="16"
                          alt=""
                        />
                        提升分子份额
                      </p>
                    </div>

                    <p class="action-text">
                      <span class="action-flag"></span>
                      强化美金刚是AD患者ABC
                      <span class="action-text-emphasize"> 全面症状管理 </span>
                      的优选
                    </p>

                    <div class="proof-link">
                      <h4>建议使用资料</h4>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/专家幻灯：从《阿尔茨海默病ABC全面管理中国专家共识》看盐酸美金刚片的治疗优势_1663940521000.pdf'
                            )
                          "
                        >
                          专家幻灯:
                          从《阿尔茨海默病ABC全面管理中国专家共识》看盐酸美金刚片的治疗优势
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/AAIC 2021 —如何优化阿尔茨海默病的治疗_1663940468000.pdf'
                            )
                          "
                        >
                          专家幻灯：AAIC2021-如何优化阿尔茨海默病的治疗
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/《中国老年期痴呆防治指南》解读-V1.0-20220531_1663940432000.pdf'
                            )
                          "
                        >
                          专家幻灯：2021中国老年期痴呆防治指南解读
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/科室会幻灯：易倍申ABC全面管理的优选_1663940505000.pdf'
                            )
                          "
                        >
                          科室会幻灯：易倍申®ABC全面管理的优选
                        </a>
                      </p>
                    </div>

                    <p class="action-text">
                      <span class="action-flag"></span>
                      强化美金刚是AD患者改善
                      <span class="action-text-emphasize">
                        BPSD症状的基础
                      </span>
                      用药
                    </p>
                    <div class="proof-link">
                      <h4>建议使用资料</h4>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/专家幻灯：诊疗阿尔茨海默病-关注BPSD_1663940540000.pdf'
                            )
                          "
                        >
                          专家幻灯:诊疗阿尔茨海默病-关注BPSD
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/AD认知障碍的治疗策略_1663940484000.pdf'
                            )
                          "
                        >
                          专家幻灯：AD认知障碍的治疗策略
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/3.科室会 易倍申AD精神行为症状的治疗优选-0324_1663940449000.pdf'
                            )
                          "
                        >
                          科室会幻灯：易倍申AD精神行为症状的治疗优选
                        </a>
                      </p>
                    </div>

                    <p class="action-text">缔结话术：</p>
                    <div class="proof-link">
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        对于新病人以及经济条件一般的病人可以使用美金刚中标品单药或联合多奈哌齐进行治疗，美金刚20毫克/天是疗效的保证。
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        对于使用多奈哌齐治疗的AD患者，有BPSD症状的，建议联合美金刚20毫克治疗，美金刚联合多奈哌齐，患者更多获益。
                      </p>
                    </div>
                  </div>
                  <!-- 提升产品份额 (防停控没有)-->
                  <div v-if="!(physicianSelectedParting.indexOf('A') === 1)">
                    <div class="action-progress">
                      <p class="action-progress-text">
                        <img
                          src="@/assets/lundbeck/NBA/Ebixa.png"
                          width="16"
                          alt=""
                        />
                        提升产品份额
                      </p>
                    </div>
                    <p class="action-text">
                      <span class="action-flag"></span>
                      强化易倍申是AD患者ABC
                      <span class="action-text-emphasize"> 全面症状管理 </span>
                      的优选
                    </p>

                    <div class="proof-link">
                      <h4>建议使用资料</h4>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/专家幻灯：从《阿尔茨海默病ABC全面管理中国专家共识》看盐酸美金刚片的治疗优势_1663940521000.pdf'
                            )
                          "
                        >
                          专家幻灯:
                          从《阿尔茨海默病ABC全面管理中国专家共识》看盐酸美金刚片的治疗优势
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/AAIC 2021 —如何优化阿尔茨海默病的治疗_1663940468000.pdf'
                            )
                          "
                        >
                          专家幻灯：AAIC2021-如何优化阿尔茨海默病的治疗
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/《中国老年期痴呆防治指南》解读-V1.0-20220531_1663940432000.pdf'
                            )
                          "
                        >
                          专家幻灯：2021中国老年期痴呆防治指南解读
                        </a>
                      </p>

                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/科室会幻灯：易倍申ABC全面管理的优选_1663940505000.pdf'
                            )
                          "
                        >
                          科室会幻灯：易倍申®ABC全面管理的优选
                        </a>
                      </p>
                    </div>

                    <p class="action-text">
                      <span class="action-flag"></span>
                      强化易倍申是AD患者改善
                      <span class="action-text-emphasize">
                        BPSD症状的基础
                      </span>
                      用药
                    </p>
                    <div class="proof-link">
                      <h4>建议使用资料</h4>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/专家幻灯：诊疗阿尔茨海默病-关注BPSD_1663940540000.pdf'
                            )
                          "
                        >
                          专家幻灯:诊疗阿尔茨海默病-关注BPSD
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/AD认知障碍的治疗策略_1663940484000.pdf'
                            )
                          "
                        >
                          专家幻灯：AD认知障碍的治疗策略
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/3.科室会 易倍申AD精神行为症状的治疗优选-0324_1663940449000.pdf'
                            )
                          "
                        >
                          科室会幻灯：易倍申AD精神行为症状的治疗优选
                        </a>
                      </p>
                    </div>

                    <p class="action-text">缔结话术：</p>
                    <div
                      v-if="physicianSelectedParting.indexOf('B') === 1"
                      class="proof-link"
                    >
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        对于需要合理用药的老年患者建议首选易倍申单药或者联合多奈哌齐进行治疗，易倍申20毫克/天是疗效的保证。
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        对于使用多奈哌齐治疗的AD患者，有BPSD症状的，建议联合美金刚20毫克治疗，美金刚联合多奈哌齐，患者更多获益。
                      </p>
                    </div>
                    <div
                      v-if="physicianSelectedParting.indexOf('C') === 1"
                      class="proof-link"
                    >
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        对于新病人可以使用易倍申单药或联合多奈哌齐进行治疗，美金刚20毫克/天是疗效的保证。
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        对于使用多奈哌齐治疗的AD患者，有BPSD症状的，建议联合易倍申20毫克治疗，易倍申联合多奈哌齐，患者更多获益
                      </p>
                    </div>
                  </div>
                </div>
                <!-- 中认可度-->
                <div v-if="physicianSelectedParting.indexOf('B') === 0">
                  <!-- 推动观念提升 -->
                  <div>
                    <div class="action-progress">
                      <p class="action-progress-text">
                        <img
                          src="@/assets/lundbeck/NBA/concept.png"
                          width="16"
                          alt=""
                        />
                        推动观念提升
                      </p>
                    </div>
                    <p class="action-text">
                      <span class="action-flag"></span>
                      强化
                      <span v-if="physicianSelectedParting.indexOf('C') === 1">
                        易倍申
                      </span>
                      <span v-else>美金刚</span>
                      是AD患者ABC
                      <span class="action-text-emphasize"> 全面症状管理 </span>
                      的优选
                    </p>

                    <div class="proof-link">
                      <h4>建议使用资料</h4>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/专家幻灯：从《阿尔茨海默病ABC全面管理中国专家共识》看盐酸美金刚片的治疗优势_1663940521000.pdf'
                            )
                          "
                        >
                          专家幻灯：从《阿尔茨海默病ABC全面管理中国专家共识》看盐酸美金刚片的治疗优势
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/AAIC 2021 —如何优化阿尔茨海默病的治疗_1663940468000.pdf'
                            )
                          "
                        >
                          专家幻灯：AAIC2021-如何优化阿尔茨海默病的治疗
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/《中国老年期痴呆防治指南》解读-V1.0-20220531_1663940432000.pdf'
                            )
                          "
                        >
                          专家幻灯：2021中国老年期痴呆防治指南解读
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/科室会幻灯：易倍申ABC全面管理的优选_1663940505000.pdf'
                            )
                          "
                        >
                          科室会幻灯：易倍申®ABC全面管理的优选
                        </a>
                      </p>
                    </div>

                    <p class="action-text">
                      <span class="action-flag"></span>
                      强化
                      <span v-if="physicianSelectedParting.indexOf('C') === 1">
                        易倍申
                      </span>
                      <span v-else>美金刚</span>
                      是AD患者改善
                      <span class="action-text-emphasize">
                        BPSD症状的基础
                      </span>
                      用药
                    </p>
                    <div class="proof-link">
                      <h4>建议使用资料</h4>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/专家幻灯：诊疗阿尔茨海默病-关注BPSD_1663940540000.pdf'
                            )
                          "
                        >
                          专家幻灯:诊疗阿尔茨海默病-关注BPSD
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/AD认知障碍的治疗策略_1663940484000.pdf'
                            )
                          "
                        >
                          专家幻灯：AD认知障碍的治疗策略
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/3.科室会 易倍申AD精神行为症状的治疗优选-0324_1663940449000.pdf'
                            )
                          "
                        >
                          科室会幻灯：易倍申AD精神行为症状的治疗优选
                        </a>
                      </p>
                    </div>
                  </div>
                  <!-- 院外处方/购药 -->
                  <div>
                    <div class="action-progress">
                      <p class="action-progress-text">
                        <img
                          src="@/assets/lundbeck/NBA/prescription.png"
                          width="16"
                          alt=""
                        />
                        院外处方/购药
                      </p>
                    </div>
                  </div>
                  <!-- 提升分子份额 （指标未完成有）-->
                  <div v-if="physicianSelectedParting === 'BB'">
                    <div class="action-progress">
                      <p class="action-progress-text">
                        <img
                          src="@/assets/lundbeck/NBA/molecule.png"
                          width="16"
                          alt=""
                        />
                        提升分子份额
                      </p>
                    </div>

                    <p class="action-text">
                      <span class="action-flag"></span>
                      强化美金刚是AD患者ABC
                      <span class="action-text-emphasize"> 全面症状管理 </span>
                      的优选
                    </p>

                    <div class="proof-link">
                      <h4>建议使用资料</h4>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/专家幻灯：从《阿尔茨海默病ABC全面管理中国专家共识》看盐酸美金刚片的治疗优势_1663940521000.pdf'
                            )
                          "
                        >
                          专家幻灯:
                          从《阿尔茨海默病ABC全面管理中国专家共识》看盐酸美金刚片的治疗优势
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/AAIC 2021 —如何优化阿尔茨海默病的治疗_1663940468000.pdf'
                            )
                          "
                        >
                          专家幻灯：AAIC2021-如何优化阿尔茨海默病的治疗
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/《中国老年期痴呆防治指南》解读-V1.0-20220531_1663940432000.pdf'
                            )
                          "
                        >
                          专家幻灯：2021中国老年期痴呆防治指南解读
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/科室会幻灯：易倍申ABC全面管理的优选_1663940505000.pdf'
                            )
                          "
                        >
                          科室会幻灯：易倍申®ABC全面管理的优选
                        </a>
                      </p>
                    </div>

                    <p class="action-text">
                      <span class="action-flag"></span>
                      强化美金刚是AD患者改善
                      <span class="action-text-emphasize">
                        BPSD症状的基础
                      </span>
                      用药
                    </p>
                    <div class="proof-link">
                      <h4>建议使用资料</h4>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/专家幻灯：诊疗阿尔茨海默病-关注BPSD_1663940540000.pdf'
                            )
                          "
                        >
                          专家幻灯:诊疗阿尔茨海默病-关注BPSD
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/AD认知障碍的治疗策略_1663940484000.pdf'
                            )
                          "
                        >
                          专家幻灯：AD认知障碍的治疗策略
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/3.科室会 易倍申AD精神行为症状的治疗优选-0324_1663940449000.pdf'
                            )
                          "
                        >
                          科室会幻灯：易倍申AD精神行为症状的治疗优选
                        </a>
                      </p>
                    </div>

                    <p class="action-text">缔结话术：</p>
                    <div class="proof-link">
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        对于新病人以及经济条件一般的病人可以使用美金刚中标品单药或联合多奈哌齐进行治疗，美金刚20毫克/天是疗效的保证。
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        对于使用多奈哌齐治疗的AD患者，有BPSD症状的，建议联合美金刚20毫克治疗，美金刚联合多奈哌齐，患者更多获益。
                      </p>
                    </div>
                  </div>
                  <!-- 提升产品份额 （政策宽松有）-->
                  <div v-if="physicianSelectedParting === 'BC'">
                    <div class="action-progress">
                      <p class="action-progress-text">
                        <img
                          src="@/assets/lundbeck/NBA/Ebixa.png"
                          width="16"
                          alt=""
                        />
                        提升产品份额
                      </p>
                    </div>
                    <p class="action-text">
                      <span class="action-flag"></span>
                      强化美金刚是AD患者ABC
                      <span class="action-text-emphasize"> 全面症状管理 </span>
                      的优选
                    </p>

                    <div class="proof-link">
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/专家幻灯：从《阿尔茨海默病ABC全面管理中国专家共识》看盐酸美金刚片的治疗优势_1663940521000.pdf'
                            )
                          "
                        >
                          专家幻灯:
                          从《阿尔茨海默病ABC全面管理中国专家共识》看盐酸美金刚片的治疗优势
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/AAIC 2021 —如何优化阿尔茨海默病的治疗_1663940468000.pdf'
                            )
                          "
                        >
                          专家幻灯：AAIC2021-如何优化阿尔茨海默病的治疗
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/《中国老年期痴呆防治指南》解读-V1.0-20220531_1663940432000.pdf'
                            )
                          "
                        >
                          专家幻灯：2021中国老年期痴呆防治指南解读
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/科室会幻灯：易倍申ABC全面管理的优选_1663940505000.pdf'
                            )
                          "
                        >
                          科室会幻灯：易倍申®ABC全面管理的优选
                        </a>
                      </p>
                    </div>

                    <p class="action-text">
                      <span class="action-flag"></span>
                      强化美金刚是AD患者改善
                      <span class="action-text-emphasize">
                        BPSD症状的基础
                      </span>
                      用药
                    </p>
                    <div class="proof-link">
                      <h4>建议使用资料</h4>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/专家幻灯：诊疗阿尔茨海默病-关注BPSD_1663940540000.pdf'
                            )
                          "
                        >
                          专家幻灯:诊疗阿尔茨海默病-关注BPSD
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/AD认知障碍的治疗策略_1663940484000.pdf'
                            )
                          "
                        >
                          专家幻灯：AD认知障碍的治疗策略
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/3.科室会 易倍申AD精神行为症状的治疗优选-0324_1663940449000.pdf'
                            )
                          "
                        >
                          科室会幻灯：易倍申AD精神行为症状的治疗优选
                        </a>
                      </p>
                    </div>

                    <p class="action-text">缔结话术：</p>
                    <div class="proof-link">
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        对于新病人可以使用易倍申单药或联合多奈哌齐进行治疗，美金刚20毫克/天是疗效的保证。
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        对于使用多奈哌齐治疗的AD患者，有BPSD症状的，建议联合易倍申20毫克治疗，易倍申联合多奈哌齐，患者更多获益
                      </p>
                    </div>
                  </div>
                </div>
                <!-- 低认可度 解限医院 -->
                <div v-if="physicianSelectedParting == 'AC'">
                  <!-- 推动观念提升 -->
                  <div>
                    <div class="action-progress">
                      <p class="action-progress-text">
                        <img
                          src="@/assets/lundbeck/NBA/concept.png"
                          width="16"
                          alt=""
                        />
                        推动观念提升
                      </p>
                    </div>

                    <p class="action-text">
                      <span class="action-flag"></span>
                      强化
                      <span v-if="physicianSelectedParting.indexOf('C') === 1">
                        易倍申
                      </span>
                      <span v-else>美金刚</span>
                      是AD患者改善
                      <span class="action-text-emphasize">
                        BPSD症状的基础
                      </span>
                      用药
                    </p>
                    <div class="proof-link">
                      <h4>建议使用资料</h4>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/专家幻灯：诊疗阿尔茨海默病-关注BPSD_1663940540000.pdf'
                            )
                          "
                        >
                          专家幻灯:诊疗阿尔茨海默病-关注BPSD
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/AD认知障碍的治疗策略_1663940484000.pdf'
                            )
                          "
                        >
                          专家幻灯：AD认知障碍的治疗策略
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/3.科室会 易倍申AD精神行为症状的治疗优选-0324_1663940449000.pdf'
                            )
                          "
                        >
                          科室会幻灯：易倍申AD精神行为症状的治疗优选
                        </a>
                      </p>
                    </div>
                  </div>
                  <!-- 提升产品份额 -->
                  <div v-if="!(physicianSelectedParting.indexOf('A') === 1)">
                    <div class="action-progress">
                      <p class="action-progress-text">
                        <img
                          src="@/assets/lundbeck/NBA/Ebixa.png"
                          width="16"
                          alt=""
                        />
                        提升产品份额
                      </p>
                    </div>
                    <p class="action-text">
                      <span class="action-flag"></span>
                      强化易倍申是AD患者改善
                      <span class="action-text-emphasize">
                        BPSD症状的基础
                      </span>
                      用药
                    </p>
                    <div class="proof-link">
                      <h4>建议使用资料</h4>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/专家幻灯：诊疗阿尔茨海默病-关注BPSD_1663940540000.pdf'
                            )
                          "
                        >
                          专家幻灯:诊疗阿尔茨海默病-关注BPSD
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/AD认知障碍的治疗策略_1663940484000.pdf'
                            )
                          "
                        >
                          专家幻灯：AD认知障碍的治疗策略
                        </a>
                      </p>
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        <a
                          @click="
                            lookDetail(
                              'LFile/nba_content/3.科室会 易倍申AD精神行为症状的治疗优选-0324_1663940449000.pdf'
                            )
                          "
                        >
                          科室会幻灯：易倍申AD精神行为症状的治疗优选
                        </a>
                      </p>
                    </div>

                    <p class="action-text">缔结话术：</p>
                    <div class="proof-link">
                      <p class="action-text" style="font-size: 12px">
                        <span class="action-flag"></span>
                        对于有BPSD症状的新病人可以使用易倍申单药或联合多奈哌齐进行治疗
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- @confirm="confirmPhysicianAnswer()" -->
      <van-dialog
        v-model="physicianDialogShow"
        title="分型问答"
        theme="round-button"
        confirm-button-color="#aa341b"
        :before-close="beforePhysicianClose"
      >
        <div class="diglog-close" @click="closeDiglog()">×</div>
        <div class="diglog-questions">
          <div class="question">
            <p class="question-text">
              1. 医院当前对于产品的政策 <span style="color: #cc4b47">*</span>
            </p>
            <van-checkbox-group v-model="physicianQuestionAnswers.firstAnswer">
              <van-checkbox name="A" shape="square" class="answer-checkbox">
                停药
              </van-checkbox>
              <van-checkbox name="B" shape="square" class="answer-checkbox">
                用量比例
              </van-checkbox>
              <van-checkbox name="C" shape="square" class="answer-checkbox">
                金额比例
              </van-checkbox>
              <van-checkbox name="D" shape="square" class="answer-checkbox">
                每月盒数限制
              </van-checkbox>
              <van-checkbox name="E" shape="square" class="answer-checkbox">
                可用原研药天数限制
              </van-checkbox>
              <van-checkbox name="F" shape="square" class="answer-checkbox">
                无限制
              </van-checkbox>
              <van-checkbox name="G" shape="square" class="answer-checkbox">
                其他
              </van-checkbox>
              <van-checkbox
                name="不了解"
                shape="square"
                class="answer-checkbox"
              >
                不了解
              </van-checkbox>
            </van-checkbox-group>
            <p v-if="physicianFirstAnswerTips" style="color: #cc4b47">
              这是必答题，请选择答案！
            </p>
          </div>
          <div
            v-if="
              physicianQuestionAnswers.firstAnswer.findIndex(
                (e) => e === '不了解'
              ) > -1
            "
            class="question"
          >
            <p class="question-text">
              若不了解具体的医院对于产品的政策，请直接判断医院对产品政策的严格程度
            </p>
            <van-radio-group
              v-model="physicianQuestionAnswers.secondAnswer"
              direction="horizontal"
              class="answer"
            >
              <van-radio name="true" style="margin-right: 30%">严格</van-radio>
              <van-radio name="false"> 宽松 </van-radio>
            </van-radio-group>
          </div>
          <div class="question">
            <p class="question-text">
              2. VBP执行严格程度
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="physicianQuestionAnswers.thirdAnswer"
              direction="horizontal"
              class="answer"
            >
              <van-radio name="A" style="margin-right: 10%">严格</van-radio>
              <van-radio name="B" style="margin-right: 10%">一般</van-radio>
              <van-radio name="C"> 宽松 </van-radio>
            </van-radio-group>
            <p v-if="physicianThirdAnswerTips" style="color: #cc4b47">
              这是必答题，请选择答案！
            </p>
          </div>
          <div class="question">
            <p class="question-text">
              3. 当前中标产品带量采购完成情况
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="physicianQuestionAnswers.fourthAnswer"
              direction="horizontal"
              class="answer"
            >
              <div style="margin-right: 20%">
                <van-radio style="margin-bottom: 10px" name="A">
                  已完成
                </van-radio>
                <van-radio name="C">远未完成</van-radio>
              </div>
              <div>
                <van-radio style="margin-bottom: 10px" name="B">
                  近完成
                </van-radio>
                <van-radio name="D"> 不知道 </van-radio>
              </div>
            </van-radio-group>
            <p v-if="physicianFourthAnswerTips" style="color: #cc4b47">
              这是必答题，请选择答案！
            </p>
          </div>
          <div class="question">
            <p class="question-text">4. 医院当前对于产品的限制？</p>
            <van-field
              v-model="physicianQuestionAnswers.fifthAnswer.answer1"
              type="number"
              :border="true"
              label-width="115"
              label="(1) 用量比例限制"
              placeholder="请输入"
            >
              <template #button> % </template>
            </van-field>
            <van-field
              v-model="physicianQuestionAnswers.fifthAnswer.answer2"
              type="number"
              :border="true"
              label-width="85"
              label="(2) 盒数限制"
              placeholder="请输入"
            >
              <template #button> 盒 </template>
            </van-field>
            <van-field
              v-model="physicianQuestionAnswers.fifthAnswer.answer3"
              type="number"
              :border="true"
              label-width="85"
              label="(3) 金额比例"
              placeholder="请输入"
            >
              <template #button> 盒 </template>
            </van-field>
            <van-field
              v-model="physicianQuestionAnswers.fifthAnswer.answer4"
              type="number"
              :border="true"
              label-width="85"
              label="(4) 金额限制"
              placeholder="请输入"
            >
              <template #button> 元 </template>
            </van-field>
            <van-field
              v-model="physicianQuestionAnswers.fifthAnswer.answer5"
              type="number"
              :border="true"
              label-width="160"
              label="(5) 可用原研药天数限制"
              placeholder="请输入"
            >
              <template #button> 天 </template>
            </van-field>
            <van-field
              v-model="physicianQuestionAnswers.fifthAnswer.answer6"
              :border="true"
              label-width="60"
              label="(6) 其他"
              rows="2"
              type="textarea"
              placeholder="请输入"
            />
          </div>
          <div class="question">
            <p class="question-text">
              5. 当前安理申政策状况
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="physicianQuestionAnswers.sixthAnswer"
              direction="horizontal"
            >
              <div>
                <van-radio style="margin-bottom: 5px" name="A">
                  与易倍申相同
                </van-radio>
                <van-radio style="margin-bottom: 5px" name="B">
                  较易倍申宽松
                </van-radio>
                <van-radio style="margin-bottom: 5px" name="C">
                  较易倍申严格
                </van-radio>
                <van-radio style="margin-bottom: 5px" name="D">
                  不了解
                </van-radio>
              </div>
            </van-radio-group>
            <p v-if="physiciansixthAnswerTips" style="color: #cc4b47">
              这是必答题，请选择答案！
            </p>
          </div>
          <div class="question">
            <p class="question-text">
              6. 医生为其所有痴呆（Dementia）患者，处方美金刚分子的比例是多少
              <span style="color: #cc4b47">*</span>
            </p>
            <p class="sixth-answer">
              {{ physicianQuestionAnswers.seventhAnswer }} %
            </p>
            <van-slider
              v-model="physicianQuestionAnswers.seventhAnswer"
              :step="5"
            />
          </div>
        </div>
      </van-dialog>
    </div>
    <swipeDialog v-if="detailStatus" title="详情" @closeFn="closeFn">
      <van-loading v-if="fileLoading" class="loading">加载中...</van-loading>
      <pdfComponent v-if="pdfLink" :url="pdfLink" :pdf-key="pdfKey" />
      <!-- <van-loading v-if="fileLoading" class="loading">加载中...</van-loading>
      <div v-if="!fileLoading">
        <a :href="pdfLink" download>
          <img class="download" src="@/assets/nba/down.png" width="20" alt />
        </a>
        <pdf v-for="i in numPages" :key="i" :src="pdfLink" :page="i" />
      </div> -->
    </swipeDialog>
  </div>
</template>

<script>
import CommonHeader from "@/components/CommonHeader";
import * as moment from "moment";
import * as py from "@/api/Physicians";
import {
  Button,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  Field,
  Slider,
  Loading,
} from "vant";
import { getLocalStorage } from "@/utils";
import { setTimeout } from "timers";
import pdf from "vue-pdf";
import swipeDialog from "@/views/layout/swipeDialog";
import { s3Service } from "@/utils/aws/index";
import pdfComponent from "../pdfComponent.vue";
import { getMimeTypeBySuffix } from "@/utils/index";
export default {
  name: "Ebixa",
  components: {
    CommonHeader,
    vanButton: Button,
    vanRadioGroup: RadioGroup,
    vanRadio: Radio,
    vanCheckbox: Checkbox,
    vanCheckboxGroup: CheckboxGroup,
    vanField: Field,
    vanSlider: Slider,
    swipeDialog,
    pdfComponent,
    vanLoading: Loading,
    // pdf,
  },
  props: {
    productId: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      loading: false,
      isPhysicianFirst: true,
      physicianDialogShow: false,
      physicianPartings: [],
      physicianSelectedParting: "",
      physicianQuestionAnswers: {
        firstAnswer: [],
        secondAnswer: null,
        thirdAnswer: null,
        fourthAnswer: null,
        fifthAnswer: {
          answer1: null,
          answer2: null,
          answer3: null,
          answer4: null,
          answer5: null,
          answer6: null,
        },
        sixthAnswer: null,
        seventhAnswer: 0,
      },
      physicianFirstAnswerTips: false,
      physicianThirdAnswerTips: false,
      physicianFourthAnswerTips: false,
      physiciansixthAnswerTips: false,
      showPhysicianPartingHistorys: [],
      showExpand: false,
      physicianHistoryExpandStatus: false,
      isKeyCustomer: true,
      star: require("@/assets/lundbeck/star.png"),
      numPages: 0,
      pdfLink: "",
      pdfKey: "",
      detailStatus: false,
      fileLoading: false,
      canEditStatus: false
    };
  },
  mounted() {
    if (getLocalStorage('territory_type') === 'MR') {
      this.canEditStatus = true;
    }
    this.getPhysicianTyping();
  },
  methods: {
    getPhysicianTyping() {
      let params = {
        physician_id: this.$route.query.physician_id,
        product_id: this.productId,
        territory_id: getLocalStorage("territory_id"),
      };
      py.getPhysicianTyping(params).then((res) => {
        if (res && res.value.length) {
          this.physicianPartings = res.value;
          if (this.physicianPartings.length > 0) {
            this.isPhysicianFirst = false;
            this.physicianQuestionAnswers = JSON.parse(
              this.physicianPartings[0].content
            );
            if (this.physicianPartings.length > 2) {
              // 医生分型列表 长度>2
              if (!this.physicianHistoryExpandStatus) {
                // 收起状态取前两位分型
                this.showPhysicianPartingHistorys =
                  this.physicianPartings.slice(0, 2);
              }
              this.showExpand = true;
            } else {
              this.showPhysicianPartingHistorys = this.physicianPartings;
            }
            this.getPhysicianPartingResult();
          } else {
            this.isPhysicianFirst = true;
          }
        }
      });
    },
    beforePhysicianClose(action, done) {
      if (action === "confirm") {
        if (this.physicianQuestionAnswers.firstAnswer.length < 1) {
          this.physicianFirstAnswerTips = true;
          done(false);
          return;
        }
        if (this.physicianQuestionAnswers.thirdAnswer == null) {
          this.physicianThirdAnswerTips = true;
          done(false);
          return;
        }
        if (this.physicianQuestionAnswers.fourthAnswer == null) {
          this.physicianFourthAnswerTips = true;
          done(false);
          return;
        }
        if (this.physicianQuestionAnswers.sixthAnswer == null) {
          this.physiciansixthAnswerTips = true;
          done(false);
          return;
        }
        let uName = getLocalStorage("user_name");
        let params = {
          physician_id: this.$route.query.physician_id,
          content: JSON.stringify(this.physicianQuestionAnswers),
          user_id: getLocalStorage("user_id"),
          territory_id: getLocalStorage("territory_id"),
          create_time: Date.parse(new Date()),
          product_id: this.productId,
          result: this.getPhysicianPartingResult(),
          modify_user_id: getLocalStorage("user_id"),
          modify_user_name: uName !== 'null' ? uName : getLocalStorage("user_id"),
        };
        py.setPhysicianTyping(params).then((res) => {
          if (res.is_success) {
            this.$toast.success("提交成功");
            this.hospitalDialogShow = false;
            setTimeout(() => {
              done();
              this.getPhysicianTyping();
            }, 2);
          } else {
            this.$toast.success("提交失败，请重试");
            done(false);
          }
        });
      } else {
        done();
      }
    },
    openPhysicianDialog() {
      this.physicianFirstAnswerTips = false;
      this.physicianThirdAnswerTips = false;
      this.physicianFourthAnswerTips = false;
      this.physiciansixthAnswerTips = false;
      if (this.isPhysicianFirst) {
        this.physicianQuestionAnswers = {
          firstAnswer: [],
          secondAnswer: null,
          thirdAnswer: null,
          fourthAnswer: null,
          fifthAnswer: {
            answer1: null,
            answer2: null,
            answer3: null,
            answer4: null,
            answer5: null,
            answer6: null,
          },
          sixthAnswer: null,
          seventhAnswer: 0,
        };
      }
      if (this.physicianPartings.length > 0) {
        this.physicianQuestionAnswers = JSON.parse(
          this.physicianPartings[0].content
        );
      }
      this.physicianDialogShow = true;
    },
    getPhysicianPartingResult() {
      let result = "";
      if (+this.physicianQuestionAnswers.seventhAnswer > 35) {
        result = "高认可度";
        this.physicianSelectedParting = "C";
      }
      if (
        this.physicianQuestionAnswers.seventhAnswer >= 25 &&
        this.physicianQuestionAnswers.seventhAnswer <= 35
      ) {
        result = "中认可度";
        this.physicianSelectedParting = "B";
      }
      if (this.physicianQuestionAnswers.seventhAnswer < 25) {
        result = "低认可度";
        this.physicianSelectedParting = "A";
      }
      let firstAnswerString =
        this.physicianQuestionAnswers.firstAnswer.toString();
      // 选择停药，则直接归进停药，但VBP选择“执行不严格”，可以被放在第二类“指标远未完成/政策限制严格”
      if (firstAnswerString.indexOf("A") > -1) {
        if (this.physicianQuestionAnswers.thirdAnswer === "C") {
          // 停药并且VBP执行宽松
          result += " 指标远未完成/政策限制严格";
          this.physicianSelectedParting += "B";
        } else {
          result += " 医院停控药";
          this.physicianSelectedParting += "A";
        }
      } else if (this.physicianQuestionAnswers.fourthAnswer === "A") {
        // 指标已完成，放在第三类'已完成/政策宽松'
        result += " 已完成/政策宽松";
        this.physicianSelectedParting += "C";
      } else {
        // 指标远未完成/不知道 归为第二类“指标远未完成/政策限制严格”
        // VBP执行严格、指标近完成 归为第二类“指标远未完成/政策限制严格”
        // 严格：A，C，D (不了解 但判断为严格)
        // 宽松：B（≤20%判为严格），E（<10天判为严格），F
        // 政策严格 VBP执行一般 归为第二类“指标远未完成/政策限制严格”
        // （指标已完成已被排除）
        if (
          this.physicianQuestionAnswers.fourthAnswer === "C" || // 指标远未完成
          this.physicianQuestionAnswers.fourthAnswer === "D" || // 完成情况不知道
          (this.physicianQuestionAnswers.thirdAnswer === "A" &&
            this.physicianQuestionAnswers.fourthAnswer === "B") || // VBP执行严格、指标近完成
          (((firstAnswerString.indexOf("B") > -1 &&
            this.physicianQuestionAnswers.fifthAnswer.answer1 &&
            this.physicianQuestionAnswers.fifthAnswer.answer1 <= 20) || // B（≤20%判为严格）
            firstAnswerString.indexOf("C") > -1 || // 严格：A，C，D
            firstAnswerString.indexOf("D") > -1 || // 严格：A，C，D
            (firstAnswerString.indexOf("E") > -1 &&
              this.physicianQuestionAnswers.fifthAnswer.answer5 &&
              this.physicianQuestionAnswers.fifthAnswer.answer5 < 10) || // E（<10天判为严格）
            (firstAnswerString.indexOf("不了解") > -1 &&
              this.physicianQuestionAnswers.secondAnswer === "true")) && // 不了解 直接判断为严格 （括号内的判断为政策严格）
            this.physicianQuestionAnswers.thirdAnswer === "B") // 政策严格且VBP执行一般
        ) {
          result += " 指标远未完成/政策限制严格";
          this.physicianSelectedParting += "B";
        } else {
          result += " 已完成/政策宽松";
          this.physicianSelectedParting += "C";
        }
      }
      console.log("physicianSelectedParting", this.physicianSelectedParting);

      return result;
    },
    confirmPhysicianAnswer() {
      console.log('静如没有');
      if (this.physicianQuestionAnswers.firstAnswer.length < 1) {
        this.physicianFirstAnswerTips = true;
        return;
      }
      if (this.physicianQuestionAnswers.thirdAnswer == null) {
        this.physicianThirdAnswerTips = true;
        return;
      }
      if (this.physicianQuestionAnswers.fourthAnswer == null) {
        this.physicianFourthAnswerTips = true;
        return;
      }
      if (this.physicianQuestionAnswers.sixthAnswer == null) {
        this.physiciansixthAnswerTips = true;
        return;
      }
      let uName = getLocalStorage("user_name");
      let params = {
        physician_id: this.$route.query.physician_id,
        content: JSON.stringify(this.physicianQuestionAnswers),
        user_id: getLocalStorage("user_id"),
        territory_id: getLocalStorage("territory_id"),
        create_time: Date.parse(new Date()),
        product_id: this.productId,
        result: this.getPhysicianPartingResult(),
        modify_user_id: getLocalStorage("user_id"),
        modify_user_name: uName !== 'null' ? uName : getLocalStorage("user_id"),
      };
      py.setPhysicianTyping(params).then((res) => {
        if (res.is_success) {
          this.$toast.success("提交成功");
          this.hospitalDialogShow = false;
          setTimeout(() => {
            this.getPhysicianTyping();
          }, 2);
        }
      });
      this.physicianDialogShow = false;
    },
    closeDiglog() {
      this.hospitalDialogShow = false;
      this.physicianDialogShow = false;
    },
    getDate(date) {
      return moment(new Date(+date)).format("YYYY-MM-DD");
    },
    expendPhysicianHistory() {
      if (
        this.physicianHistoryExpandStatus &&
        this.physicianPartings.length > 2
      ) {
        this.showPhysicianPartingHistorys = this.physicianPartings.slice(0, 2);
      } else {
        this.showPhysicianPartingHistorys = this.physicianPartings;
      }
      this.physicianHistoryExpandStatus = !this.physicianHistoryExpandStatus;
    },
    lookDetail(key) {
      this.$store.dispatch("setDialogStatus", true);
      this.detailStatus = true;
      if (this.detailStatus) {
        this.getPictureUrl(key);
      }
    },
    closeFn() {
      this.$store.dispatch("setDialogStatus", false);
      this.detailStatus = false;
      this.pdfLink = null;
      this.pdfKey = null;
    },
    getPictureUrl(key) {
      py.getS3ByKey(key).then((res) => {
        let currentMimeType = getMimeTypeBySuffix(
          key.split(".").pop().toLowerCase()
        );
        let blob = new Blob([res.data], {
          type: currentMimeType,
        });
        this.pdfKey = key;
        this.pdfLink = window.URL.createObjectURL(blob);
        this.fileLoading = false;
        // if (this.pdfLink) {
        //   this.getNumPages(this.pdfLink);
        // }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.parting {
  .tip-text {
    font-size: 14px;
    font-family: Arial;
    font-weight: 400;
    color: #2c3034;
    opacity: 1;
    margin: 40px auto 20px;
  }
  .tip-button {
    width: 160px;
    height: 40px;
    background: #aa341b;
    border: 1px solid #aa341b;
    opacity: 1;
    border-radius: 500px;
    margin-bottom: 20px;
  }
  .diglog-close {
    position: absolute;
    font-size: 30px;
    top: 25px;
    right: 20px;
  }
  .diglog-questions {
    max-height: 400px;
    overflow-y: auto;
    padding: 20px 20px 0 20px;
    .question {
      margin-bottom: 20px;
      .question-text {
        font-size: 14px;
        font-family: Arial;
        font-weight: 700;
        line-height: 1.2;
        color: #2c3034;
        opacity: 1;
        text-align: left;
        margin-bottom: 10px;
      }
      .answer {
        display: flex;
        justify-content: center;
      }
      .answer-checkbox {
        margin: 5px;
      }
    }
  }
  .parting-type-y-top {
    height: 14px;
    font-size: 10px;
    font-family: Arial;
    font-weight: 400;

    color: #aa341b;
    opacity: 1;
    text-align: left;
    margin-top: 20px;
  }
  .parting-type {
    display: flex;
    align-items: center;
    justify-content: center;
    .parting-type-cake {
      display: flex;
      flex-wrap: wrap;
      .parting-type-unselected {
        width: 95px;
        height: 40px;
        background: linear-gradient(46deg, #f8f6f5 0%, #f3e6e3 100%);
        opacity: 1;
        border-radius: 4px;
        margin: 3px;
      }
      .parting-type-selected {
        width: 95px;
        height: 40px;
        background: linear-gradient(46deg, #aa341b 0%, #aa341b 100%);
        opacity: 1;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 3px;
        p {
          line-height: 1.5;
          font-size: 10px;
          color: #ffffff;
        }
      }
    }
    .parting-type-y {
      margin-right: 5px;
      .parting-type-y-axis {
        display: flex;
        .parting-type-y-text {
          width: 18px;
          height: 28px;
          font-size: 10px;
          font-family: Arial;
          font-weight: 400;
          line-height: 14px;
          color: #838a95;
          opacity: 1;
          margin-top: 20px;
        }
      }
    }
  }
  .parting-type-x {
    display: flex;
    justify-content: space-around;
    margin-left: 20px;
    .parting-type-x-text {
      font-size: 10px;
      font-family: Arial;
      font-weight: 400;
      color: #838a95;
      line-height: 1.2;
      opacity: 1;
    }
  }
  .parting-type-x-end {
    height: 14px;
    font-size: 10px;
    font-family: Arial;
    font-weight: 400;

    color: #aa341b;
    opacity: 1;
    float: right;
    margin-top: -10px;
    margin-right: -15px;
  }
  .parting-type-action {
    margin-top: 10px;
    .action-title {
      display: flex;
      align-items: baseline;
      height: 20px;
    }
    .action-plan {
      padding: 10px;
    }
    .action-text {
      .action-text-emphasize {
        color: #aa341b;
      }
      a {
        color: #aa341b;
      }
    }
    .action-progress {
      display: flex;
      align-items: center;
      height: 30px;
      background: rgba(236,233,223,.5);
      border-radius: 4px;
      margin-bottom: 20px;
      .action-progress-text {
        display: flex;
        align-items: center;
        color: #aa341b;
        font-size: 14px;
        font-family: Arial;
        font-weight: 700;
        margin-left: 10px;
        img {
          margin-right: 5px;
        }
      }
    }
    .title-flag {
      width: 6px;
      height: 14px;
      background: #aa341b;
      opacity: 1;
      border-radius: 4px;
    }
    .title-text {
      height: 22px;
      font-size: 16px;
      font-family: Arial;
      font-weight: 700;

      color: #2c3034;
      opacity: 1;
      margin-left: 5px;
    }
    .key-customers {
      width: 70px;
      height: 20px;
      background: rgba(239, 156, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
    }
    .key-customers-text {
      height: 14px;
      font-size: 10px;
      font-family: Arial;
      font-weight: 700;
      color: #ef9c00;
      margin: 5px 0 0 5px;
    }
    .no-key-customers {
      width: 100px;
      height: 20px;
      background: rgba(135, 190, 204, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
    }
    .no-key-customers-text {
      height: 14px;
      font-size: 10px;
      font-family: Arial;
      font-weight: 700;
      color: #4fb0c9;
      margin: 5px 0 0 5px;
    }
    .loading {
      margin-top: 55%;
    }
    .download {
      z-index: 1;
      position: absolute;
      top: 450px;
      right: 20px;
      border: 1px solid rgb(218, 217, 217);
      border-radius: 100%;
      padding: 5px;
    }
  }
  .proof-link {
    h4 {
      font-size: 14px;
      font-family: Arial;
      font-weight: bold;
      color: #2c3034;
      margin: 10px 0 20px;
      padding: 0;
    }
  }
  .sixth-answer {
    font-size: 24px;
    font-family: Arial;
    font-weight: 700;
    color: #aa341b;
    margin: 20px 0 30px 0;
    text-align: left;
  }
}
</style>
