<template>
  <div class="box box-left bac-white">
    <!-- <div class="group-header" style="justify-content: space-between;">
      <div style="display: flex;">
        <span class="icon">
          <font-awesome-icon class="dimension-icon" :icon="['fa', 'user']" />
        </span>
        <p class="title"><span>{{ propsData.title }}</span></p>
      </div>
    </div> -->
    <CommonHeader :title="propsData.title" :source="propsData.source" :no-feedback="true">
      <span v-if="hasEdit" slot="before" class="icon">
        <font-awesome-icon class="dimension-icon" :icon="['fa', 'edit']" />
      </span>
    </CommonHeader>
    <!-- <div class="title">{{ propsData.title }}</div> -->
    <div class="description" style="min-height: 40px;">
      <Nodata v-if="!propsData.content" :loading="propsData.loading" :empty-txt="propsData.emptyTxt" />
      <template v-else>
        <slot></slot>
        <div v-html="propsData.content"></div>
        <!-- {{ propsData.content }} -->
      </template>
    </div>
    <slot name="append"></slot>
    <p v-if="propsData.content && updateTime" class="module-update-time">{{ updateTime }}</p>
    <p v-if="propsData.content && dataSource" class="module-update-time">{{ dataSource }}</p>
  </div>
</template>
<script>
import Nodata from '@/components/Nodata';
import CommonHeader from '@/components/CommonHeader';
export default {
  components: {
    Nodata,
    CommonHeader
  },
  props: {
    hasEdit: {
      type: Boolean,
      default() {
        return false;
      }
    },
    propsData: {
      type: Object,
      default() {
        return {
          title: '',
          content: '',
          source: '',
          emptyTxt: this.$t('no_data')
        };
      }
    },
    dataSource: {
      type: String,
      default() {
        return '';// this.$t('info_from_open');
      }
    },
    updateTime: {
      type: String,
      default() {
        return '';
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.feed-back-fixed {
  // width: 20px;
  height: 20px;
  // position: fixed;
  // right: 20px;
  // bottom: 20px;
  color: #aa341b;
}
</style>
