<template>
  <div class="home-content">
    <div class="banner">
      <div style="padding-top: 80px; font-size: 16px">我的报告</div>
      <div style="margin-top: 20px; font-size: 38px">
        {{ physicianMslCall?.call_last_30day_no ?? 0 }}
      </div>
      <div style="margin-top: 20px; font-size: 12px">近30天拜访数</div>
    </div>
    <div class="board">
      <div v-for="(item, index) in boardList" :key="index" class="board-item">
        <div class="title">{{ item.title }}</div>
        <div class="value">{{ item.value }}</div>
      </div>
    </div>
    <div v-if="physicianMslCall" class="main">
      <Tabs v-model="active" type="card" color="#aa341b">
        <Tab title="拜访统计" />
        <Tab title="拜访医生" />
      </Tabs>
      <!-- 拜访统计 -->
      <div v-if="active === 0" style="padding-top: 20px">
        <div class="flex">
          <ReportChart
            title="拜访客户"
            :data="[
              {name: '目标KOL', value: physicianMslCall?.target_call_no ?? 0},
              {name: '非目标KOL', value: physicianMslCall?.non_tartget_call_no ?? 0},
            ]"
          />
          <ReportChart
            title="专家级别"
            :data="[
              {name: '国家级', value: physicianMslCall?.national_call_no ?? 0},
              {name: '区域级', value: physicianMslCall?.regional_call_no ?? 0},
            ]"
          />
        </div>
        <div class="flex">
          <ReportChart
            title="拜访与协访"
            :data="[
              {name: '拜访', value: physicianMslCall?.call_no ?? 0},
              {name: '协访', value: physicianMslCall?.coaching_call_no ?? 0},
            ]"
          />
          <ReportChart
            title="拜访类型"
            :data="[
              {name: '学术', value: physicianMslCall?.academic_call_no ?? 0},
              {
                name: '事务',
                value: physicianMslCall?.transactional_call_no ?? 0,
              },
              {name: '其他', value: physicianMslCall?.other_call_no ?? 0},
            ]"
          />
        </div>
      </div>
      <!-- 拜访医生 -->
      <div v-if="active === 1">
        <div class="doctor-list-header">
          <div>我的医生</div>
          <div>本月拜访次数</div>
        </div>
        <ul class="activity-list">
          <li
            v-for="({physician, call_no}, index) in physicianMslCallState"
            :key="index"
            class="flex"
            style="justify-content: space-between; align-items: center"
          >
            <div class="flex">
              <img src="./../../assets/lundbeck/portrait.png" class="avatar" />
              <div class="custom-item-right">
                <div class="header clearfix">
                  <div class="name" style="flex: 1">
                    <span class="name-big">{{
                      physician?.physician_name
                    }}</span>
                  </div>
                </div>
                <p class="department">{{ physician?.institution_name }}</p>
              </div>
            </div>
            <div>
              <span style="font-size: 28px; margin-right: 5px">{{
                call_no
              }}</span>次
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>
<script>
import ReportChart from '@/components/ReportChart.vue';
import VisitListItem from './components/visitListItem.vue';
import {Tabs, Tab} from 'vant';
import asyncData from '@/mixins/asyncData';
import {fetchMslCallState, fetchPhysicianMslCallState} from '@/api/kol';
import {getLocalStorage} from '@/utils';

export default {
  components: {Tabs, Tab, ReportChart},
  data() {
    return {
      active: 0,
      // physicianMslCall: {
      //   call_last_30day_no: 21,
      //   call_current_month_no: 2,
      //   call_last_update_text: '2天前',
      //   call_physician_no: 10,
      //   physician_no: 20,
      //   call_coverage_rate: 20.22,
      //   // 拜访区域统计
      //   city_call_no: 1,
      //   province_call_no: 1,
      //   other_province_call_no: 1,
      //   // 专家级别统计
      //   national_call_no: 2,
      //   regional_call_no: 10,
      //   // 拜访与协访统计
      //   call_no: 10,
      //   coaching_call_no: 2,
      //   // 拜访类型统计
      //   academic_call_no: 1,
      //   transactional_call_no: 2,
      //   other_call_no: 3,
      // },
    };
  },
  mixins: [
    asyncData(
      () => fetchPhysicianMslCallState({user_id: getLocalStorage('user_id')}),
      'physicianMslCallState',
    ),
    asyncData(
      () => fetchMslCallState({user_id: getLocalStorage('user_id')}),
      'physicianMslCall',
    ),
  ],
  computed: {
    boardList() {
      return [
        {
          title: '最近一次拜访',
          value: this.physicianMslCall?.call_last_update_text || '-',
        },
        {
          title: '本月拜访',
          value: this.physicianMslCall?.call_current_month_no || '-',
        },
        {
          title: '拜访客户/总客户',
          value: `${this.physicianMslCall?.call_physician_no ?? 0}/${
            this.physicianMslCall?.physician_no ?? 0
          }`,
        },
        {
          title: '拜访覆盖率',
          value: `${this.physicianMslCall?.call_coverage_rate ?? 0}%`,
        },
      ];
    },
  },
  created() {},
  mounted() {},
  destroyed() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.home-content {
  height: 100%;
  overflow-y: auto;
  .banner {
    height: 200px;
    background: #aa341b;
    color: #fff;
  }
  .board {
    background: #f4f8f9;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // font-size: 18px;
    .board-item {
      .title {
        font-size: 12px;
      }
      .value {
        font-size: 16px;
        color: #aa341b;
        margin-top: 20px;
      }
    }
  }
  .main {
    // margin-top: 20px;
    background: #fff;
    padding: 20px 10px;
    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 30px;
    }
    .name-big {
      color: #aa341b;
      font-size: 18px;
    }
    .custom-item-right {
      padding-top: 7px;
    }
    .doctor-list-header {
      font-size: 12px;
      color: #6e6e6d;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      margin-top: 10px;
    }
  }
  ::v-deep .van-tabs__nav {
    border-radius: 50px;
    overflow: hidden;
  }
}
</style>
