<template>
  <div class="view-wrapper">
    <template>
      <div class="search-bar-list pd-btm0">
        <swipeBack
          v-if="showHeaderBar"
          :title="title"
          backtxt="返回"
          @backFn="backFn"
        />
        <div style="width: 100%">
          <van-search
            v-model="keyword"
            shape="round"
            background="#ffffff"
            placeholder="输入医院/科室/医生搜索"
            :clearable="false"
            @input="searchDoctor"
            />
        </div>
        <div class="search-filter">
          <div class="search-filter-drop">
            <van-dropdown-menu active-color="#aa341b" style="width: 100%">
              <van-dropdown-item
                v-model="selectedFilter"
                :options="showFilters"
                get-container="body"
                @change="changeFilter"
              />
            </van-dropdown-menu>
          </div>
          <div class="search-filter1">
            <!-- <van-button
              round
              type="info"
              :disabled="searchDisable"
              :loading="loading && resetDisable"
              class="search-filter-button"
              color="#aa341b"
              @click="searchDoctor"
            >
              搜索
            </van-button>
            <van-button
              round
              plain
              type="info"
              class="search-filter-button"
              color="#aa341b"
              :disabled="resetDisable"
              :loading="loading && searchDisable"
              @click="reset"
            >
              重置
            </van-button> -->
          </div>
        </div>
      </div>
      <div v-if="false" class="search-bar-list">
        <div class="search-filter j-center">
          <span class="f-quest" @click="showDescFn(2)">
            排序<font-awesome-icon
              class="question-icon"
              :icon="['fa', 'question-circle']"
            />：
          </span>
          <!-- <div style="display: flex;">
          </div> -->
          <van-radio-group
            v-model="sortOrder"
            direction="horizontal"
            checked-color="#aa341b"
            @change="changeFn($event)"
          >
            <van-radio name="physician_name">默认</van-radio>
            <van-radio name="institution_name">按医院名</van-radio>
            <van-radio v-if="selectedProduct" name="physician_segment_sort"
              >按医生等级</van-radio
            >
          </van-radio-group>
        </div>
      </div>
      <div class="search-bar-list">
        <div class="search-filter j-center" style="background-color: #f5f7f8; padding-top: 15px;">
          <div class="hospital-count-div">
            <span class="hospital-count-item"
              >医生数：<span class="f-blod">{{ physician_count }}</span></span
            >
            <!-- <span class="hospital-count-item"
              >外部数据医生数：<span class="f-blod">{{
                ex_data_support_count
              }}</span></span
            > -->
            <span class="hospital-count-item"
              >绑定微信医生数：<span class="f-blod">{{
                is_wechat_count
              }}</span></span
            >
          </div>
        </div>
      </div>
      <div
        style="background-color: #f5f7f8"
        class="index-list-wrapper custom"
        :class="{
          'no-list': list.length == 0,
          'index-list-wrapper-nosales': !isSales,
        }"
      >
        <van-list
          v-model="listLoading"
          :finished="finished"
          :immediate-check="false"
          :finished-text="$t('no_more_txt')"
          @load="onLoad"
        >
          <CardItem
            v-for="(item, $index) in list"
            :id="'ITEM' + $index"
            :ref="$index == 0 ? 'AIMREF' : ''"
            :key="item.product_id + '_' + item.physician_id + $index"
            :is-favorite="true"
            :gen-status="false"
            :show-draw="false"
            :item="item"
            :parting="item.result"
            :physician-segment="item.physician_segment"
            @refreshFn="pageFn"
            @clickItem="selectItem(item)"
          />
          <Nodata
            v-if="!list.length"
            :loading="loading"
            :empty-txt="$t('no_data')"
          />
        </van-list>
        <div style="height: 50px"></div>
      </div>
    </template>
    <popup
      v-if="showDescStatus"
      :title="searchDesc"
      @closeFn="showDescStatus = false"
    >
      <div class="box" style="max-height: 400px; overflow-y: auto">
        <div class="seg-type-desc box">
          <div class="seg-type-list">
            <p v-if="searchDesc == '搜索说明'">
              <!-- <span class="td-title">搜索说明：</span> -->
              <span class="td-value"
                >可输入医院名称、科室名称和医生姓名，以及选择产品和医生分型进行搜索</span
              >
            </p>
            <template v-if="searchDesc == '排序说明'">
              <p>
                <span class="td-title">默认：</span>
                <span class="td-value">按照是否关注、医生姓名的汉语拼音顺序排序</span>
              </p>
              <p>
                <span class="td-title">按医院名：</span>
                <span class="td-value">按照医院名称的汉语拼音顺序排序</span>
              </p>
              <p>
                <span class="td-title">按医生等级：</span>
                <span class="td-value"
                  >按照医生的ABC等级排序（仅支持在选择了产品搜索时使用）</span
                >
              </p>
            </template>
          </div>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import * as py from '@/api/Physicians';
import popup from '@/views/layout/popup';
import swipeBack from '@/views/layout/swipeBack';
import {
  List,
  Search,
  DropdownMenu,
  DropdownItem,
  Button,
  Collapse,
  CollapseItem,
  RadioGroup,
  Radio,
} from 'vant';
import {
  getFirstUpperChar,
  getLocalStorage,
  setLocalStorage,
  debounce,
} from '@/utils/index';
import _ from 'underscore';
// import followBscMIxin from "@/mixins/followBscMIxin";
import refreshUserID from '@/mixins/refreshUserID';
import Nodata from '@/components/Nodata';
import CardItem from '@/views/hcp/components/CardItem';
import SummaryChart from '@/components/SummaryChart';
import CommonHeader from '@/components/CommonHeader';

export default {
  components: {
    CardItem,
    popup,
    swipeBack,
    Nodata,
    vanList: List,
    vanSearch: Search,
    vanDropdownMenu: DropdownMenu,
    vanDropdownItem: DropdownItem,
    vanButton: Button,
    vanCollapse: Collapse,
    vanCollapseItem: CollapseItem,
    vanRadioGroup: RadioGroup,
    vanRadio: Radio,
    // CommonHeader,
  },
  mixins: [refreshUserID],
  data() {
    return {
      pageName: 'doctorList',
      sortOrder: 'physician_name',
      activeTxt: 'other',
      isHcpHeader: 0,
      keyword: '',
      loading: false,
      singerData: [], // 结构数据
      initList: [], // 初始数据
      list: [],
      listLoading: false,
      finished: false,
      pageNum: 1,
      searchKey: '',
      isSearch: false,
      loadingTxt: this.$t('loading_txt'),
      physician_count: 0,
      ex_data_support_count: 0,
      is_wechat_count: 0,
      userProducts: [],
      showFilters: [],
      filterData: [],
      selectedFilter: '',
      showProduct: [{text: '全部产品', value: ''}],
      searchDisable: false,
      resetDisable: false,
      summaryData: [],
      activeNames: '',
      isSales: null,
      loadUrl: null,
      showDescStatus: false,
      isMr: false,
      searchDesc: '搜索说明', // 排序说明
      title: '', // 医生列表
    };
  },
  computed: {
    showHeaderBar() {
      if (this.$route.name != 'listHome') {
        return false;
      } else if (this.$route.name != 'doctorListHome') {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    if (this.$route.query.department_name) {
      this.title = this.$route.query.department_name;
    }
    if (this.$route.query.institution_name) {
      this.title = this.title
        ? this.title + ' - ' + this.$route.query.institution_name
        : this.$route.query.institution_name;
    }
    if (
      getLocalStorage('territory_type') &&
      getLocalStorage('territory_type') === 'MR'
    ) {
      this.isMr = true;
    }
    this.getUserId();
    // this.initPage(getLocalStorage("user_id"));
  },
  mounted() {},
  methods: {
    backFn() {
      if (this.$route.query.routeTxt === 'department') {
        // 返回科室
        this.$router.push({
          path: '/hcp/department',
          query: {
            user_id: getLocalStorage('user_id'),
            institution_id: this.$route.query.institution_id,
            tab_state: this.$route.query.tab_state,
            institution_name: this.$route.query.institution_name,
          },
        });
      } else {
        // 返回首页  (this.$route.query.routeTxt === 'home')
        this.$router.push({
          path: '/hcp/home',
          query: {
            user_id: getLocalStorage('user_id_key'),
            tab_state: this.$route.query.tab_state,
          },
        });
      }
    },
    showDescFn(status) {
      this.searchDesc = status === 1 ? '搜索说明' : '排序说明';
      this.showDescStatus = true;
    },
    closeWXWindow() {
      window.history.pushState(null, null, document.URL);
    },
    initPage(userId) {
      if (this.isSales || this.isSales === 0) {
        this.list = [];
        this.getShowProduct(userId);
        this.getTerritorieInfo(userId);
        this.pageFn(true);
      } else {
        this.getIsSales(userId);
      }
    },
    getShowProduct(userId) {
      py.getUserProduct(userId).then((prodRes) => {
        if (prodRes && prodRes.value.length) {
          this.userProducts = prodRes.value;
          py.getPhysicianFilters().then((filterRes) => {
            if (filterRes && filterRes.value) {
              this.filterData = filterRes.value;
              filterRes.value.forEach((item) => {
                this.showFilters.push({
                  text: item.view_name,
                  value: item.cnf_physician_view_id,
                });
              });
              this.selectedFilter = this.showFilters[0].value;
            }
          })
          // this.showProduct = [{text: '全部产品', value: ''}];
          // res.value.map((item) => {
          //   this.showProduct.push({
          //     text: item.product_name,
          //     value: item.product_id,
          //   });
          // });
          // if (this.isSales) {
          //   res.value.map((item) => {
          //     this.showProduct.push({
          //       text: '仅 ' + item.product_name,
          //       value: 'only_' + item.product_id,
          //     });
          //   });
          // }
        }
      });
    },
    // changeProduct() {
    //   this.selectedParting = '';
    //   if (!this.selectedProduct) {
    //     this.sortOrder = 'physician_name';
    //   }
    //   this.getShowPating();
    //   this.searchDoctor();
    // },
    changeFilter() {
      if (!this.selectedProduct) {
        this.sortOrder = 'physician_name';
      }
      this.searchDoctor();
    },
    getTerritorieInfo(userId) {
      this.getTerritoryTypingKpi(getLocalStorage('territory_id'));
    },
    sortNameFn(arr) {
      const listDataMap = _.groupBy(arr, (ele) => {
        ele.name = getFirstUpperChar(ele.physician_name);
        return ele.name;
      });
      let mapArr = [];
      for (let key in listDataMap) {
        mapArr.push({
          name: key,
          items: listDataMap[key],
        });
      }
      const sortArr = _.sortBy(mapArr, 'name');
      let list = [];
      sortArr.forEach((ele) => {
        list = list.concat(ele.items);
      });
      return list;
    },
    dealListData(arr) {
      let concatArr = [];
      // if (this.isSearch) {
      //   // 搜索
      //   concatArr = arr;
      // } else {
      //   concatArr = this.list.concat(arr);
      // }
      let topArr = [];
      let noTopArr = [];
      arr.forEach((item) => {
        if (item.is_top) {
          topArr.push(item);
        } else {
          noTopArr.push(item);
        }
      });
      // 置顶数据
      const TopData = this.sortNameFn(topArr); // .sortBy(mapArr, 'name');
      // 未置顶数据
      const noTopDataOld = this.sortNameFn(noTopArr); // .sortBy(mapArr, 'name');
      const noTopData = noTopDataOld.sort((a, b) => {
        return a.name - b.name;
      });
      // this.list = [];
      const list = TopData.concat(noTopData);
      this.list = list;
      if (!this.isSearch) {
        this.initList = list;
      }
      this.loading = false;
    },
    // isSearch true 点击搜索时触发查询数量
    pageFn(isSearch) {
      let data = {
        user_id: getLocalStorage('user_id'),
        pageNum: this.pageNum,
        pageSize: 20,
        physician_name: this.searchKey,
        product_id: this.selectedProduct,
        result: this.selectedParting,
        institution_id: this.$route.query.institution_id,
        department_name: this.$route.query.department_name,
        clickSearch: isSearch,
      };
      if (
        data.pageNum - 1 === 0 ||
        (data.pageNum - 1) * data.pageSize < this.physician_count
      ) {
        this.loading = true;
        if (this.isSales) {
          if (this.selectedProduct) {
            py.getProductPhysicianList(data, this.sortOrder)
              .then((res) => {
                this.loadList(res, isSearch);
              })
              .catch((e) => {
                this.claerData();
              });
          } else {
            py.getPhysicianList(data, this.sortOrder)
              .then((res) => {
                this.loadList(res, isSearch);
              })
              .catch((e) => {
                this.claerData();
              });
          }
        } else {
          if (this.selectedProduct) {
            py.getManagementProductPhysicianList(data, this.sortOrder)
              .then((res) => {
                this.loadList(res, isSearch);
              })
              .catch((e) => {
                this.claerData();
              });
          } else {
            py.getManagementPhysicianList(data, this.sortOrder)
              .then((res) => {
                this.loadList(res, isSearch);
              })
              .catch((e) => {
                this.claerData();
              });
          }
        }
      } else {
        this.finished = true;
        this.listLoading = false;
      }
    },
    // debouncePageFn: _.debounce(function () {
    //   this.pageFn();
    // }, 500),
    loadList(aRes, isSearch) {
      let res = '';
      if (isSearch) {
        this.ex_data_support_count = aRes[0];
        this.is_wechat_count = aRes[1];
        res = aRes[2];
      } else {
        res = aRes;
      }
      if (res && res.value && res.value.length > 0) {
        if (res && res['@odata.count']) {
          this.physician_count = res['@odata.count'];
        }
        let resArr = res.value;
        if (res && resArr.length > 0) {
          resArr.forEach((ele) => {
            this.list.push(ele);
          });
        }
        this.initList = JSON.parse(JSON.stringify(this.list));
      } else {
        this.physician_count = 0;
        this.list = [];
        // this.loading = false;
        if (!this.isSearch) {
          // 非搜索
          this.finished = true;
        }
        this.isSearch = false;
      }
      this.loading = false;
      this.listLoading = false;
      this.searchDisable = false;
      this.resetDisable = false;
    },
    claerData() {
      this.listLoading = false;
      this.loading = false;
      this.isSearch = false;
      this.searchDisable = false;
      this.resetDisable = false;
      this.list = [];
    },

    onLoad() {
      if (!this.loading) {
        this.pageNum++;
        this.listLoading = true;
        this.pageFn();
      }
    },
    refreshFn() {
      this.list = [];
      this.pageFn();
    },
    selectItem(item) {
      this.$router.push({
        path: '/hcp/detail',
        query: {
          physician_id: item.physician_id,
          user_id: this.$route.query.user_id,
          selectedLabel: 'persona',
          institution_id: this.$route.query.institution_id,
          institution_name: this.$route.query.institution_name,
          routeTxt: this.$route.query.routeTxt,
          tab_state: this.$route.query.tab_state,
          department_name: this.$route.query.department_name,
        },
      });
    },
    searchDoctor() {
      this.physician_count = 0;
      this.ex_data_support_count = 0;
      this.is_wechat_count = 0;
      this.list = [];
      this.pageNum = 1;
      this.finished = false;
      this.listLoading = false;
      this.isSearch = true;
      this.searchKey = this.keyword;
      this.resetDisable = true;
      this.pageFn(true);
    },
    reset() {
      this.physician_count = 0;
      this.ex_data_support_count = 0;
      this.is_wechat_count = 0;
      this.sortOrder = 'physician_name';
      this.list = [];
      this.pageNum = 1;
      this.finished = false;
      this.keyword = '';
      this.searchKey = '';
      this.selectedProduct = '';
      this.selectedParting = '';
      this.searchDisable = true;
      this.showProductParting = [{text: '全部分型', value: ''}];
      this.pageFn(true);
    },

    getCurrentProduct() {
      let currentProduct = '';
      if (this.selectedProduct.indexOf('Azilect') > -1) {
        currentProduct = 'Azilect';
      } else if (this.selectedProduct.indexOf('Brintellix') > -1) {
        currentProduct = 'Brintellix';
      } else if (this.selectedProduct.indexOf('Ebixa') > -1) {
        currentProduct = 'Ebixa';
      } else if (this.selectedProduct.indexOf('Lexapro') > -1) {
        currentProduct = 'Lexapro';
      }
      return currentProduct;
    },
    getTerritoryTypingKpi(territory_id) {
      py.getTerritoryTypingKpi(territory_id).then((res) => {
        if (res && res.value.length) {
          this.summaryData = [
            {
              label: '问卷已填写',
              value: res.value[0].ty_count,
            },
            {
              label: '问卷未填写',
              value: res.value[0].no_ty_count,
            },
            {
              label: '问卷完成度',
              value: (res.value[0].complate_rate * 100).toFixed(2) + '%',
            },
          ];
        }
      });
    },
    getIsSales(user_id) {
      py.getIsSales(user_id).then((res) => {
        if (res && res.value && res.value.length) {
          this.isSales = res.value[0].is_sales;
          this.initPage(user_id);
        } else {
          this.$router.push({
            path: '/error',
            query: {},
          });
        }
      });
    },
    changeFn($event) {
      this.searchDoctor();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/follow-list.scss';
.view-wrapper ::v-deep .cube-index-list-fixed,
.view-wrapper ::v-deep .cube-index-list-anchor {
  text-align: left;
}
.search-bar-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  // padding-bottom: 10px;
  background: #fff;
  &.pd-btm0 {
    padding-bottom: 0;
  }
  .search-button {
    font-size: 14px;
    color: #333333;
    width: 50px;
    text-align: center;
    color: #aa341b;
    // position: absolute;
    // right: 20px;
    // top: 10px;
    i {
      font-size: 18px;
      color: #ccc;
    }
  }
}
.search-filter {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  align-items: center;
  padding: 0 5px 0 10px;
  &.j-center {
    justify-content: flex-start;
    padding: 0.166667rem 0 0.166667rem 18px;
  }
  .search-filter-drop {
    flex: 1;
    max-width: 50%;
    ::v-deep .van-dropdown-menu__item {
      justify-content: flex-start;
    }
  }
  .search-filter-reset {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    font-size: 15px;
    font-family: Arial;
    font-weight: 400;
    color: #aa341b;
  }
  .search-filter-button {
    width: 1.8rem;
    height: 0.8rem;
    & + .search-filter-button {
      margin-left: 5px;
    }
  }
}
.search-filter1 {
  white-space: nowrap;
}
::v-deep .van-dropdown-menu__bar {
  box-shadow: none;
}
.hcp-list-explain {
  font-size: 11px;
  background-color: #e0e6ed;
  padding: 15px;
  text-align: center;
}
.view-wrapper {
  width: 100%;
  height: 100%;
  // overflow-y: auto;
  // position: relative;
  .add-fav-explain {
    font-size: 14px;
    background-color: #e1f3da;
    padding: 15px 0;
    text-align: center;
  }
  .bac-be {
    height: 30px;
    background-color: #ebebee;
  }
}
.bac-be {
  height: 30px;
  background-color: #ebebee;
}
.show-more {
  font-size: 12px;
  text-align: center;
  color: #aa341b;
  margin-top: 40px;
  padding-bottom: 60px;
}
.radius-btn {
  border-radius: 20px;

  padding-left: 25px;
  padding-right: 25px;
}
.dimension-icon {
  margin-right: 4px;
}
.custom ::v-deep .name {
  display: flex;
  .name-w {
    // min-width: 60px;
  }
}
.no-list {
  ::v-deep .van-list__finished-text,
  ::v-deep .van-list__placeholder {
    display: none;
  }
}
.online-tab-box {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  .online-tab {
    width: 43%;
    padding: 10px;
    text-align: center;
    border-radius: 4px;
    background-color: #f0f0f0;
    &.active {
      color: #ffffff;
      background-color: #aa341b;
    }
  }
}
.hospital-box {
  .mg-to {
    margin-top: -5px;
  }
  img {
    max-width: 100%;
  }
}
.summary-container {
  display: flex;
  align-items: center;
  justify-content: space-around;

  .summary-item {
    flex: 1;
    padding: 15px 0 0;
    .label {
      font-size: 10px;
      color: #666;
      display: block;
    }
    .value {
      padding: 10px 0 5px;
      display: block;
      font-size: 28px;

      color: #ab351c;
      .v-bd {
        // border-bottom: 1px solid #ab351c;
        font-weight: initial;
      }
    }
  }
}
.van-cps ::v-deep .van-cell__title {
  font-size: 12px !important;
}
.van-cps ::v-deep .van-cell {
  padding-top: 0.166667rem;
  padding-bottom: 0.166667rem;
}
.hospital-count-div {
  display: flex;
  font-size: 12px;
  color: #aaa;
  .hospital-count-item + .hospital-count-item {
    margin-left: 10px;
  }
  .f-blod {
    // color: #aa341b;
    font-weight: bold;
  }
}
.f-quest {
  display: flex;
  &.f-quest-mg {
    margin: 0 5px;
  }
  .question-icon {
    color: #aa341b;
    margin-left: 3px;
    // margin-right: 5px;
  }
}
</style>
