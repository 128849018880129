<template>
  <!-- <div v-if="tagList.length || xueList.length || styleList.length" class="tag-container bac-white"> -->
  <div class="tag-container bac-white">
    <div class="tag-title box-right">
      <CommonHeader
        :title="$t('persona_tag')"
        :icon="'user-tag'"
        :no-feedback="true"
      />
    </div>
    <van-tabs
      v-if="tabLabels.length > 1"
      type="card"
      color="#aa341b"
      class="product-tab"
    >
      <van-tab
        v-for="(item, index) in tabLabels"
        :key="index"
        :title="item.label"
      >
        <div class="tag-line">
          <product-tags
            class="tag-content"
            :tag-item="item.tagList"
            :product="item.id"
            @refresh="getShowProduct"
          />
        </div>
      </van-tab>
    </van-tabs>
    <div v-else-if="tabLabels.length == 1">
      <div class="product-one">{{ tabLabels[0].label }}</div>
      <div class="tag-line">
        <product-tags
          class="tag-content"
          :tag-item="tabLabels[0].tagList"
          :product="tabLabels[0].id"
          @refresh="getShowProduct"
        />
      </div>
    </div>
    <Nodata v-else :loading="loading" :empty-txt="$t('no_data')" />
    <!-- <popup v-if="showDescStatus" :title="tagTitle" @closeFn="closeFn">
      <div class="box" style="max-height: 400px; overflow-y: auto;">
        <ListDesc :desc-list="personListArr" />
      </div>
    </popup>-->
  </div>
</template>
<script>
import _ from "underscore";
import ProductTags from "@/components/ProductTags";
import Nodata from "@/components/Nodata";
import CommonHeader from "@/components/CommonHeader";
// import Feedback from '@/components/Feedback';
import * as py from "@/api/Physicians";
import { personTagDescList } from "@/utils/defaultConstant";
import { Tabs, Tab, Loading } from "vant";
export default {
  components: {
    ProductTags,
    CommonHeader,
    Nodata,
    vanTabs: Tabs,
    vanTab: Tab,
    // ListDesc,
    // popup
    // Feedback
  },
  props: {
    updateTime: {
      type: String,
      default() {
        return "";
      },
    },
    labelType: {
      type: String,
      default() {
        return this.$t("persona_tag");
      },
    },
    physicianId: {
      type: String,
      default() {
        return "";
      },
    },
    showProduct: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      tagTitle: "",
      personTagDescList,
      personListArr: [],
      showDescStatus: false,
      loading: false,
      personaTagObj: {
        personaTags: [],
      },
      tagList: [],

      styleObj: {
        高: "high",
        中: "medium",
        低: "low",
        无: "none",
      },
      activeTreatment: "",
      detailTreatments: [],
      selectedLabel: "",
      radarLoading: false,
      disabled: false,
      tabLabels: [],
      loop: false,
      autoPlay: false,
      showDots: false,
      slideOptions: {
        listenScroll: true,
        probeType: 3,
        /* lock y-direction when scrolling horizontally and  vertically at the same time */
        directionLockThreshold: 0,
      },
      scrollOptions: {
        /* lock x-direction when scrolling horizontally and  vertically at the same time */
        directionLockThreshold: 0,
      },
    };
  },
  computed: {
    initialIndex() {
      let index = 0;
      index = this.findIndex(
        this.tabLabels,
        (item) => item.label === this.selectedLabel
      );
      return index;
    },
  },
  mounted() {
    this.getShowProduct();
  },
  methods: {
    getShowProduct() {
      let tempLabels = [];
      this.tabLabels = [];
      this.selectedLabel = this.selectedLabel
        ? this.selectedLabel
        : this.showProduct[0].product_name || this.showProduct[0].product_id;
      this.showProduct.map((item) => {
        tempLabels.push({
          label: item.product_name ? item.product_name : item.product_id,
          id: item.product_id,
          tagList: [],
        });
      });
      this.getLabelList(tempLabels);
    },
    getLabelList(tempLabels) {
      this.personaTagObj.personaTags = [];
      this.loading = true;
      py.getVPhysicianLabelByPhysicianId(this.physicianId)
        .then((res) => {
          if (res.value.length) {
            const tags = res.value.filter((item) => (item.label_name_parent !== '难易度'));
            const listDataMap = _.groupBy(tags, "product_id");
            for (let product_id in listDataMap) {
              listDataMap[product_id].map((item) => {
                tempLabels.map((el) => {
                  if (el.id === product_id) {
                    el.tagList.push({
                      product_id: product_id,
                      label: item.label_name,
                      class: "",
                      labelType: item.label_type,
                      subLabelType: item.label_name_parent,
                    });
                  }
                });
              });
            }
            this.tabLabels = tempLabels;
          } else {
            this.tabLabels = [];
          }
          // debugger;
          // console.log('tabLabels', this.tabLabels);
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    changePage(current) {
      this.selectedLabel = this.tabLabels[current].label;
    },
    scroll(pos) {
      const x = Math.abs(pos.x);
      const tabItemWidth = this.$refs.tabNav.$el.clientWidth;
      const slideScrollerWidth = this.$refs.slide.slide.scrollerWidth;
      const deltaX = (x / slideScrollerWidth) * tabItemWidth;
      this.$refs.tabNav.setSliderTransform(deltaX);
    },
    findIndex(ary, fn) {
      if (ary.findIndex) {
        return ary.findIndex(fn);
      }
      let index = -1;
      ary.some((item, i, ary) => {
        const ret = fn.call(this, item, i, ary);
        if (ret) {
          index = i;
          return ret;
        }
      });
      return index;
    },
    closeFn(status) {
      this.showDescStatus = status;
    },
    showDescFn(obj) {
      this.personListArr = [];
      let stObj = this.personTagDescList.find((ele) => ele.title === obj.label);
      if (stObj) {
        this.tagTitle = obj.label;
        this.personListArr.push(stObj);
        this.showDescStatus = true;
      }
    },
    showMoreTagFn(index, item) {
      this.xueList[index].showMore = !item.showMore;
      this.xueList[index].TagsArr.forEach((ele, sIndex) => {
        if (sIndex > 5) {
          ele.class = this.xueList[index].showMore
            ? this.styleObj[ele.grade]
            : this.styleObj[ele.grade] + " hide";
        }
      });
    },
    tagPersonClick(item) {
      this.showDescFn(item);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/var.scss";
.tag-container {
  padding-bottom: 10px;
  .tag-title {
    padding: 10px 10px 0 10px;
  }
  .tag-box {
    padding: 10px 10px 0 10px;
    ::v-deep .group-header {
      background-color: #f4f4f4 !important;

      .icon {
        // display: none;
        background-color: #aaa;
      }
    }
  }
}
.tag-line {
  display: flex;
  justify-content: flex-start;
  align-items: top;
  margin: 0 10px 10px 10px;
  .tag-line-label {
    font-size: 13px;
    // width: 80px;
    text-align: left;
    margin-top: 10px;
    white-space: nowrap;
    .tag-label-icon {
      color: #ddd;
      font-size: 10px;
      margin-right: 4px;
    }
  }
  .no-data {
    font-size: 12px;
    color: #ccc;
    margin: 10px 10px 10px 10px;
  }

  &.sub-tag {
    margin-left: 30px;
  }
}
.title {
  font-size: 14px;
}
.feedback {
  float: right;
  margin: 15px;
}
.t-label-title {
  font-size: 11px;
  font-weight: normal;
  color: #666;
}
.t-label {
  font-size: 10px;
  color: #333;
  font-weight: initial;
  position: relative;
  padding-left: 25px;
  & + .t-label {
    margin-left: 10px;
  }
  @include tag-before();
  &:before {
    content: "";
    width: 20px;
    height: 14px;
    border-radius: 4px;
    background-color: inherit;
    position: absolute;
    left: 0;
    top: -2px;
  }
}
.t-type {
  margin: 10px 0 0 0;
  font-size: 13px;
  text-align: left;
  padding: 5px 10px;
  .grey-text {
    margin-left: 10px;
    font-size: 12px;
    color: #999;
  }
}
.subtitle {
  margin: 15px 5px 5px 5px;
  text-align: center;
  border-radius: 8px;
  // border: 2px solid #f4f4f0;
  padding: 5px;
}
.tag-content {
  margin: 0 10px 0 10px;
  ::v-deep .hide {
    display: none;
  }
}
@include show-more();
.product-tab ::v-deep .van-tabs__wrap {
  padding-top: 10px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}
</style>
