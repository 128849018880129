<template>
  <div class="app-container">
    <router-view />
    <TabBar />
    <div v-if="pendingCount > 0 && !isApprovalPage" class="floating-icon" @click="goToApprovalPage">
      <van-icon name="bell" />
      <div class="badge">{{ pendingCount }}</div>
    </div>
  </div>
</template>

<script>
import TabBar from '../hcp/components/tabBar.vue';
import { checkPendingApprovals } from '@/api/approval';
import { getLocalStorage } from '@/utils/index';
import { Icon } from 'vant';

export default {
  name: 'Index',
  components: {
    TabBar,
    vanIcon: Icon,
  },
  data() {
    return {
      pendingCount: 0,
      isApprovalPage: false,
    };
  },
  watch: {
    $route(to) {
      this.isApprovalPage = to.path === '/approval';
    },
  },
  mounted() {
    this.checkPendingApprovals();
    this.isApprovalPage = this.$route.path === '/approval';
  },
  methods: {
    async checkPendingApprovals() {
      const userId = getLocalStorage('user_id');
      const res = await checkPendingApprovals(userId);
      this.pendingCount = res;
    },
    goToApprovalPage() {
      this.$router.push({ path: '/approval' });
    },
  },
};
</script>
<style scoped lang="scss">
.app-container {
  height: 100%;
}

.floating-icon {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 20px;
  color: #aa341b;
  background-color: white;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #aa341b;
  color: white;
  border-radius: 50%;
  padding: 3px 6px;
  font-size: 12px;
}
</style>
