<template>
  <div class="content-share">
    <van-search
      v-model="searchValue"
      shape="round"
      placeholder="请输入搜索关键词"
    />
    <div class="c-serach-box">
      <van-dropdown-menu active-color="#aa341b" style="margin-right: 40px">
        <van-dropdown-item
          v-model="selectedProduct"
          :options="userProduct"
          get-container="body"
        />
      </van-dropdown-menu>
      <div class="c-serach-item">
        <div class="c-s-label" style="font-size: 13px;">标签筛选：</div>
        <div class="flex flex-wrap" style="margin-bottom: -10px">
          <div
            v-for="(se, index) in tagList"
            :key="index"
            style="margin-bottom: 10px; font-size: 11px"
            class="c-s-item"
            :class="{active: se.active}"
            @click="changeTag(se)"
          >
            <div class="c-s-flex">{{ se.product_name }}</div>
          </div>
        </div>
      </div>
    </div>
    <van-loading
      v-if="loading"
      style="margin-top: 80px; margin-bottom: 40px"
      type="spinner"
      color="#aa341b"
    />
    <div v-if="contentsList.length">
      <div class="action-plan">
        <!-- {{ nbaKeyMessages }} -->
        <div v-for="(item, index) in contentsList" :key="index">
          <p class="action-text flex">
            <span>
              <!-- <span class="action-flag"></span> -->
              <span class="key-message-labels">
                <span>
                  {{ item.content_type }}
                </span>
              </span>
              {{ item.content_name }}
              <p>
                <span
                  class="key-message-labels product-outlined"
                  v-for="(tag, tagIndex) in JSON.parse(item.product)"
                  :key="tagIndex"
                >
                  <span>
                    {{ tag.product_name }}
                  </span>
                </span>
              </p>
            </span>
            <span class="buttons">
              <img
                src="@/assets/lundbeck/NBA/preview.png"
                alt=""
                style="margin-left: 4px"
                @click="previewImage(item.s3_file_name)"
              />
              <img
                src="@/assets/lundbeck/NBA/forward.png"
                alt=""
                style="margin-left: 4px"
                @click="
                  choosePhysician(item)
                "
              />
            </span>
          </p>
        </div>
      </div>
    </div>
    <van-empty v-else-if="!loading" description="暂无内容" />
    <swipeDialog
      v-if="pdfLink"
      backtxt="返回"
      :title="'预览'"
      @closeFn="closePdfFn"
    >
      <van-loading v-if="fileLoading" class="loading">加载中...</van-loading>
      <pdf v-show="pdfLink" :src="pdfLink" />
    </swipeDialog>
    <DoctorListSelectAll
      v-if="showDoctorSelect"
      :multipleMode="false"
      :defaultSelectedList="doctors"
      @close="showDoctorSelect = false"
      @select="handleSelectDoctor"
    />
  </div>
</template>

<script>
import * as py from '@/api/Physicians';
import {
  Search,
  Loading,
  ImagePreview,
  Empty,
  DropdownMenu,
  DropdownItem,
} from 'vant';
import swipeDialog from '@/views/layout/swipeDialog';
import {getLocalStorage} from '@/utils';
import DoctorListSelectAll from '@/components/DoctorListSelectAll.vue';
import {wecomSDK} from '@/utils/wecom';
import {getMimeTypeBySuffix} from '@/utils/index';
import pdf from 'vue-pdf';
// import pdfComponent from '@/views/hcp/components/pdfComponent.vue';
export default {
  name: 'ContentShare',
  components: {
    [Search.name]: Search,
    swipeDialog,
    DoctorListSelectAll,
    // pdfComponent,
    pdf,
    [Empty.name]: Empty,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    vanLoading: Loading,
  },
  data() {
    return {
      searchValue: '',
      loading: true,
      contentsList: [],
      pdfLink: null,
      pdfKey: null,
      showDoctorSelect: false,
      doctors: [],
      forwardContent: {
        id: '',
        title: '',
        physicianId: '',
      },
      detailStatus: false,
      fileLoading: false,
      userProduct: [],
      tagList: [
        {
          product_name: '图文',
          product_id: '图文',
          active: true,
        },
        {
          product_name: '幻灯',
          product_id: '幻灯',
          active: true,
        },
      ],
      selectedProduct: '',
    };
  },
  watch: {
    searchValue(val) {
      this.fetchNba(val);
    },
    selectedProduct(val) {
      this.fetchContents();
    },
  },
  mounted() {
    this.fetchContents();
    this.fetchProduct();
  },
  methods: {
    fetchContents(content_name) {
      py.findContents(
        content_name || '',
        this.selectedProduct,
        this.tagList
          .filter((item) => item.active)
          .map((item) => item.product_id),
      ).then((res) => {
        if (res.value) {
          this.contentsList = res.value;
        }
        this.loading = false;
      });
    },
    fetchProduct() {
      py.getUserProduct(getLocalStorage('user_id')).then((res) => {
        this.userProduct = [
          {
            text: '全部产品',
            value: '',
          },
          ...res.value.map((item) => {
            return {
              text: item.product_name,
              value: item.product_id,
            };
          }),
        ];
      });
    },
    closePdfFn() {
      this.$store.dispatch('setDialogStatus', false);
      this.pdfLink = null;
    },

    choosePhysician(content) {
      console.log(content);
      this.forwardContent.id = content.content_id;
      this.forwardContent.title = content.content_name;
      this.showDoctorSelect = true;
    },
    handleSelectDoctor(doctors) {
      console.log(doctors);
      this.forwardContent.physicianId = doctors[0].physician_id;
      this.doctors = doctors;
      this.showDoctorSelect = false;
      this.forwardWechat();
    },
    async forwardWechat() {
      console.log('forward', this.forwardContent.title);
      const toast = this.$createToast({
        time: 10000,
        mask: false,
      });
      toast.show();
      try {
        await wecomSDK.shareWechatMessage({
          content_id: this.forwardContent.id,
          sender_user_id: getLocalStorage('user_id'),
          sender_user_name: getLocalStorage('user_name'),
          sender_territory_id: getLocalStorage('territory_id'),
          receiver_physician_id: this.forwardContent.physicianId,
          send_time: new Date(),
          title: this.forwardContent.title,
          url: 'https://hcp360-mobile-sandbox.lundbecksys.cn/resource/nba/azilect/azilect-slides-001.pdf',
        });
      } catch (err) {
        this.$toast.fail('转发失败，请稍后重试');
      }
      toast.hide();
    },
    previewImage(slides) {
      if (slides.indexOf('.pdf') > 0) {
        this.getPictureUrl(slides);
      } else {
        let images = [];
        slides.split(',').forEach((image) => {
          images.push('/resource/nba/brintellix/key-message/' + image);
        });
        ImagePreview({
          images: images,
          loop: false,
          showIndicators: true,
        });
      }
    },
    getPictureUrl(key) {
      console.log('cnm---->', key);
      py.getS3ByKey(key).then((res) => {
        let currentMimeType = getMimeTypeBySuffix(
          key.split('.').pop().toLowerCase(),
        );
        let blob = new Blob([res.data], {
          type: currentMimeType,
        });
        this.pdfKey = key;
        this.pdfLink = window.URL.createObjectURL(blob);
        this.fileLoading = false;
        this.$store.dispatch('setDialogStatus', true);
      });
    },
    changeTag(se) {
      console.log(se);
      se.active = !se.active;
      this.fetchContents();
    },
  },
};
</script>
<style lang="scss" scoped>
.content-share {
  min-height: 100vh;
  // background: #fff;
  // padding: 10px;
}
.c-serach-box {
  //   margin-top: 20px;
  background: #fff;
  display: flex;
  padding: 0 10px;
  margin-bottom: 5px;
  // justify-content: space-between;
  ::v-deep .van-dropdown-menu__bar {
    box-shadow: none;
  }
}
.action-plan {
  padding: 0 4px 70px 4px;
  height: calc(100vh - 190px);
  overflow: auto;
}
.key-message-labels > span {
  background: rgba(236, 233, 223, 0.5);
  border-radius: 20px;
  font-size: 11px;
  color: #333;
  white-space: nowrap;
  padding: 2px 8px;
  margin: 0 4px 0 0;
}
.product-outlined > span {
  background: #fff;
  border: 1px solid #aa341b;
  color: #aa341b;
}
.action-highlight {
  font-size: 16px;
  font-family: Arial;
  font-weight: 700;
  color: #aa341b;
  opacity: 1;
  text-align: left;
  line-height: 1.4;
  margin-bottom: 15px;
  .action-flag {
    background: #aaa;
  }
  span {
    font-weight: 700;
  }
}
.action-tag {
  color: #ab351c;
}
.highlight {
  color: #aa341b;
}
.action-progress {
  display: flex;
  align-items: center;
  height: 30px;
  background: rgba(236, 233, 223, 0.5);
  border-radius: 4px;
  margin-bottom: 20px;
  p {
    color: #aa341b;
    font-size: 14px;
    font-family: Arial;
    font-weight: 700;
    margin-left: 10px;
  }
}
.action-no-data {
  padding: 20px;
  color: #999;
}
.action-text {
  font-size: 14px;
  font-family: Arial;
  font-weight: 400;
  color: #2c3034;
  opacity: 1;
  text-align: left;
  line-height: 1.3;
  margin-bottom: 5px;
  justify-content: space-between;
  padding: 10px 12px 14px 12px;
  border-radius: 8px;
  border: 1px solid #f4f4f4;
  background: #fff;

  span.buttons {
    display: flex;
    justify-content: flex-end;
    width: 80px; // 根据按钮总宽度调整

    img {
      display: inline-block;
      width: 26px; // 64 x 96
      height: 39px;
    }
  }
}

.action-flag {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #aa341b;
  opacity: 1;
  border-radius: 4px;
  margin: 0 5px 2px 0;
}
</style>
