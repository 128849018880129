export const colorlist = ['#ab351c', '#7EC0EE', '#aa341b', '#FFD700', '#A290FF ', '#F19C9C', '#9CCC65', '#FAD773', '#9EA9BE', '#9CE8CA', '#9DBCFB', '#FF99FF', '#ea7ccc'];
export const academicColorList = ['#ab351c', '#aa341b', '#FFD700', '#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'];
export const type_view_list = [
  {
    text: 'old_age',
    label: 'highRisk',
    approvalTags: [{
      tag_name: 'I的持续治疗缓解深度深',
      tag_id: 'id001',
      solveStatus: false
    }, {
      tag_name: '安全性：没有心脏毒性',
      tag_id: 'id001',
      solveStatus: false
    }, {
      tag_name: '纯口服方案的便利性',
      tag_id: 'id001',
      solveStatus: false
    }], // 认可
    disapprovalTags: [
      {
        tag_name: 'I和R不能联合报销',
        tag_id: 'id001',
        solveStatus: false
      }, {
        tag_name: '资源覆盖少，I用药经验不丰富',
        tag_id: 'id001',
        solveStatus: false
      }
    ] // 不认可
  }, {
    text: 'old_age_standard',
    label: 'standardRisk',
    approvalTags: [{
      tag_name: 'I的疗效',
      tag_id: 'id001',
      solveStatus: false
    }], // 认可
    disapprovalTags: [
      {
        tag_name: '标危患者I的疗效过于强',
        tag_id: 'id001',
        solveStatus: false
      }, {
        tag_name: '缺少PI类药物的用药经验',
        tag_id: 'id001',
        solveStatus: false
      }
    ] // 不认可
  }, {
    text: 'middle_aged',
    label: 'highRisk1',
    approvalTags: [{
      tag_name: 'I的持续治疗缓解深度深',
      tag_id: 'id001',
      solveStatus: false
    }, {
      tag_name: '安全性：没有心脏毒性',
      tag_id: 'id001',
      solveStatus: false
    }, {
      tag_name: '纯口服方案的便利性',
      tag_id: 'id001',
      solveStatus: false
    }], // 认可
    disapprovalTags: [
      {
        tag_name: 'I和R不能联合报销',
        tag_id: 'id001',
        solveStatus: false
      }, {
        tag_name: '资源覆盖少，I用药经验不丰富',
        tag_id: 'id001',
        solveStatus: false
      }
    ] // 不认可
  }, {
    text: 'middle_aged_standard',
    label: 'standardRisk1',
    approvalTags: [{
      tag_name: 'I的疗效',
      tag_id: 'id001',
      solveStatus: false
    }], // 认可
    disapprovalTags: [
      {
        tag_name: '标危患者I的疗效过于强',
        tag_id: 'id001',
        solveStatus: false
      }, {
        tag_name: '缺少PI类药物的用药经验',
        tag_id: 'id001',
        solveStatus: false
      }
    ] // 不认可
  }
];

export const assessmentListDesc = {
  '恩莱瑞认可度': '医生对于恩莱瑞的使用和观念认可程度，从PI持续治疗和早期转换进行评估。',
  '学术影响力': '医生的学术影响力，通过医生的院内任职、院外任职、学术发表、临床试验、获奖等公开信息来评估。',
  '武田合作': '医生与武田的合作紧密度，通过临床试验合作、内部会议合作、媒体合作等方面评估。',
  '观念易转化': '医生对于接受恩莱瑞的可能性评估，从沟通、服务、产品熟悉、品牌满意度、性格类型、未满足需求等角度综合评估。',
  '优先关注': '医生需要关注的优先级，综合考虑医生潜力、医院重要度、医生当前观念状态等因素。'
};

export const activitySearchList = [
  {
    headline: 'filter_publication',
    title: 'filter_type',
    initShow: true,
    selected: [],
    field: 'type',
    arr: [
      { title: '论文', value: 'type1', active: false, disabled: false },
      { title: '临床试验', value: 'type2', active: false, disabled: false },
      { title: '国自然项目', value: 'type3', active: false, disabled: false },
      { title: '获奖', value: 'type4', active: false, disabled: false }
    ]
  },
  // {
  //   title: 'filter_is_mm',
  //   initShow: true,
  //   selected: [],
  //   field: 'is_mm',
  //   checkType: true,
  //   arr: [
  //     { title: '是', value: 1, active: false, disabled: false, alias: 'MM领域(是)' },
  //     { title: '否', value: 0, active: false, disabled: false, alias: 'MM领域(否)' }
  //   ]
  // },
  {
    title: 'filter_author',
    initShow: true,
    selected: [],
    field: 'author_seq_no',
    arr: [
      { title: '第一作者', value: 1, active: false, disabled: false },
      { title: '其他', value: 0, active: false, disabled: false }
    ]
  },
  {
    title: 'filter_if',
    initShow: true,
    selected: [],
    field: 'journal_if',
    arr: [
      { title: '无', value: null, active: false, disabled: false },
      { title: 'IF ≤ 2', value: [0, 2], active: false, disabled: false },
      { title: '2 < IF ≤ 5', value: [2, 5], active: false, disabled: false },
      { title: '5 < IF ≤ 10', value: [5, 10], active: false, disabled: false },
      { title: 'IF > 10', value: [10, 10000], active: false, disabled: false }
    ]
  }
];

export const activityTypeObj = {
  '论文': 'type1',
  '临床试验': 'type2',
  '国自然项目': 'type3',
  '获奖': 'type4'
};

export const redarDescList = [
  {
    title: '综合影响力',
    value: '评估医生综合影响力包含院内院外和学术影响力'
  },
  {
    title: '学术能力',
    value: '通过教育经历、学术和科研成就评估医生的学术能力'
  },
  {
    title: '医生认可度',
    value: '通过医院潜力和客户观念认知潜力评估医生的潜力'
  },
  {
    title: '学术活跃度',
    value: '评估医生参与学术会议和临床试验的活跃度'
  },
  {
    title: '在线活跃度',
    value: '通过与外部自媒体和微信公众号的月应用时长来评估医师线上的活跃度'
  },
  {
    title: '学术互动',
    value: '评估医生与灵北的合作关系友好度'
  }
];

export const sortDynamic = {
  'persona': [
    { components: 'tabTags', desc: '画像标签' },
    { components: 'radarChart', desc: '评估' },
    { components: 'relevance', desc: '圈层关系' },
    { components: 'goodAt', desc: '擅长' },
    { components: 'brief', desc: '简介' },
    { components: 'schedulesTime', desc: '出诊时间' },
    { components: 'relationship', desc: '学术合作关系' },
    { components: 'businessScore', desc: '企业合作' },
    { components: 'contactPreference', desc: '客户互动趋势图' }
  ],
  'activity': [
    { components: 'activitySummary', desc: '学术成果汇总' },
    { components: 'topActivityKey', desc: 'TOP论文关键词' }
  ],
  'perception': [
    { components: 'SegType', desc: '分型' },
    { components: 'typingHistory', desc: '分型历史' },
    { components: 'technique', desc: '手术技能评估' },
    { components: 'ivus', desc: '不使用/推广IVUS的原因' },
    { components: 'situation', desc: '手术情形' }
  ]
};
// 画像标签描述
export const personTagDescList = [
  {
    title: '手术能力突出',
    value: '手术技能分数等于5分'
  },
  {
    title: '学术大咖',
    value: '学术能力分数得分全国前10%'
  },
  {
    title: '学术活跃者',
    value: '学术能力分数得分全国前10%-30%'
  },
  {
    title: '企业合作踊跃者',
    value: '企业合作意愿度分数得分全国前10%'
  },
  {
    title: '企业互动活跃者',
    value: '企业互动分数得分全国前10%'
  },
  {
    title: '新视野活跃用户',
    value: '在线活跃度分数得分全国前10%'
  }
];
// 分型描述
export const segTypeList = [
  // {
  //   title: '观望者',
  //   value: '对腔内影像工具使用有限（腔内影像学渗透率<20%）'
  // },
  // {
  //   title: '潜力股-OCT爱好者',
  //   value: '偏好于使用OCT（OCT渗透率≥10% 或 OCT渗透率≥B司IVUS渗透率）'
  // },
  // {
  //   title: '潜力股-V司爱好者',
  //   value: '偏好于使用V司IVUS（V司IVUS渗透率≥20% 或V司IVUS渗透率≥B司IVUS渗透率）'
  // },
  // {
  //   title: '潜力股-国产爱好者',
  //   value: '偏好于使用国产IVUS（国产IVUS渗透率≥B司IVUS渗透率）'
  // },
  // {
  //   title: '潜力股-中立术者',
  //   value: '未有明显品牌倾向性（腔内影像学渗透率≥20% 但 B司IVUS渗透率<20%）'
  // },
  // {
  //   title: '主力军',
  //   value: '偏好于使用B司IVUS（B司IVUS渗透率≥20%）'
  // },
  {
    title: '行业领袖',
    value: '现任/候任/曾任国家级协会主委、副主委及委员'
  },
  {
    title: '省级主委',
    value: '现任/候任/曾任省级协会主委、副主委及委员'
  },
  {
    title: '行业新星',
    value: '现任/候任/曾任国家级/省级青年委员'
  },
  {
    title: 'B司主力军–OCT倾向者',
    value: 'B司IVUS渗透率≥30%且OCT渗透率≥15%'
  },
  {
    title: 'B司主力军–V司倾向者',
    value: 'B司IVUS渗透率≥30%且V司IVUS渗透率≥ 20%'
  },
  {
    title: 'B司主力军',
    value: 'B司IVUS渗透率≥30%且V司IVUS渗透率<20%且OCT渗透率<15%'
  },
  {
    title: 'OCT主力军',
    value: 'B司IVUS渗透率<30%且OCT渗透率≥15%'
  },
  {
    title: 'V司主力军',
    value: 'B司IVUS渗透率<30%且V司IVUS渗透率≥20%'
  },
  {
    title: '厂家平衡者',
    value: '11%≤B司IVUS渗透率<30%且5%≤V司IVUS渗透率<20%或4%≤OCT渗透率<15%'
  },
  {
    title: 'B司潜力股',
    value: '11%≤B司IVUS渗透率<30%且V司IVUS渗透率<5%且OCT渗透率<4%'
  },
  {
    title: 'B司潜力股–OCT倾向者',
    value: 'B司IVUS渗透率<11%且4%≤OCT渗透率<15%'
  },
  {
    title: 'B司潜力股–V司倾向者',
    value: 'B司IVUS渗透率<11%且5%≤V司IVUS渗透率<20%'
  },
  {
    title: 'B司潜力股',
    value: 'B司IVUS渗透率<11%且V司IVUS渗透率<5%且OCT渗透率<4%'
  }
];

export const quanDesc = {
  '5分': '专家： IVUS指导下进行复杂病变的评价处理（如左主干、钙化病变、分叉病变、CTO病变等）',
  '4分': '高级：可无障碍独立操作读图；IVUS指导下可以进行一般病变处理',
  '3分': '进阶：可独立操作，进行复杂图像解读（如撕裂、夹层、血肿、血栓、肌桥、真假腔识别等）',
  '2分': '中级：可独立操作，进行简单图像解读（如斑块性质判定、近远段参考确定、MLA测量、病变距离测量等）',
  '1分': '初级：不能独立操作读图'
};

export const bDescActiveList = [
  {
    title: 'A型病变',
    value: 'A型病变'
  },
  {
    title: '中度复杂病变',
    value: 'ACS病变、临界病变、开口病变、弥漫病变、支架内再狭窄、小血管病变、多支血管病变、扭曲成角病变'
  },
  {
    title: '共识病变',
    value: '左主干分叉病变、钙化病变、CTO病变'
  },
  {
    title: '其他复杂病变',
    value: '桥血管病变、其它病变'
  }
];

export const ivusReason = [{
  headline: '',
  title: '医生主观因素',
  initShow: true,
  selected: [],
  field: 'labels',
  arr: [
    { title: '更依赖临床经验判断', value: 'type1', active: false, disabled: false },
    { title: '未完全理解IVUS对于手术指导的价值或意义', value: 'type2', active: false, disabled: false },
    { title: '严守指南或专家建议，仅用IVUS于推荐场景', value: 'type3', active: false, disabled: false }
  ]
}, {
  headline: '',
  title: '医生技能因素',
  initShow: true,
  selected: [],
  field: 'labels',
  arr: [
    { title: '产品操作（包括前期准备）熟练度不足', value: 'type1', active: false, disabled: false },
    { title: '图像解读经验不足', value: 'type2', active: false, disabled: false }
  ]
}, {
  headline: '',
  title: '产品性能因素',
  initShow: true,
  selected: [],
  field: 'labels',
  arr: [
    { title: '相较于造影，会增加手术时间', value: 'type3', active: false, disabled: false },
    { title: 'OCT分辨率高，读图更直观，更易识别斑块等组织特征', value: 'type1', active: false, disabled: false },
    { title: 'B司IVUS操作步骤多/难于V司相控阵，且V司设备操作界面简单', value: 'type2', active: false, disabled: false },
    { title: 'B司IVUS超声导管易出现打水不够均匀/充分情况，产生气泡影响读图', value: 'type2', active: false, disabled: false },
    { title: '认为V司IVUS更适用于特定病变或场景', value: 'type2', active: false, disabled: false }
    // { title: 'B司IVUS操作步骤多/难于V司相控阵', value: 'type2', active: false, disabled: false },
    // { title: 'B司IVUS超声导管易出现打水不够均匀/充分情况，造成气泡', value: 'type2', active: false, disabled: false },
    // { title: '认为V司IVUS更适用于CTO开口病变', value: 'type2', active: false, disabled: false }
  ]
}, {
  headline: '',
  title: '政策因素',
  initShow: true,
  selected: [],
  field: 'labels',
  arr: [
    { title: '认为DRG/DIP政策限制IVUS使用', value: 'type3', active: false, disabled: false }
  ]
}, {
  headline: '',
  title: '其他非小众因素',
  initShow: true,
  selected: [],
  field: 'labels',
  arr: [
    { title: '其他', value: 'type3', active: false, disabled: false }
  ]
}];
