var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-content"},[_c('div',{staticClass:"banner"},[_c('div',{staticStyle:{"padding-top":"80px","font-size":"16px"}},[_vm._v("我的报告")]),_c('div',{staticStyle:{"margin-top":"20px","font-size":"38px"}},[_vm._v(" "+_vm._s(_vm.physicianMslCall?.call_last_30day_no ?? 0)+" ")]),_c('div',{staticStyle:{"margin-top":"20px","font-size":"12px"}},[_vm._v("近30天拜访数")])]),_c('div',{staticClass:"board"},_vm._l((_vm.boardList),function(item,index){return _c('div',{key:index,staticClass:"board-item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(item.value))])])}),0),(_vm.physicianMslCall)?_c('div',{staticClass:"main"},[_c('Tabs',{attrs:{"type":"card","color":"#aa341b"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('Tab',{attrs:{"title":"拜访统计"}}),_c('Tab',{attrs:{"title":"拜访医生"}})],1),(_vm.active === 0)?_c('div',{staticStyle:{"padding-top":"20px"}},[_c('div',{staticClass:"flex"},[_c('ReportChart',{attrs:{"title":"拜访区域","data":[
            {name: '本市区', value: _vm.physicianMslCall?.city_call_no ?? 0},
            {name: '本省', value: _vm.physicianMslCall?.province_call_no ?? 0},
            {
              name: '省外',
              value: _vm.physicianMslCall?.other_province_call_no ?? 0,
            },
          ]}}),_c('ReportChart',{attrs:{"title":"专家级别","data":[
            {name: '国家级', value: _vm.physicianMslCall?.national_call_no ?? 0},
            {name: '区域级', value: _vm.physicianMslCall?.regional_call_no ?? 0},
          ]}})],1),_c('div',{staticClass:"flex"},[_c('ReportChart',{attrs:{"title":"拜访与协访","data":[
            {name: '拜访', value: _vm.physicianMslCall?.call_no ?? 0},
            {name: '协访', value: _vm.physicianMslCall?.coaching_call_no ?? 0},
          ]}}),_c('ReportChart',{attrs:{"title":"拜访类型","data":[
            {name: '学术', value: _vm.physicianMslCall?.academic_call_no ?? 0},
            {
              name: '事务',
              value: _vm.physicianMslCall?.transactional_call_no ?? 0,
            },
            {name: '其他', value: _vm.physicianMslCall?.other_call_no ?? 0},
          ]}})],1)]):_vm._e(),(_vm.active === 1)?_c('div',[_vm._m(0),_c('ul',{staticClass:"activity-list"},_vm._l((_vm.physicianMslCallState),function({physician, call_no},index){return _c('li',{key:index,staticClass:"flex",staticStyle:{"justify-content":"space-between","align-items":"center"}},[_c('div',{staticClass:"flex"},[_c('img',{staticClass:"avatar",attrs:{"src":require("./../../assets/lundbeck/portrait.png")}}),_c('div',{staticClass:"custom-item-right"},[_c('div',{staticClass:"header clearfix"},[_c('div',{staticClass:"name",staticStyle:{"flex":"1"}},[_c('span',{staticClass:"name-big"},[_vm._v(_vm._s(physician?.physician_name))])])]),_c('p',{staticClass:"department"},[_vm._v(_vm._s(physician?.institution_name))])])]),_c('div',[_c('span',{staticStyle:{"font-size":"28px","margin-right":"5px"}},[_vm._v(_vm._s(call_no))]),_vm._v("次 ")])])}),0)]):_vm._e()],1):_vm._e(),_c('div',{staticStyle:{"height":"50px"}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"doctor-list-header"},[_c('div',[_vm._v("我的医生")]),_c('div',[_vm._v("本月拜访次数")])])
}]

export { render, staticRenderFns }