<template>
  <div class="summary-container">
    <template v-for="(ts, index) in typeData">
      <div v-if="index > 0" :key="index" class="summary-item">
        <span class="label">{{ ts.value }}</span>
        <span class="value publication">{{ ts.total }}</span>
      </div>
    </template>
    <!-- <div class="summary-item">
      <span class="label">共同试验</span>
      <span class="value clinical-trial">{{ summaryData[1] }}</span>
    </div>
    <div class="summary-item">
      <span class="label">同院校</span>
      <span class="value project">{{ summaryData[2] }}</span>
    </div> -->
    <!-- <div class="summary-item">
      <span class="label">同医院</span>
      <span class="value award">{{ summaryData[3] }}</span>
    </div> -->
  </div>
</template>

<script>
import { debounce, LightenDarkenColor } from '@/utils';
import { colorlist } from '@/utils/defaultConstant';
export default {
  props: {
    typeData: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      chart: null,
      summaryData: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted() {
    this.initChart();
    if (this.autoResize) {
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize();
        }
      }, 100);
      window.addEventListener('resize', this.__resizeHandler);
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHandler);
    }

    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    sidebarResizeHandler(e) {
      if (e.propertyName === 'width') {
        this.__resizeHandler();
      }
    },
    setOptions(data) {
      if (data && data.seriesData && data.seriesData.length > 0 && data.seriesData[0].data && data.seriesData[0].data.length >= 4) {
        this.summaryData = data.seriesData[0].data;
      }
    },
    initChart() {
      this.setOptions(this.chartData);
    }
  }
};
</script>
<style scoped lang="scss">
.summary-container {
  display: flex;
  align-items: center;
  justify-content: space-around;

  .summary-item {
    flex: 1;
    padding: 15px 0;
    .label {
      min-height: 24px;
      font-size: 10px;
      color: #666;
      display: block;
    }
    .value {
      padding: 10px;
      display: block;
      font-size: 28px;

      color: #ab351c;

      // &.publication {
      //     color: #ab351c;
      // }
      // &.clinical-trial {
      //     color: #aa341b;
      // }
      // &.project {
      //     color: #7EC0EE;
      // }
      // &.award {
      //     color: #FFD700;
      // }

    }
  }
}
</style>
