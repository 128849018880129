import request from '@/utils/request';

// 检查是否有待审批的审批项目
export function checkPendingApprovals(user_id) {
  return request({
    url: `/api/standard/odata/Approvals?$filter=approver_user_id eq '${user_id}' and status eq 2&$top=99`,
    method: 'get',
  }).then(res => res.value.length);
}

// 获取待审批列表
export function getPendingApprovals(user_id) {
  return request({
    url: `/api/standard/odata/Approvals?$filter=approver_user_id eq '${user_id}'&$orderby=submit_time desc`,
    method: 'get',
  });
}

// 获取已申请列表
export function getSubmittedApprovals(user_id) {
  return request({
    url: `/api/standard/odata/Approvals?$filter=submit_user_id eq '${user_id}'&$orderby=submit_time desc`,
    method: 'get',
  });
}

// 记录审批
export function createApproval(data) {
  return request({
    url: `/api/standard/odata/Approvals`,
    method: 'post',
    data: data,
  });
}

// 修改审批
export function updateApproval(data) {
  return request({
    url: `/api/standard/odata/Approvals('${data.approval_id}')`,
    method: 'patch',
    data: data,
  });
}

// 获取特定审批项目
export function getSpecificPendingApprovals(user_id, type, source_id) {
  return request({
    url: `/api/standard/odata/Approvals?$filter=approver_user_id eq '${user_id}' and approval_type eq '${type}' and source_id eq '${source_id}' and status eq 2`,
    method: 'get',
  });
}
