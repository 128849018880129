var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view-wrapper"},[[_c('div',{staticClass:"search-bar-list pd-btm0"},[(_vm.showHeaderBar)?_c('swipeBack',{attrs:{"title":_vm.title,"backtxt":"返回"},on:{"backFn":_vm.backFn}}):_vm._e(),_c('div',{staticClass:"search-filter"},[_c('div',{staticClass:"search-filter-input"},[_c('van-search',{attrs:{"shape":"round","background":"#ffffff","placeholder":"输入医院/科室/医生搜索","clearable":false},on:{"input":_vm.searchDoctor},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('div',{staticClass:"search-filter1"},[_c('van-button',{staticClass:"search-filter-button",attrs:{"round":"","type":"info","disabled":_vm.searchDisable,"loading":_vm.loading && _vm.resetDisable,"color":"#aa341b"},on:{"click":_vm.searchDoctor}},[_vm._v(" 搜索 ")]),_c('van-button',{staticClass:"search-filter-button",attrs:{"round":"","plain":"","type":"info","color":"#aa341b","disabled":_vm.resetDisable,"loading":_vm.loading && _vm.searchDisable},on:{"click":_vm.reset}},[_vm._v(" 重置 ")])],1)])],1),_c('div',{staticClass:"index-list-wrapper custom",class:{
        'no-list': _vm.list.length == 0,
        'index-list-wrapper-nosales': !_vm.isSales,
      },staticStyle:{"background-color":"#f5f7f8"}},[_c('van-list',{attrs:{"finished":_vm.finished,"immediate-check":false,"finished-text":_vm.$t('no_more_txt')},on:{"load":_vm.onLoad},model:{value:(_vm.listLoading),callback:function ($$v) {_vm.listLoading=$$v},expression:"listLoading"}},_vm._l((_vm.list),function(item,$index){return _c('CardItem',{key:item.product_id + '_' + item.physician_id + $index,ref:$index == 0 ? 'AIMREF' : '',refInFor:true,style:(_vm.selectedItems?.includes?.(item.physician_id)
              ? {border: '1px solid #aa341b'}
              : {}),attrs:{"id":'ITEM' + $index,"is-favorite":true,"gen-status":false,"show-draw":false,"item":item,"parting":item.result,"physician-segment":item.physician_segment},on:{"refreshFn":_vm.pageFn,"clickItem":function($event){return _vm.selectItem(item)}}})}),1),_vm._m(0),_c('div',{staticStyle:{"height":"50px"}})],1)]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"font-size":"14px","color":"#a7a6a6","margin-top":"60px"}},[_c('div',{staticStyle:{"margin-bottom":"30px"}},[_vm._v("请通过搜索寻找您要添加的医生")])])
}]

export { render, staticRenderFns }