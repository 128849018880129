<template>
  <div class="bind-success">
    <div class="banner">
      <img src="@/assets/lundbeck/error.png" alt="success" />
      <div class="success-title">页面错误</div>
    </div>

    <img class="logo" src="@/assets/lundbeck/lundbeck-logo-1280x1280.png" alt="Lundbeck Logo" />
    <div class="success-text">
      对不起，发生了错误<br />
      请稍后重试或联系系统管理员
    </div>
    <!-- <button class="close-btn" @click="handleClose">关闭本页面</button> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    nextFn() {
      console.info('跳转');
    },
    handleClose() {
      window.close();
    },
  }
};
</script>
<style lang="scss" scoped>
.bind-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  .banner {
    background-color: #aa341b;
    width: 100%;
    height: 280px;
    text-align: center;
  }
  img {
    width: 85px;
    height: 80px;
    margin-top: 80px;
  }

  .success-title {
    font-size: 18px;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 40px;
  }

  .logo {
    width: 60px;
    height: 60px;
    margin: 40px 0;
  }

  .success-text {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
    margin: 0px 0;
  }

  .close-btn {
    width: 40%;
    height: 44px;
    background: #ccc;
    color: #666;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    margin-top: 40px;

    &:active {
      opacity: 0.9;
    }
  }
}
</style>
