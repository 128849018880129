<template>
  <div class="app-container">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {};
  },
  mounted() {
    document.title = 'Lundbeck';
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.app-container {
  height: 100%;
  overflow: auto;
}
</style>
