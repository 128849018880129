<template>
  <div
    :key="$route.fullPath"
    ref="profileDeatils"
    v-scroll="handleScroll"
    class="profile-details"
    :class="{'dialog-box': dialogStatus}"
    style="height: 100%; overflow-y: auto"
  >
    <template v-if="!loading">
      <div class="oce-hcp-div"></div>
      <div style="height: 2.5rem" class="top" :class="{shrinked: shrinked}">
        <suggestion
          :hcp-data="hcpData"
          :show-product="showProduct"
          :class="{shrinked: shrinked}"
          @refreshFn="getDetailFn"
        />
      </div>
      <!-- show-slider -->
      <!-- v-model="selectedLabelDefault" -->
      <cube-tab-bar
        v-model="selectedLabelDefault"
        :data="tabs"
        class="top-tab-kol top-tab box"
        style="z-index: 5"
        :class="{shrinked: shrinked}"
        @click="clickHandler"
      >
        <cube-tab v-for="item in tabs" :key="item.label" :label="item.label">
          {{ item.text }}
        </cube-tab>
      </cube-tab-bar>

      <div
        style="margin-top: 3.4rem"
        class="bottom"
        :class="{shrinked: shrinked}"
      >
        <div
          v-if="selectedLabelDefault === 'baseMessage'"
          class="hcp body-content"
        >
          <baseMessage :hcp-data="hcpData" :show-product="showProduct" />
        </div>
        <div
          v-if="selectedLabelDefault === 'latestNews'"
          class="hcp body-content"
        >
          <latestNews :hcp-data="hcpData" :show-product="showProduct" :show-insight="true" />
        </div>
        <div v-if="selectedLabelDefault === 'persona'" class="hcp body-content">
          <persona :data="hcpData" :show-product="showProduct" />
        </div>
        <div v-if="selectedLabelDefault === 'activity'" class="activity">
          <activity ref="activity" />
        </div>
        <div
          v-if="selectedLabelDefault === 'perception'"
          class="activity body-content"
        >
          <perception :data="hcpData" />
        </div>
        <div style="height: 70px"></div>
      </div>
    </template>
    <Loading v-if="loading" :loading="loading" />
    <div
      v-if="
        showTopBtn &&
          (selectedLabelDefault == 'activity' ||
            selectedLabelDefault == 'persona')
      "
      class="fixed-up"
      @click="backTopFn"
    >
      <font-awesome-icon
        class="icon question-icon"
        :icon="['fa', 'arrow-up']"
      />
    </div>
  </div>
</template>

<script>
import baseMessage from './components/baseMessage.vue';
import latestNews from './components/latestNews';
import persona from './components/persona';
import perception from '../hcp/components/perception';
import activity from '../hcp/components/activity';
import suggestion from './components/suggestion';
import * as py from '@/api/Physicians';
import * as help from '@/api/help';
import {getToken} from '@/utils/auth';
import authVerification from '@/mixins/authVerification';
import refreshUserID from '@/mixins/refreshUserID';
import {setLocalStorage, getLocalStorage} from '@/utils/index';

export default {
  name: 'ProfileDetails',
  components: {
    persona,
    activity,
    perception,
    // onlineTab,
    suggestion,
    baseMessage,
    latestNews,
  },
  mixins: [authVerification, refreshUserID],
  data() {
    return {
      pageName: 'doctorDetail',
      whereLog: 'ProfileDetails',
      selectedLabelDefault: 'baseMessage',
      tabs: [
        {
          text: '基本信息',
          label: 'baseMessage',
        },
        {
          text: '最新动态',
          label: 'latestNews',
        },
        {
          text: '360画像',
          label: 'persona',
        },
        {
          text: '学术详情',
          label: 'activity',
        },
        // {
        //   text: '策略地图',
        //   label: 'perception',
        // },
      ],
      hcpData: {}, // 医生信息
      academicCount: 1,
      shrinked: false,
      showTopBtn: false,
      showProduct: [],
    };
  },
  computed: {
    dialogStatus() {
      return this.$store.getters.dialogStatus;
    },
  },
  watch: {
    '$route.query.physician_id'(val) {
      this.selectedLabelDefault = 'baseMessage';
      this.getDetailFn();
      this.getUserId();
      this.backTopFn();
    },
  },
  created() {
    this.selectedLabelDefault = 'baseMessage';
    this.getDetailFn();
    this.getUserId();
  },
  mounted() {},
  methods: {
    getShowProduct(territory_id) {
      py.getProductPhysicians(this.$route.query.physician_id).then((res) => {
        this.showProduct = [];
        if (res.value && res.value.length) {
          this.showProduct = res.value;
        }
      });
    },
    getTerritorieInfo(userId) {
      this.getShowProduct('');
    },
    clickHandler(label) {
      this.$refs.profileDeatils.scrollTo(0, 0, 0, '');
      // let tObj = this.tabs.find(ele => ele.label === label);
      // this.$store.dispatch('userSystemLog', {
      //   page_param: this.hcpData.physician_name,
      //   page_uri: this.$route.path,
      //   page: this.$t('persona') || tObj.text
      // });
      // this.$store.dispatch('settingActiveLabel', label);
      this.$router.push({
        path: '/kol/detail',
        query: {
          physician_id: this.$route.query.physician_id,
          selectedLabel: label,
        },
      });
    },
    handleScroll(evt, el) {
      this.showTopBtn = el.scrollTop > 500;
    },
    backTopFn() {
      this.$refs.profileDeatils.scrollTo(0, 0, 0, '');
    },
    getDetailFn() {
      // this.$route.query.selectedLabel ? this.$route.query.selectedLabel : 'persona';
      // let user_id = getLocalStorage("user_id");
      py.getPhysicians(this.$route.query.physician_id).then((res) => {
        if (res && res.value.length) {
          this.hcpData = res.value[0];
          setLocalStorage('institution_id', res.value[0].institution_id);
          this.$store.dispatch('setDoctorData', this.hcpData);

          // Pre-load is_favorite
          py.getUserFavoritePhysician(getLocalStorage("user_id"), this.$route.query.physician_id).then((res) => {
            if (res.value && res.value.length) {
              this.hcpData.is_favorite = res.value[0].is_favorite;
            } else {
              this.hcpData.is_favorite = 0;
            }
          });
        }
      });
      // py.getStarPhysiciansDetail(this.$route.query.physician_id, user_id).then(
      //   (res) => {
      //     res.value[0].physician.is_favorite = res.value[0].is_favorite
      //       ? res.value[0].is_favorite
      //       : 0;
      //     res.value[0].physician.user_id = res.value[0].user_id;
      //     res.value[0].physician.user_name = res.value[0].user_name;
      //     res.value[0].physician.user_favorite_physician_id =
      //       res.value[0].user_favorite_physician_id;
      //     res.value[0].physician.perception_type =
      //       res.value[0].physician_type || "";
      //     res.value[0].physician.influence_level =
      //       res.value[0].influence_level || "";
      //     this.hcpData = res.value[0].physician;
      //     this.$store.dispatch("setDoctorData", this.hcpData);
      //   }
      // );
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  border-top: none;
}
.profile-details ::v-deep .cube-pullup-wrapper {
  .before-trigger {
    font-size: 13px;
    padding: 15px 0;
    color: #dddddd;
  }
}
.fixed-up {
  font-size: 20px;
  padding: 8px 10px;
  border-radius: 50%;
  color: #aa341b;
  position: fixed;
  right: 20px;
  bottom: 30px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
}
.bottom > div {
  padding-bottom: 10px;
}

::v-deep .fixed-up {
  bottom: 60px;
}
</style>
