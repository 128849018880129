<template>
  <div class="box table-list">
    <div v-for="(product, index) in list" :key="index">
      <div class="table-title">
        <div>
          <p><i class="solid"></i>{{ product.product_name }}</p>
        </div>
      </div>
      <div class="table-status">
        <p>
          进院状态：<span>{{ product.lst_status }}</span>
        </p>
        <p>
          申请状态：<span>{{ product.apply_status }}</span>
        </p>
        <p>
          进院时间：<span>{{ product.lst_time }}</span>
        </p>
        <p>
          更新时间：<span>{{ product.update_time }}</span>
        </p>
      </div>
      <div class="table-value">
        <table>
          <tr class="header">
            <td>月份</td>
            <td>指标</td>
            <td>销量</td>
            <td>达成率</td>
          </tr>
          <tr v-for="(data, i) in product.product_data" :key="i">
            <td>{{ data.period }}</td>
            <td>{{ data.target_amt }}</td>
            <td>{{ data.sales_amt }}</td>
            <td>
              {{
                data.achievement_rate
                  ? Math.round(data.achievement_rate * 100) + "%"
                  : ""
              }}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <Nodata v-if="!list.length" :loading="loading" :empty-txt="$t('no_data')" />
  </div>
</template>
<script>
import * as py from "@/api/Physicians";
import Nodata from "@/components/Nodata";
import _ from "underscore";
import * as moment from "moment";
import { returnYMD } from "@/filters/index";
import { getLocalStorage } from "@/utils/index";

export default {
  components: {
    Nodata,
  },
  props: {
    institutionId: {
      type: String,
      default() {
        return "";
      },
    },
    showProduct: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      list: [],
      loading: false,
    };
  },
  watch: {
    institutionId(nVal, oVal) {
      // console.info(nVal, "=====");
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.list = [];
      this.loading = true;
      let is_sales = getLocalStorage('is_sales');
      let territory_id = is_sales === '1' ? getLocalStorage("territory_id") : 'all';
      // localStorage.getItem("user_id");
      Promise.all([
        py.getLstListings(this.institutionId),
        py.getTargetSaleses(this.institutionId, territory_id),
      ])
        .then((res) => {
          this.list = [];
          this.showProduct.map((item) => {
            let lst = res[0].value.find(
              (e) => e.product_id === item.product_id
            );
            let targetSales = res[1].value.filter(
              (target) => target.product_id === item.product_id
            );
            let productInfo = {
              product_id: item.product_id,
              product_name: item.product_name || item.product_id,
              lst_status: lst.lst_status,
              lst_time: lst.lst_time,
              apply_status: lst.apply_status,
              update_time: lst.update_time
                ? moment(lst.update_time).format("YYYY-MM-DD")
                : "",
              product_data:
                targetSales.length > 3 ? targetSales.slice(0, 3) : targetSales,
            };
            this.list.push(productInfo);
          });
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.dosing-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 12px;
  &.active {
    span {
      color: #aa341b;
    }
  }
  .dosing-left {
    width: 120px;
    text-align: center;
    padding-right: 10px;
    .num {
      font-size: 16px;
      color: #575757;
    }
    .year {
      margin-top: 10px;
      color: #575757;
    }
    .speed {
      // padding: 5px 0;
      height: 20px;
      line-height: 20px;
      color: #ffffff;
      border-radius: 30px;
      margin-top: 10px;
      &.new {
        // background-color: #FFD700;
        background-color: #ccc;
      }
      &.over {
        background-color: #aa341b;
      }
    }
  }
  .dosing-right {
    flex: 1;
    padding: 0 10px;
    text-align: left;
    position: relative;
    &:before {
      content: "";
      width: 1px;
      height: 100%;
      background-color: #ccc;
      position: absolute;
      top: 0;
      left: 0;
    }
    .hospital {
      color: #000000;
      margin-top: 10px;
    }
    .high {
      color: #595959;
      margin-right: 5px;
    }
    // .name, .h-type {
    //   // margin-top: 10px;
    // }
  }
}
.table-list {
  .table-title {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 5px;
    color: #aa341b;
    .solid {
      border: 1px solid;
      margin-right: 2px;
      background: #aa341b;
    }
  }
  .table-status {
    text-align: left;
    padding: 0 10px;
    line-height: 1.5;
  }
  .table-value {
    margin-top: 10px;
    padding: 0 10px;
  }
  padding: 0 10px;
  .header {
    td {
      // font-weight: bold;
      font-size: 0.28rem;
      color: #000000;
      background-color: #f6f6f6;
    }
  }
}
</style>
