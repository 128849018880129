<template>
  <Tabbar
    v-model="active"
    :safe-area-inset-bottom="true"
    @change="changeTab"
    active-color="#aa341b"
    class="tab-bar"
  >
    <TabbarItem icon="friends">医生</TabbarItem>
    <TabbarItem icon="chat">拜访</TabbarItem>
    <TabbarItem icon="browsing-history">洞见</TabbarItem>
    <TabbarItem icon="completed">工作</TabbarItem>
    <TabbarItem icon="graphic">报告</TabbarItem>
  </Tabbar>
</template>

<script>
import {Tabbar, TabbarItem} from 'vant';
import {getLocalStorage} from '@/utils/index';
export default {
  name: 'TabBar',
  components: {
    Tabbar,
    TabbarItem,
  },
  data() {
    return {
      active: 0,
    };
  },
  created() {
    // 初始化时根据路由设置 active 值
    this.updateActiveTab(this.$route.name);

    // 监听路由变化
    this.$router.afterEach((to) => {
      this.updateActiveTab(to.name);
    });
  },
  methods: {
    updateActiveTab(routeName) {
      switch (routeName) {
        case 'doctorListHome':
          this.active = 0;
          break;
        case 'visitListHome':
          this.active = 1;
          break;
        case 'insightListHome':
          this.active = 2;
          break;
        case 'workHome':
          this.active = 3;
          break;
        default:
          break;
      }
    },
    changeTab(e) {
      switch (e) {
        case 0:
          this.$router.push({
            path: '/kol/doctor-list',
            query: {
              user_id: getLocalStorage('user_id_key'),
            },
          });
          break;
        case 1:
          this.$router.push({
            path: '/kol/visit-list',
          });
          break;
        case 2:
          this.$router.push({
            path: '/kol/insight-list',
          });
          break;
        case 3:
          this.$router.push({
            path: '/kol/work',
          });
          break;
        case 4:
          this.$router.push({
            path: '/kol/report',
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-bar {
  .van-tabbar__item--active {
    color: #aa341b;
  }
}
</style>
