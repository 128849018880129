<template>
  <div class="login-container">
    <div v-if="!isDirectLogin" class="login-form">
      <div class="title-container">
        <h3 class="title">{{ $t('login_txt') }}</h3>
      </div>
      <!-- <div class="login-item">
        <cube-input
          v-model="loginObj.userName"
          type="text"
          :placeholder="$t('input_name')"
          :clearable="clearable"
          :autocomplete="true"
          @blur="blurUserName"
        />
        <p class="cube-validator-msg-def" :class="{'hidden': !loginObj.userNameTips}">{{ loginObj.userNameTips }}</p>
      </div> -->
      <div class="login-item">
        <form>
          <cube-input
            v-model="loginObj.userId"
            :type="type"
            :placeholder="$t('input_uid')"
            :maxlength="20"
            :clearable="clearable"
            :autocomplete="true"
            :eye="eyeObj"
            @blur="blurPwd"
          />
        </form>
        <p
          class="cube-validator-msg-def"
          :class="{hidden: !loginObj.userIdTips}"
        >
          {{ loginObj.userIdTips }}
        </p>
      </div>
      <div class="prompt">当前版本为内部测试版本<br />请使用您的员工ID登录</div>
      <!-- <div class="login-btn" style=""><cube-loading :size="14" style="width: 14px;" /></div> -->
      <div
        class="login-btn"
        :class="{disable: isloging}"
        @click="loginFn('userid')"
      >
        {{ $t('login_btn') }}
      </div>
      <a
        v-if="false"
        href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcb656c3e60659e8b&redirect_uri=https://hcp360-demo-bsc.lekinsights.cn/authorize&response_type=code&scope=snsapi_base&state=#wechat_redirect"
        class="login-btn"
        >企业微信登录</a
      >
      <!-- <div class="language"><span class="title">Language:</span><span class="button" :class="{'active': $i18n.locale == 'ZH'}" @click="settingLan('ZH')">CN</span>/<span class="button" :class="{'active': $i18n.locale == 'EN'}" @click="settingLan('EN')">En</span></div> -->
      <div class="prompt">
        <div @click="() => (loginObj.userId = '504735')">hcp: 504735</div>
        <div @click="() => (loginObj.userId = '503920')">dm: 503920</div>
        <div @click="() => (loginObj.userId = '513769')">kol: 513769</div>
      </div>
    </div>
  </div>
</template>
<script>
import loginMixins from '@/mixins/loginMixins';

export default {
  mixins: [loginMixins],
};
</script>
<style lang="scss" scoped>
@import '@/styles/var.scss';
.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bac-color;
  overflow: hidden;
  .login-form {
    position: relative;
    // width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
    .title-container {
      position: relative;
      .title {
        font-size: 26px;
        color: #333;
        margin: 0 auto 40px auto;
        text-align: center;
        font-weight: 700;
      }
    }
    .login-item {
      font-size: 14px;
      margin-bottom: 8px;
      .cube-validator-msg-def {
        min-height: 12px;
        font-size: 12px;
        color: #e64340;
        text-align: left;
        margin-top: 5px;
        &.hidden {
          visibility: hidden;
        }
      }
    }
    .login-btn {
      width: 100%;
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      background: $btn-color;
      border: 1px solid $btn-color;
      color: #ffffff;
      -webkit-appearance: none;
      text-align: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      outline: 0;
      margin: 0;
      -webkit-transition: 0.1s;
      transition: 0.1s;
      font-weight: 500;
      padding: 12px 20px;
      font-size: 14px;
      border-radius: 4px;
      &.disable {
        background: #dddddd;
        border: 1px solid #dddddd;
      }
      & + .login-btn {
        margin-top: 10px;
      }
    }
  }
}
.prompt {
  font-size: 12px;
  line-height: 18px;
  color: #666;
  text-align: center;
  margin: 10px;
}
.language {
  margin-top: 10px;
  text-align: left;
  font-size: 12px;
  .title {
    margin-right: 10px;
  }
  .button {
    &.active {
      color: #aa341b;
    }
  }
}
</style>
