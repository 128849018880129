<template>
  <div v-if="externalDataLastUpdate">
    <div class="update-tag">
      <span class="update-label">外部公开数据更新于: {{ externalDataLastUpdate }}</span>
      <Icon
        v-if="false"
        class="update-button"
        name="question"
        @click="onClick()"
      /></div>
    <div>
      <van-dialog
        v-model="externalDataDialogShow"
        title=""
        theme="round-button"
        :close-on-click-overlay="true"
        :show-confirm-button="false"
        confirm-button-color="#aa341b"
      >
        <div style="padding: 20px 20px 10px 20px;">
          <p class="popup-p">该专家的外部公开数据最近收集时间为：{{ externalDataLastUpdate }}</p>
          <p class="popup-p">如果您认为该专家的数据不准确或未及时更新，可以申请更新该专家的外部数据，我们将定期进行处理。</p>
          <div class="buttons">
            <Button
              class="close-button"
              round
              plain
              type="primary"
              size="mini"
              @click="externalDataDialogShow = false"
            >关闭</Button>
            <span v-if="isApplied" class="applied">已申请数据更新</span>
            <Button
              v-if="!isApplied"
              class="apply-button"
              round
              plain
              type="primary"
              size="mini"
              @click="applyDataUpdate"
            >申请数据更新</Button>
          </div>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {Button, Icon} from 'vant';
import * as py from '@/api/Physicians';
import { getLocalStorage } from '@/utils/index';

export default {
  components: {
    Button,
    Icon,
  },
  props: {
    externalDataLastUpdate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      externalDataDialogShow: false,
      isApplied: false,
    };
  },
  mounted() {
    py.getUserFeedback(getLocalStorage("user_id"), this.$route.query.physician_id).then((res) => {
      if (res.value && res.value.length) {
        this.isApplied = true;
      }
    });
  },
  methods: {
    onClick() {
      this.externalDataDialogShow = true;
    },
    applyDataUpdate() {
      let params = {
        user_id: getLocalStorage("user_id"),
        related_physician_id: this.$route.query.physician_id,
        feedback_time: new Date(),
        feedback_type: 'ex_data_request',
        feedback_content: '',
        is_resolved: 0,
      };
      py.createUserFeedback(params).then((result) => {
        if (result.is_success && result.entity) {
          this.isApplied = true;
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.update-tag {
  padding: 5px 0 5px 0;
  text-align: center;
  font-size: 10px;
  color: #999;
  .update-label {
    vertical-align: middle;
  }
  .update-button {
    margin-left: 5px;
    font-size: 16px;
    white-space: nowrap;
    color: #828282;
    border: 0;
    vertical-align: middle;
  }
}
.filed-name {
  width: 160px;
  padding: 5px;
  text-align: center;
}
.filed-value {
  padding: 5px;
}
.popup-p {
  font-size: 12px;
  padding: 5px 0;
  color: #666;
}
.buttons {
  margin-top: 20px;
  .close-button {
    width: 60px;
    vertical-align: middle;
  }
  .applied {
    margin-left: 20px;
    vertical-align: middle;
    font-size: 11px;
    color: #0cc160;
  }
  .apply-button {
    margin-left: 20px;
    width: 88px;
    vertical-align: middle;
  }
}
</style>
