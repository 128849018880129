<template>
  <div>
    <div v-if="loading"><img class="spinner" src="/image/spinner.gif" /></div>
    <div v-if="!loading && error" class="bind-success">
      <div class="banner">
        <img src="@/assets/lundbeck/error.png" alt="success" />
        <div class="success-title">出错啦</div>
      </div>

      <img class="logo" src="@/assets/lundbeck/lundbeck-logo-1280x1280.png" alt="Lundbeck Logo" />
      <div class="success-text">
        {{ msg }}<br />
        请稍后重试或联系系统管理员。
      </div>
      <!-- <button class="close-btn" @click="handleClose">关闭本页面</button> -->
    </div>
    <div v-if="showConsent && !error" class="terms-service">
      <div class="title">服务条款</div>

      <div class="content">
        <div class="section">
          <div class="section-title">知情而意书</div>
          <div class="section-text">
            本声明旨在告知：灵北公司需收集个人识别信息（如：姓名、电话号码或身份信息、地址、邮箱等），并将收集的信息在法律允许的情况下进行使用和共享，具体请参照隐私政策。点击同意按钮视为您已知情同意。在您提供完整相关信息后，系统将为您提供相应的服务。为保证您顺利完成操作，请仔细闭读以上内容。未成年人请在监护人陪同下操作或签署协议。如有任何疑问，请及 时联系。
          </div>
        </div>

        <div class="section">
          <div class="section-title">个人识别信息的用意</div>
          <div class="section-text">
            基于信息将提高服务效率和学术研究方法的优化，并用于服务产品的体验优化和推广用于参加门卷调查（含抽奖）。个人识别信息的收集与使用，将在合法、透明的框架下进行。您有权拒绝提供信息的权利。如拒绝，您或无法继续享受本系统的相关服务。
            基于信息将提高服务效率和学术研究方法的优化，并用于服务产品的体验优化和推广用于参加门卷调查（含抽奖）。个人识别信息的收集与使用，将在合法、透明的框架下进行。您有权拒绝提供信息的权利。如拒绝，您或无法继续享受本系统的相关服务。
          </div>
        </div>
      </div>

      <div class="button-group">
        <div class="myself">我是<span class="highlight">{{institutionName}}</span><span class="highlight">{{physicianName}}</span>，手机号码为</div>
        <input
          type="tel"
          class="form-input"
          v-model="phone"
          placeholder="请填写手机号码"
          style="background: #EDE9DE"
        />
        <van-button class="agree-btn" :loading="submitting" @click="handleAgree">
          我已阅读，并同意服务条款
        </van-button>
        <div class="separator"></div>
        <button class="reject-btn" @click="handleReject">拒绝</button>
      </div>
    </div>
  </div>
</template>
<script>
import { getInfoByCode } from '@/api/wechat';
import { getPhysicianWechatDetailByOpenId, getLogContentSend, getPhysicianWechatDetail, weixinBind, signConsent } from '@/api/content';
import { setLocalStorage } from '@/utils/index';
import { Button } from 'vant';
import { weixinOASDK } from '@/utils/weixinOA';

const isValidPhoneNumber = (phoneNumber) => /^1[3-9]\d{9}$/.test(phoneNumber);

export default {
  components: {
    vanButton: Button,
  },
  data() {
    return {
      open_id: '',
      send_id: '',
      userList: [],
      loading: true,
      code: '',
      msg: '对不起，验证失败',
      error: false,
      content: null, // log_content_send
      physician: null, // physician_weixin
      openPhysician: null,
      showConsent: false,
      institutionName: '',
      physicianName: '',
      phone: '',
      submitting: false,
    };
  },
  mounted() {
    this.getCode();
  },
  methods: {
    getCode() {
      let code = this.$route.query.code;
      this.send_id = this.$route.query.send_id;
      this.loading = true;
      this.code = code;
      getInfoByCode(code).then(res => {
        console.log(res);
        // Fake open_id
        if (code === 'test0123') {
          res = {
            data: {
              openid: 'fake_openid_0001',
            },
            isSuccess: true,
            message: "",
          };
        }
        if (res.data && res.data.openid) {
          this.open_id = res.data.openid;
          setLocalStorage('open_id', this.open_id);

          getPhysicianWechatDetailByOpenId(this.open_id).then(res => {
            if (res && res.data && res.data.physician_id) {
              // 存在医生
              this.openPhysician = res.data;
            } else {
              // 未绑定过的用户
            }

            // Check log_content_send
            getLogContentSend(this.send_id).then(res => {
              this.content = res.data;
              this.physicianName = this.content.receiver_physician_name;
              this.institutionName = this.content.receiver_institution_name;
              if (this.content && this.content.receiver_physician_id) {
                getPhysicianWechatDetail(this.content.receiver_physician_id).then(res => {
                  if (res && res.data && res.data.physician_id) {
                    // 医生已绑定，判断open_id是否一致
                    this.physician = res.data;
                    this.loading = false;

                    console.log(this.physician.open_id, this.open_id, this.physician);
                    // 医生是否有绑定过（openid为空）
                    if (this.physician.open_id) {
                      if (this.physician.open_id === this.open_id) {
                        // open_id一致的话，判断是否签署了知情同意
                        if (this.physician.is_consent) {
                          // Success
                          this.$router.push('/public/open?send_id=' + this.send_id);
                        } else {
                          this.showConsent = true;
                        }
                      } else {
                        this.error = true;
                        this.msg = '用户不一致';
                        // // 用户不一致，但也继续打开
                        // this.$router.push('/public/open?send_id=' + this.send_id);
                      }
                    } else {
                      // 当前登陆的医生是否已经绑定过？
                      if (this.openPhysician) {
                        this.error = true;
                        this.msg = '用户不一致';
                        // // 用户不一致，但也继续打开
                        // this.$router.push('/public/open?send_id=' + this.send_id);
                      } else {
                        // 医生未绑定，openid为空
                        if (!this.physician.is_consent) {
                          // 医生未绑定，继续绑定流程
                          this.loading = false;
                          this.showConsent = true;
                        } else {
                          // 已签署过知情同意，直接绑定
                          this.onlyBind();
                        }
                      }
                    }
                  } else {
                    // 当前登陆的医生是否已经绑定过？
                    if (this.openPhysician) {
                      // 医生未绑定，继续绑定流程
                      this.loading = false;
                      // // 用户不一致，但也继续打开
                      // this.$router.push('/public/open?send_id=' + this.send_id);
                    } else {
                      // 医生未绑定，继续绑定流程
                      this.loading = false;
                      this.showConsent = true;
                    }
                  }
                });
              } else {
                this.loading = false;
                this.error = true;
                this.msg = '内容格式错误';
              }
            }).catch(e => {
              this.loading = false;
              this.error = true;
            });
          });
        } else {
          this.loading = false;
          this.error = true;
          if (res.message) {
            this.msg = '错误：' + res.message;
          }
        }
      }).catch(e => {
        this.loading = false;
        this.error = true;
      });
    },
    handleAgree() {
      // 处理同意逻辑
      if (!this.phone) {
        this.$toast('请输入手机号码');
        return;
      }
      if (!isValidPhoneNumber(this.phone)) {
        this.$toast('手机号码无效');
        return;
      }

      this.submitting = true;
      let bindParams = {
        physician_id: this.content.receiver_physician_id,
        open_id: this.open_id,
        record_user_id: this.content.sender_user_id,
        record_territory_id: this.content.sender_territory_id,
      };
      let consentParams = {
        physician_id: this.content.receiver_physician_id,
        record_user_id: this.content.sender_user_id,
        record_territory_id: this.content.sender_territory_id,
        mobile_phone: this.phone,
      };

      // 检查是否已绑定
      if (!this.physician || !this.physician.open_id) {
        // 绑定医生
        weixinBind(bindParams).then(res => {
          // 继续签知情同意
          signConsent(consentParams).then(res => {
            // Success
            this.submitting = false;
            this.showConsent = false;
            this.$router.push('/public/open?send_id=' + this.send_id);
          }).catch(() => {
            this.submitting = false;
            this.error = true;
            this.msg = '签署知情同意错误';
          });
        }).catch(() => {
          this.submitting = false;
          this.error = true;
          this.msg = '绑定错误';
        });
      } else {
        // 直接签知情同意
        signConsent(consentParams).then(res => {
          console.log(res);
          // Success
          this.submitting = false;
          this.showConsent = false;
          this.$router.push('/public/open?send_id=' + this.send_id);
        }).catch(() => {
          this.submitting = false;
          this.error = true;
          this.msg = '直接签署知情同意错误';
        });
      }
      // this.$router.push('/public/hcp-bind-success');
    },
    onlyBind() {
      let bindParams = {
        physician_id: this.content.receiver_physician_id,
        open_id: this.open_id,
        record_user_id: this.content.sender_user_id,
        record_territory_id: this.content.sender_territory_id,
      };
      weixinBind(bindParams).then(res => {
        console.log(res);
        // Success
        this.showConsent = false;
        this.$router.push('/public/open?send_id=' + this.send_id);
      }).catch(() => {
        this.error = true;
        this.msg = '绑定错误';
      });
    },
    handleReject() {
      // 处理拒绝逻辑
      weixinOASDK.closeWindow();
    },
  }
};
</script>
<style lang="scss" scoped>
.spinner {
  width: 24px;
  height: 24px;
  margin: 120px 0;
}
.bind-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  .banner {
    background-color: #aa341b;
    width: 100%;
    height: 280px;
    text-align: center;
  }
  img {
    width: 85px;
    height: 80px;
    margin-top: 80px;
  }

  .success-title {
    font-size: 18px;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 40px;
  }

  .logo {
    width: 60px;
    height: 60px;
    margin: 40px 0;
  }

  .success-text {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
    margin: 0px 0;
  }

  .close-btn {
    width: 40%;
    height: 44px;
    background: #ccc;
    color: #666;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    margin-top: 40px;

    &:active {
      opacity: 0.9;
    }
  }
}
.terms-service {
  padding: 20px 30px 40px 30px;
  background-color: #ffffff;
  min-height: 100vh;

  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin: 20px 0 40px 0;
    color: #aa341b;
  }

  .myself {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    margin: 20px 0 10px 0;
    width: 100%;
    color: #333;
    .highlight {
      font-weight: bold;
      // color: #aa341b;
      // text-decoration: underline;
    }
  }

  .content {
    .section {
      margin-bottom: 20px;

      .section-title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        text-align: left;
        margin-bottom: 30px;
      }

      .section-text {
        font-size: 14px;
        line-height: 1.6;
        color: #333;
        margin-bottom: 15px;
        text-align: justify;
      }
    }
  }

  .button-group {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    .form-input {
      width: 90%;
      height: 44px;
      border: 1px solid #eee;
      border-radius: 30px;
      padding: 0 15px;
      font-size: 16px;
      color: #333;
      background: #fff;
      text-align: center;

      &:disabled {
        background: #fff;
        color: #666;
      }

      &::placeholder {
        color: #999;
      }
    }

    button {
      width: 100%;
      height: 44px;
      border-radius: 22px;
      border: none;
      font-size: 16px;
      cursor: pointer;

      &.agree-btn {
        margin-top: 5px;
        background: #aa341b;
        color: #ffffff;
      }
      &.reject-btn {
        // margin-top: 40px;
        background: #22201f;
        color: #ffffff;
      }
    }
    .separator {
      margin: 40px 0;
      width: 80%;
      height: 1px;
      border-top: 2px dashed #eeeee0;
    }
  }
}
</style>
