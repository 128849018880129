<template>
  <div :class="className" :style="{height:height,width:width}"></div>
</template>

<script>
import * as echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
import { debounce } from '@/utils';
export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chart: null,
      sidebarElm: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted() {
    this.initChart();
    if (this.autoResize) {
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize();
        }
      }, 100);
      window.addEventListener('resize', this.__resizeHandler);
    }

    // 监听侧边栏的变化
    this.sidebarElm = document.getElementsByClassName('sidebar-container')[0];
    this.sidebarElm && this.sidebarElm.addEventListener('transitionend', this.sidebarResizeHandler);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHandler);
    }

    this.sidebarElm && this.sidebarElm.removeEventListener('transitionend', this.sidebarResizeHandler);

    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    sidebarResizeHandler(e) {
      if (e.propertyName === 'width') {
        this.__resizeHandler();
      }
    },
    setOptions(data) {
      this.chart.setOption({
        title: {
          text: ''
        },
        legend: {
          top: 0,
          // right: 10,
          // orient: 'vertical',
          // align: 'right',
          icon: 'circle',
          data: data.legendData
        },
        // grid: {
        //   top: 380,
        //   left: '50%',
        //   right: '4%',
        //   bottom: '3%'
        // },
        radar: [
          {
            indicator: data.indicator,
            triggerEvent: true,
            center: ['48%', '50%'],
            radius: 80,
            startAngle: 90,
            splitNumber: 4,
            shape: 'circle',
            name: {
              formatter: (key) => {
                let value = 0;
                // let level = '';
                let rank = null;
                value = data.customData[key].score;
                // level = data.customData[key].level;
                rank = data.customData[key].rank;
                // 自定义显示内容
                // return '{label|' + key + '}';// + '{level|' + level + '}';
                if (key === '在线活跃度' || key === '医生认同度') {
                  return '{value| } {label|' + key + '}';
                } else {
                  return '{label|' + key + '} {value| }';
                }
              },
              rich: {
                label: {
                  color: '#333',
                  fontSize: 12,
                  lineHeight: 20
                },
                value: {
                  width: 12,
                  fontSize: 12,
                  color: '#ab351c',
                  backgroundSize: 12,
                  backgroundColor: {
                    image: '/wenhao.svg'
                  }
                },
                level: {
                  fontSize: 10,
                  color: '#ab351c' // '#606060'
                },
                rank: {
                  lineHeight: 20,
                  color: '#999999'
                }
              },
              color: '#000'
              // backgroundColor: '#dddddd'
            },
            splitArea: {
              areaStyle: {
                color: ['rgba(0, 98, 179, 0.4)', 'rgba(0, 98, 179, 0.2)', 'rgba(0, 98, 179, 0.1)', 'rgba(0, 98, 179, .03)']
              }
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0)'
              }
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0)'
              }
            }
          }
        ],
        series: [
          {
            name: '评估',
            type: 'radar',
            data: [
              {
                value: data.value1,
                name: data.legendData[0],
                symbol: 'circle',
                symbolSize: 2,
                itemStyle: {
                  color: 'rgba(60, 170, 69, 1)'
                },
                lineStyle: {
                  color: 'rgba(60, 170, 69, 1)'
                },
                areaStyle: {
                  opacity: 0.9,
                  color: new echarts.graphic['RadialGradient'](0.5, 0.5, 1, [
                    {
                      color: 'rgba(60, 170, 69, 0.03)',
                      offset: 0
                    },
                    {
                      color: 'rgba(60, 170, 69, 0.7)',
                      offset: 1
                    }
                  ])
                }
              },
              {
                value: data.value2,
                name: data.legendData[1],
                symbol: 'circle',
                symbolSize: 2
              },
              {
                value: data.value3,
                name: data.legendData[2],
                symbol: 'circle',
                symbolSize: 2
              },
              {
                value: data.value4,
                name: data.legendData[3],
                symbol: 'circle',
                symbolSize: 2
              }
            ]
          }
        ]
      });
      let self = this;
      this.chart.on('click', function(params) {
        if (params.componentType === 'radar') {
          // let name = params.name.split('{label|')[1].split('}')[0] || params.name;
          self.$emit('selectRadarDetail', params.name);
        }
      });
    },
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOptions(this.chartData);
    }
  }
};
</script>
