<template>
  <div class="content-share">
    <van-search
      v-model="searchValue"
      shape="round"
      placeholder="请输入搜索关键词"
    />
    <div class="c-serach-box">
      <div class="c-serach-item">
        <div class="c-s-label">标签筛选</div>
        <div class="flex flex-wrap" style="margin-bottom: -10px">
          <div
            v-for="(se, index) in nbaKeyMessagesLabels"
            :key="index"
            style="margin-bottom: 10px; font-size: 11px"
            class="c-s-item"
            :class="{active: se.active}"
            @click="se.active = !se.active"
          >
            <div class="c-s-flex">{{ se.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="nbaKeyMessages.length">
      <div class="action-plan">
        <div v-for="(item, index) in nbaKeyMessages" :key="index">
          <p class="action-text flex">
            <span>
              <!-- <span class="action-flag"></span> -->
              <span class="key-message-labels">
                <span
                  v-for="label in newLabelShow(
                    item.cnf_key_message_content?.labels?.split(','),
                  )"
                  :key="label"
                >
                  {{ label }}
                </span>
              </span>
              {{ item.cnf_key_message_content?.key_messages }}
            </span>
            <span>
              <img
                src="@/assets/lundbeck/NBA/preview.png"
                alt=""
                width="26"
                style="margin-left: 4px"
                @click="
                  previewImage(
                    item.cnf_key_message_content?.slides,
                    item.cnf_key_message_content?.key_messages,
                  )
                "
              />
            </span>
            <span>
              <img
                src="@/assets/lundbeck/NBA/forward.png"
                alt=""
                width="26"
                style="margin-left: 4px"
                @click="
                  choosePhysician(
                    item.cnf_key_message_content?.slides,
                    item.cnf_key_message_content?.key_messages,
                  )
                "
              />
            </span>
          </p>
        </div>
      </div>
    </div>
    <swipeDialog
      v-if="pdfLink"
      backtxt="返回"
      :title="'预览'"
      @closeFn="closePdfFn"
    >
      <pdf v-show="pdfLink" :src="pdfLink" />
    </swipeDialog>
    <DoctorListSelectAllAndNew
      v-if="showDoctorSelect"
      :multipleMode="false"
      :defaultSelectedList="doctors"
      @close="showDoctorSelect = false"
      @select="handleSelectDoctor"
    />
  </div>
</template>

<script>
import * as py from '@/api/Physicians';
import {Search, ImagePreview} from 'vant';
import swipeDialog from '@/views/layout/swipeDialog';
import {getLocalStorage} from '@/utils';
import DoctorListSelectAllAndNew from '@/components/DoctorListSelectAllAndNew.vue';
import pdf from 'vue-pdf';
import {wecomSDK} from '@/utils/wecom';
export default {
  name: 'ContentShare',
  components: {
    [Search.name]: Search,
    swipeDialog,
    pdf,
    DoctorListSelectAllAndNew,
  },
  data() {
    return {
      searchValue: '',
      nbaKeyMessagesLabels: [],
      nbaKeyMessages: [],
      nbaKeyMessagesAllData: [],
      pdfLink: null,
      showDoctorSelect: false,
      doctors: [],
      forWord: {
        slides: '',
        title: '',
        physicianId: '',
      },
    };
  },
  mounted() {
    this.fetchNba();
  },
  watch: {
    nbaKeyMessagesLabels: {
      handler(newVal) {
        console.log(newVal);
        const lastLabelArr = newVal
          .filter((item) => item.active)
          .map((item) => item.title);
        const lastKeyMessages = this.nbaKeyMessagesAllData.filter((message) =>
          lastLabelArr.some((label) =>
            message.cnf_key_message_content.labels.split(',').includes(label),
          ),
        );
        this.nbaKeyMessages = lastKeyMessages;
      },
      deep: true,
    },
  },
  methods: {
    fetchNba() {
      this.nbaKeyMessagesLabels = [];
      py.getPhysicianLadderNbas({
        product_id: 'Brintellix_000001',
        segment_index: 2,
        sub_segment: 'ALL',
        ladder_index: '2',
      }).then((res) => {
        if (res.value && res.value.length > 0) {
          let item = res.value[0];
          this.nbaKeyMessages =
            item.cnf_physician_ladder_nba__cnf_nba_key_message_contents;
          this.nbaKeyMessagesAllData =
            item.cnf_physician_ladder_nba__cnf_nba_key_message_contents;
          this.nbaKeyMessagesLabels = item.labels
            .split(',')
            .map((label) => ({title: label, active: true}));
        }
        this.loading = false;
      });
    },
    newLabelShow(arr) {
      return arr.filter((label) =>
        this.nbaKeyMessagesLabels.some((item) => item.title === label),
      );
    },
    lookPdfDetail(url) {
      // this.$store.dispatch('setDialogStatus', true);
      console.log('url---->', url);
      this.pdfLink = url;
    },
    closePdfFn() {
      this.$store.dispatch('setDialogStatus', false);
      this.pdfLink = null;
    },
    previewImage(slides) {
      console.log(slides);
      if (slides.indexOf('.pdf') > 0) {
        this.lookPdfDetail('/resource/nba/brintellix/key-message/' + slides);
      } else {
        let images = [];
        slides.split(',').forEach((image) => {
          images.push('/resource/nba/brintellix/key-message/' + image);
        });
        ImagePreview({
          images: images,
          loop: false,
          showIndicators: true,
        });
      }
    },
    choosePhysician(slides, title) {
      console.log(slides, title);
      this.forWord.slides = slides;
      this.forWord.title = title;
      this.showDoctorSelect = true;
    },
    handleSelectDoctor(doctors) {
      console.log(doctors);
      this.forWord.physicianId = doctors[0].physician_id;
      this.doctors = doctors;
      this.showDoctorSelect = false;
    },
    forwardWechat(slides, title) {
      console.log(slides, title);
      wecomSDK.shareWechatMessage({
        content_id: '001',
        sender_user_id: getLocalStorage('user_id'),
        sender_user_name: getLocalStorage('user_name'),
        sender_territory_id: getLocalStorage('territory_id'),
        receiver_physician_id: this.forWord.physicianId,
        send_time: new Date(),
        title,
        url: 'https://hcp360-mobile-sandbox.lundbecksys.cn/resource/nba/azilect/azilect-slides-001.pdf',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content-share {
  min-height: 100vh;
  background: #fff;
  padding: 10px;
}
.c-serach-box {
  //   margin-top: 20px;
}
.action-plan {
  padding: 15px 10px;
}
.key-message-labels > span {
  background: rgba(236, 233, 223, 0.5);
  border-radius: 20px;
  font-size: 11px;
  color: #333;
  white-space: nowrap;
  padding: 3px 8px;
  margin: 0 2px 0 0;
}
.action-highlight {
  font-size: 16px;
  font-family: Arial;
  font-weight: 700;
  color: #aa341b;
  opacity: 1;
  text-align: left;
  line-height: 1.4;
  margin-bottom: 15px;
  .action-flag {
    background: #aaa;
  }
  span {
    font-weight: 700;
  }
}
.action-tag {
  color: #ab351c;
}
.highlight {
  color: #aa341b;
}
.action-progress {
  display: flex;
  align-items: center;
  height: 30px;
  background: rgba(236, 233, 223, 0.5);
  border-radius: 4px;
  margin-bottom: 20px;
  p {
    color: #aa341b;
    font-size: 14px;
    font-family: Arial;
    font-weight: 700;
    margin-left: 10px;
  }
}
.action-no-data {
  padding: 20px;
  color: #999;
}
.action-text {
  font-size: 14px;
  font-family: Arial;
  font-weight: 400;
  color: #2c3034;
  opacity: 1;
  text-align: left;
  line-height: 1.3;
  margin-bottom: 15px;
}
.action-flag {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #aa341b;
  opacity: 1;
  border-radius: 4px;
  margin: 0 5px 2px 0;
}
</style>
