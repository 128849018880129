import {getWecomSdkSign, getWeChatConfig} from '@/api/wechat';
import * as ww from '@wecom/jssdk';
import {getPhysicianWechatDetail, logContentSend} from '@/api/content';
import { Dialog } from 'vant';

// 自定停止录音提前时间，防止未能调用停止录音时间获取语音文件失败
export const RECORD_AUTOSTOP_AHEAD_TIME = 3000;

class WecomSDK {
  async register() {
    const sign = await getWecomSdkSign();
    console.log(sign, 'sign');

    this.ww = ww;
    return new Promise((resolve, reject) => {
      this.ww.register({
        corpId: sign.appid,
        getConfigSignature,
        onConfigSuccess: () => {
          console.log('onConfigSuccess');
          this.registered = true;
          resolve();
        },
        onConfigFail: (err) => {
          console.error('onConfigFail:', err);
          reject(err);
        },
        onConfigComplete: (res) => {
          console.log('onConfigComplete:', res);
        },
        jsApiList: [
          'startRecord',
          'stopRecord',
          'onVoiceRecordEnd',
          'playVoice',
          'pauseVoice',
          'stopVoice',
          'uploadVoice',
          'downloadVoice',
          'shareWechatMessage',
        ],
      });

      if (this.registered) {
        resolve();
      }
    });

    async function getConfigSignature() {
      return {
        timestamp: sign.timestamp,
        nonceStr: sign.noncestr,
        signature: sign.sign,
      };
    }
  }

  stopRecord() {
    clearTimeout(this.recordEndTimer);

    return this.ww.stopRecord({
      success: function (e) {
        this.localId = e.localId;
      },
    });
  }

  translateVoice(localId) {
    return this.ww.translateVoice({
      localId,
      isShowProgressTips: true,
    });
  }

  startRecord({onVoiceRecordEnd} = {}) {
    const result = this.ww.startRecord();
    this.recordEnded = false;

    if (typeof onVoiceRecordEnd === 'function') {
      this.recordEndTimer = setTimeout(() => {
        onVoiceRecordEnd();
      }, 60000 - RECORD_AUTOSTOP_AHEAD_TIME);
    }
    // ww.onVoiceRecordEnd({
    //   cancel: function () {
    //     alert('用户拒绝授权录音');
    //   },
    //   complete(e) {
    //     alert('自动停止');
    //     alert(JSON.stringify(e));
    //   },
    //   fail(e) {
    //     alert('自动停止');
    //     alert(JSON.stringify(e));
    //   },
    // });

    return result;
  }

  async shareWechatMessage(data) {
    try {
      // 获取医生微信详情
      const res = await getPhysicianWechatDetail(data.receiver_physician_id);
      let openid = null;

      if (res && res.data && res.data.open_id) {
        openid = res.data.open_id;
      }

      // 如果 openid 为空，弹出提示框
      if (!openid) {
        const confirmed = await this.showBindDialog();
        if (!confirmed) {
          console.log('用户取消了发送操作');
          return; // 用户取消，不再继续发送
        }
      }

      // 准备发送日志参数
      const params = {
        content_id: data.content_id,
        sender_user_id: data.sender_user_id,
        sender_user_name: data.sender_user_name,
        sender_territory_id: data.sender_territory_id,
        receiver_physician_id: data.receiver_physician_id,
        receiver_openid: openid || undefined,
        // send_time: data.send_time,
      };

      // 记录分享日志
      const logRes = await logContentSend(params);
      if (!logRes.isSuccess || !logRes.data) {
        throw new Error('Failed to log content send.');
      }

      // 构建分享链接
      const contentUrl = `${window.location.origin}/public/open?send_id=${logRes.data}`;
      console.log(contentUrl);

      // 确保 Wecom SDK 已注册
      await this.register();

      // 调用分享接口
      this.ww.shareWechatMessage({
        title: data.title,
        desc: '灵北同事给您转发了一篇文章',
        link: contentUrl,
        imgUrl: 'https://hcp360-mobile.lundbecksys.cn/image/lundbeck-logo.png',
        // success: () => {
        //   console.log('Share success');
        //   resolve();
        // },
        // fail: (err) => {
        //   console.error('Share failed:', err);
        //   reject(err);
        // },
      });
    } catch (error) {
      console.error('Error in shareWechatMessage:', error);
      throw error; // 抛出错误，供调用者捕获
    }
  }

  async shareQrCode(data) {
    try {
      // 构建分享链接
      const contentUrl = `${window.location.origin}/api/weixin/Wechat/GetQRCode?scene=12345&expire_seconds=259200`;
      console.log(contentUrl);

      // 确保 Wecom SDK 已注册
      await this.register();

      // 调用分享接口
      this.ww.shareWechatMessage({
        title: '灵北邀请',
        desc: '灵北同事邀请您关注公众号',
        link: contentUrl,
        imgUrl: 'https://hcp360-mobile.lundbecksys.cn/image/lundbeck-logo.png',
        // success: () => {
        //   console.log('Share success');
        //   resolve();
        // },
        // fail: (err) => {
        //   console.error('Share failed:', err);
        //   reject(err);
        // },
      });
    } catch (error) {
      console.error('Error in shareWechatMessage:', error);
      throw error; // 抛出错误，供调用者捕获
    }
  }
  async showBindDialog() {
    return new Promise((resolve) => {
      Dialog.confirm({
        title: '医生未绑定微信',
        message:
          '您选择的医生尚未绑定微信账号。本次发送的内容将在医生首次打开时，触发微信绑定与知情同意的签署流程。请在下一步确保选择该医生所对应的微信好友进行发送。',
        confirmButtonText: '确认',
        cancelButtonText: '取消',
      })
        .then(() => {
          resolve(true); // 用户点击确认
        })
        .catch(() => {
          resolve(false); // 用户点击取消
        });
    });
  }

  generateFollowLinkWithParams(officialAccountId, scene) {
    return `https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=${officialAccountId}&scene=${scene}#wechat_redirect`;
  }
}

export const wecomSDK = new WecomSDK();
