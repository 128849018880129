<template>
  <div class="relation">
    <div
      class="relaxion-select box bac-white"
      style="border-radius: 0; border-bottom: 0"
    >
      <CommonHeader
        :title="$t('relaxion_network')"
        :source="$t('feedback_data_hao')"
        :no-feedback="true"
      />
      <div class="select-flex" style="padding: 0 10px">
        <label class="txt">{{ $t('choose_relation_type') }}</label>
        <cube-select
          v-model="searchObj.type"
          :title="$t('please_placeholder')"
          :options="types"
          :cancel-txt="$t('cancel_txt')"
          :confirm-txt="$t('confirm_txt')"
          @change="change"
        />
      </div>
    </div>
    <div class="bac-white">
      <!-- <cube-loading v-if="loading" class="loading" /> -->
      <div class="relaxion-flex-box relation-chart">
        <div v-show="nodesData && nodesData.length > 0" class="relation-legend">
          <div class="dots">
            <template v-for="(ts, index) in types">
              <span v-if="index > 0 && index <= 4" :key="index">
                <div class="dot" :style="'background-color:' + ts.color"></div>
                {{ ts.text }}
              </span>
            </template>
          </div>
          <div class="dots">
            <template v-for="(ts, index) in types">
              <span v-if="index > 4" :key="index">
                <div class="dot" :style="'background-color:' + ts.color"></div>
                {{ ts.text }}
              </span>
            </template>
          </div>
          <div class="comments">{{ $t('click_to_look') }}</div>
        </div>
        <div
          v-show="nodesData && nodesData.length > 0"
          id="main"
          class="relation-main"
        ></div>
        <Nodata
          v-if="!nodesData.length"
          :loading="loading"
          :empty-txt="$t('no_relaxion_network')"
        />
        <Slider
          v-if="nodesData && nodesData.length > 0"
          :slider-number="relationCount"
          @sliderFn="sliderFn"
        />
        <!-- <p v-if="nodesData && nodesData.length > 0" class="module-update-time">{{ updateTime }}</p> -->
      </div>
    </div>
    <popup
      v-if="showBarChart"
      :title="$t('common_relaxion_detail')"
      @closeFn="closeFn"
    >
      <div
        class="box relaxion-flex-box relation-list"
        style="min-width: 300px; max-height: 400px; overflow-y: auto"
      >
        <template>
          <!-- 学术合作详情弹窗 -->
          <div class="business-box bac-white">
            <!-- <p class="business-box-title">学术关系详情</p> -->
            <div class="box doctor-relation">
              <div class="doctor-relation-item">
                <div class="doctor-detail">
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      margin-bottom: 10px;
                    "
                  >
                    <font-awesome-icon class="icon" :icon="['fa', 'user-md']" />
                    <span
                      class="doctor-detail-name"
                      style="margin-right: 10px"
                      >{{
                        relationHcpDetail.physician_name
                          ? relationHcpDetail.physician_name
                          : '医生数据暂未导入'
                      }}</span
                    >
                    <Button
                      v-if="
                        relationHcpData.physician_id_cooperation &&
                        relationHcpData.physician_id_cooperation[0] === 'P'
                      "
                      class="change-button"
                      round
                      type="info"
                      size="mini"
                      @click="enterPage(relationHcpData)"
                      >前往360</Button
                    >
                  </div>

                  <!-- <span>{{ relationHcpDetail.admin_title }}</span> -->
                </div>
                <span
                  v-show="false && relationHcpData.physician_id_cooperation"
                  class="show-all"
                  @click="linkTo(relationHcpData)"
                >
                  {{ relationHcp }}
                </span>
              </div>
              <div class="doctor-relation-item">
                <div class="doctor-detail">
                  <span style="margin-left: 0">{{
                    relationHcpDetail.institution_name
                  }}</span>
                  <span>{{ relationHcpDetail.department_name }}</span>
                </div>
              </div>
            </div>
            <SummaryRelationChart :type-data="types" />
          </div>
          <card :list="networkContents" @lookActivityDetail="lookActivityDetail"/>
        </template>
      </div>
    </popup>
    <transition name="page-move">
      <kolCardActivityDetail
        v-if="activityDetailStatus"
        :detail-obj="activityDetailObj"
        backtxt="返回"
        @closeDetailFn="closeDetailFn"
      />
    </transition>
  </div>
</template>

<script>
import _ from 'underscore';
import * as echarts from 'echarts';
import * as re from '@/api/relation';
import * as py from '@/api/Physicians';
import popup from '@/views/layout/popup';
import SummaryRelationChart from '@/components/SummaryRelationChart';
import Nodata from '@/components/Nodata';
import card from '@/components/card';
import Slider from '@/components/Slider';
import CommonHeader from '@/components/CommonHeader';
import {Button} from 'vant';
// import TabTags from '@/components/TabTags';
import {formatterDateYMD, formatterDate} from '@/filters/index';
// import { LightenDarkenColor } from '@/utils';
import kolCardActivityDetail from '@/components/kolCardActivityDetail';
import Publications from '@/mixins/kolPublications';
export default {
  name: 'ProfileDetailsRelation',
  components: {
    SummaryRelationChart,
    card,
    Nodata,
    CommonHeader,
    popup,
    Slider,
    Button,
    // TabTags
    kolCardActivityDetail,
  },
  mixins: [Publications],
  data() {
    return {
      updateTime: '',
      relationCount: 20,

      types: [
        {value: 'all', text: '全部类型', class: ''},
        {
          value: '协会关系',
          type: 'type1',
          text: '协会关系',
          color: '#ab351c',
          class: 'publication',
          total: 0,
        },
        {
          value: '同事',
          type: 'type-hospital',
          text: '同事',
          color: '#999999',
          class: 'clinical-trial',
          total: 0,
        },
        {
          value: '临床试验',
          type: 'type2',
          text: '临床试验',
          color: '#aa341b',
          class: 'colleague',
          total: 0,
        },
        {
          value: '论文合作',
          type: 'type-keshi',
          text: '论文合作',
          color: '#5470c6',
          class: 'keshi',
          total: 0,
        },
        {
          value: '会议',
          type: 'type-xiehui',
          text: '会议',
          color: '#91cc75',
          class: 'xiehui',
          total: 0,
        },
        {
          value: '编委关系',
          type: 'type-tong',
          text: '编委关系',
          color: '#7EC0EE',
          class: 'education',
          total: 0,
        },
        {
          value: '师生',
          type: 'type-tong',
          text: '师生',
          color: '#faa19d',
          class: 'education',
          total: 0,
        },
        {
          value: '基金项目',
          type: 'type-kua',
          text: '基金项目',
          color: '#fac858',
          class: 'xueke',
          total: 0,
        },
      ],
      searchObj: {
        physician_id: '',
        doctor: 'all',
        type: 'all',
      },
      networkContents: [],
      nodesData: [],
      edgesData: [],
      loading: false,
      myChart: null,
      contentsText: '全部关系数据',
      // barChartData: {
      //   colorList: ["#ab351c", "#aa341b", "#7EC0EE", "#77787b"],
      //   xData: ["论文共著", "共同试验", "同院校", "同医院"],
      //   seriesData: [],
      //   legend: true,
      // },
      showBarChart: false, // 点击显示柱状图状态
      relationHcpData: {}, // 关系医生数据
      relationHcp: '',
      relationHcpDetail: {}, // 关系医生详情数据
      physicianNameMap: {}, // 所有关系数据
      nsmTypeArr: [
        {typeName: 'Strawberry', color: '#ab351c'},
        {typeName: 'Apple', color: '#71c778'},
        {typeName: 'Banana', color: '#8fdc95'},
        {typeName: 'Cherry', color: '#b1f1b7'},
        {typeName: '其他', color: '#dddddd'},
      ],
    };
  },
  mounted() {
    this.searchObj.physician_id = this.$route.query.physician_id;
    if (this.searchObj.physician_id) {
      this.init();
    } else {
      this.$router.push({path: '/list'});
    }
    // -------------------
    // let physician_id = this.$route.query.physician_id;
  },
  methods: {
    // getNetworkType() {
    //   re.getNetworkType(this.searchObj.physician_id).then((res) => {
    //     if (res && res.length > 0) {
    //       this.types = res;
    //       this.init();
    //     }
    //   });
    // },
    linkTo(item) {
      this.$router.push({
        path: '/hcp/detail',
        query: {
          physician_id: item.physician_id_cooperation,
          selectedLabel: 'persona',
        },
      });
      window.location.reload();
    },
    settingMaxData(edgesData) {
      let maxData = _.max(edgesData, (edge) => {
        return edge.lineStyle.width;
      });
      const maxW = maxData.lineStyle.width;
      let maxV = _.max(edgesData, (edge) => {
        return edge.value;
      });
      edgesData.forEach((edge) => {
        edge.lineStyle.width = (edge.lineStyle.width * 4) / maxW;
        if (edge.name.split(',').length > 1) {
          // 如果包含两种以上关系，换一种颜色-深灰色
          edge.lineStyle.color = '#A9A9A9';
        } else {
          edge.lineStyle.color = _.find(this.types, (num) => {
            return num.value === (edge.name === '临床' ? '临床试验' : edge.name);
          }).color;
        }
        edge.lineStyle.opacity = 0.5;
        // }
      });
      return edgesData;
    },
    dealData(res) {
      // 数据处理
      // 当前医生
      this.nodesData.push({
        physician_id_cooperation: res.value[0].physician_id_cooperation,
        name: res.value[0].physician_name,
        department_name_cooperation: res.value[0].department_name_cooperation,
        institution_name_cooperation: res.value[0].institution_name_cooperation,
        des: '',
        symbolSize: 42,
        itemStyle: {
          normal: {
            color: '#aa341b',
          },
        },
      });
      let edgesData = [];
      res.value.forEach((ele) => {
        let nsmTypeColor = '#dddddd';
        let firstRelation = ele.network_type.split(',')[0];
        switch (firstRelation) {
          case '协会关系':
            nsmTypeColor = '#ab351c';
            break;
          case '同事':
            nsmTypeColor = '#999999';
            break;
          case '临床':
          case '临床试验':
            nsmTypeColor = '#aa341b';
            break;
          case '论文合作':
            nsmTypeColor = '#5470c6';
            break;
          case '会议':
            nsmTypeColor = '#91cc75';
            break;
          case '编委关系':
            nsmTypeColor = '#7EC0EE';
            break;
          case '师生':
            nsmTypeColor = '#faa19d';
            break;
          case '基金项目':
            nsmTypeColor = '#fac858';
            break;
        }
        // if (ele.physician_id_cooperation.indexOf('P') === 0) {
        //   nsmTypeColor = '#1a89fa';
        // }
        // let nsmTypeColor = ele.perception_type_cooperation
        const node = {
          physician_id_cooperation: ele.physician_id_cooperation,
          name: ele.physician_name_cooperation,
          department_name_cooperation: ele.department_name_cooperation,
          institution_name_cooperation: ele.institution_name_cooperation,
          des: '',
          // symbolSize: ,
          itemStyle: {
            normal: {
              color: nsmTypeColor,
              size: 12,
              textStyle: {
                color: '#ffffff',
              },
            },
          },
          // label: {
          //   normal: {
          //     textStyle: {
          //       color: '#ffffff'
          //     }
          //   }
          // }
        };
        this.nodesData.push(node);
        ele.network_type.split(',').forEach((rel, index) => {
          const edge = {
            // 连线
            physician_id_cooperation: ele.physician_id_cooperation,
            source: ele.physician_name,
            target: ele.physician_name_cooperation,
            name: rel,
            itemStyle: {
              normal: {
                color: '#ab351c',
              },
            },
            lineStyle: {
              width: ele.network_score ? +ele.network_score / 50 : 1,
              curveness: 0.15 * (index + 1),
            },
            des: '',
            clicked: false,
            value: 20 + Math.sqrt(ele.network_score, 10), // 长度
          };
          edgesData.push(edge);
        });
      });
      let obj = {};
      this.nodesData = this.nodesData.reduce((cur, next) => {
        obj[next.name] ? '' : (obj[next.name] = true && cur.push(next));
        return cur;
      }, []);
      // 不同关系类型给连线增加颜色
      this.edgesData = this.settingMaxData(edgesData);
      this.loading = false;

      setTimeout(() => {
        this.initChart();
      });
    },
    sumRelationCount(res, type) {
      // 处理数据 获取医生之间的关系数量
      this.physicianNameMap = {};
      this.physicianNameMap = _.groupBy(
        res[1].value,
        'physician_name_cooperation',
      );
      this.dealData(res[0]);
    },
    init() {
      this.showBarChart = false;
      if (
        this.myChart !== null &&
        this.myChart !== '' &&
        this.myChart !== undefined
      ) {
        this.myChart.dispose();
      }
      this.loading = true;
      this.nodesData = [];
      this.edgesData = [];
      re.getAllRelation(this.searchObj.physician_id, this.relationCount)
        .then((res) => {
          if (res && res.value) {
            this.dealData(res);
            // this.sumRelationCount(res, "load"); // 医生
          } else {
            this.loading = false;
            this.nodesData = [];
          }
        })
        .catch((e) => {
          this.loading = false;
          this.nodesData = [];
        });
    },
    // 获取关系网数据
    /**
     * type - search 搜索 - load 初始化
     */
    getRelationData(data) {
      if (
        this.myChart !== null &&
        this.myChart !== '' &&
        this.myChart !== undefined
      ) {
        this.myChart.dispose();
      }
      this.loading = true;
      this.nodesData = [];
      this.edgesData = [];
      re.getRelationData(this.searchObj.physician_id, data, this.relationCount)
        .then((res) => {
          if (res && res.value.length > 0) {
            this.dealData(res);
          } else {
            this.loading = false;
            this.nodesData = [];
          }
        })
        .catch((e) => {
          this.loading = false;
          this.nodesData = [];
        });
    },
    /**
     * index 索引
     */
    settingSeriesData(networkTypeMap, name, index) {
      let sdata = [];
      this.barChartData.xData.forEach((ele) => {
        sdata.push(networkTypeMap[ele] ? networkTypeMap[ele].length : 0);
      });
      this.$set(this.barChartData.seriesData, index, {
        name: name,
        data: sdata,
      });
    },
    dealContentsData(res) {
      // let typeObj = {
      //   '论文共著': 'type1',
      //   '共同试验': 'type2',
      //   '同院校': 'type-school',
      //   '同医院': 'type-hospital'
      // };

      let networkTypeMap = {}; // _.groupBy(res.value, 'network_type');;
      this.networkContents = [];
      let networkContents = [];
      console.log('rescnm----->', res);
      res.value.forEach((ele) => {
        // 根据关键字归类
        if (ele.network_type === '临床') {
          ele.network_type = '临床试验';
        }
        if (!networkTypeMap[ele.network_type]) {
          networkTypeMap[ele.network_type] = [ele];
        } else {
          networkTypeMap[ele.network_type].push(ele);
        }
        let typeObj = this.types.find((t) => t.value === ele.network_type);
        let obj = {
          ...ele,
          study: ele.network_content_text,
          one: ele.network_type,
          two: ele.physician_name_cooperation,
          type: typeObj?.type,
          view_key: ele.network_content_id,
          // type: ele.network_type === '论文合作' ? 'type1' : 'type2',
        };
        networkContents.push(obj);
      });
      this.types.forEach((ele) => {
        ele.total = 0;
        if (networkTypeMap[ele.value]) {
          ele.total = networkTypeMap[ele.value].length;
        }
      });

      // console.info(networkTypeMap, '-----', networkContents);
      this.networkContents = _.sortBy(networkContents, (item) => {
        return -item.month;
      });
      // this.settingSeriesData(networkTypeMap, res.value[0].physician_name_cooperation, 0);
    },
    getNetworkContents() {
      // 当前医生 全部关系内容数据
      this.contentsText = '全部关系数据';
      // this.barChartData.seriesData = [];
      this.showBarChart = false;
      re.getRelationContents(this.searchObj.physician_id).then((res) => {
        if (res && res.value.length > 0) {
          this.dealContentsData(res);
        } else {
          this.networkContents = [];
        }
      });
    },
    getHcpDetail(physician_id) {
      this.hcpData = {};
      py.getPhysiciansDetail(physician_id).then((res) => {
        if (res.value.length) {
          this.relationHcpDetail = res.value[0];
        } else {
          this.relationHcpDetail = {};
        }
      });
    },
    getNetWorkEdgeDetails(data, param) {
      // this.relationHcpDetail = {
      //   physician_name: data.name,
      //   institution_name: data.institution_name_cooperation,
      //   professional_title: '',
      //   physician_id_cooperation: data.physician_id_cooperation
      // };
      // 获取关系医生关系数据
      this.relationHcpData = data;
      // this.getHcpDetail(data.physician_id_cooperation);
      re.getRelationDetail(
        this.searchObj.physician_id,
        data.physician_id_cooperation,
      ).then((res) => {
        if (res && res.value.length > 0) {
          res.value[0].physician_name = res.value[0].physician_name_cooperation;
          res.value[0].institution_name =
            res.value[0].institution_name_cooperation;
          res.value[0].department_name =
            res.value[0].department_name_cooperation;
          this.relationHcpDetail = res.value[0];
          this.$store.dispatch('setDialogStatus', true);
          this.networkContents = [];
          this.dealContentsData(res);
          this.relationHcp = `跳转${res.value[0].physician_name_cooperation}HCP360`;
          this.showBarChart = true;
        } else {
          this.networkContents = [];
        }
      });
    },
    initChart() {
      this.myChart = echarts.init(document.getElementById('main'));
      let symbolSize = 42;
      let repulsion = 200;
      let labelFontSize = 12;
      if (this.relationCount === 30) {
        symbolSize = 40;
        repulsion = 130;
        labelFontSize = 12;
      } else if (this.relationCount === 40) {
        symbolSize = 35;
        repulsion = 100;
        labelFontSize = 10;
      } else if (this.relationCount === 50) {
        symbolSize = 35;
        repulsion = 90;
        labelFontSize = 10;
      }
      let option = {
        title: {text: ''},
        tooltip: {
          show: false,
          // formatter: function(param) {
          //   return `<div>${param.data.des}</div>`;
          // }
        },
        series: [
          {
            type: 'graph',
            layout: 'force',
            symbolSize: symbolSize,
            roam: false,
            animation: true,
            width: 600,
            height: 290,
            focusEdgeAdjacency: true,
            // focusNodeAdjacency: true,
            emphasis: {
              focus: 'adjacency',
            },
            edgeSymbol: ['circle'],
            edgeSymbolSize: [4],
            force: {
              repulsion: repulsion,
              edgeLength: [40, 140],
            },
            draggable: false,
            itemStyle: {
              normal: {
                color: '#ffffff',
              },
            },
            lineStyle: {
              normal: {
                width: 2,
                color: '#bbbbbb',
              },
            },
            edgeLabel: {
              normal: {
                // show: true,
                formatter: (x) => {
                  return x.data.name;
                },
              },
            },
            label: {
              normal: {
                show: true,
                textStyle: {
                  // color: '#333', // label 字体颜色
                  fontSize: labelFontSize,
                },
              },
            },
            data: this.nodesData,
            links: this.edgesData,
          },
        ],
      };
      // console.info(JSON.stringify(option), '----------');
      this.myChart.setOption(option);
      let that = this;
      this.myChart.on('click', (param) => {
        if (param.dataType === 'edge') {
          // param.data.clicked = !param.data.clicked;
          that.contentsText =
            param.data.source + `－` + param.data.target + '的关系数据';
          that.getNetWorkEdgeDetails(param.data, param);
        } else if (param.dataType === 'node') {
          if (that.nodesData[0].name === param.name) {
            that.showBarChart = false;
          } else {
            that.contentsText =
              that.nodesData[0].name + `－` + param.name + '的关系数据';
            that.getNetWorkEdgeDetails(param.data, param);
          }
        }
      });
      this.myChart.getZr().on('click', (param) => {
        if (!param.target) {
          that.showBarChart = false;
        }
      });
    },
    change() {
      let data = {
        network_type_full:
          this.searchObj.type === 'all' ? '' : (this.searchObj.type === '临床试验' ? '临床' : this.searchObj.type),
      };
      this.contentsText = '';
      this.networkContents = [];
      this.showBarChart = false;
      if (this.searchObj.type === 'all') {
        this.init();
      } else {
        this.getRelationData(data); // 查询关系网数据
      }
    },
    sliderFn(count) {
      this.relationCount = count;
      this.change();
    },
    closeFn(status) {
      // 子组件事件
      this.showBarChart = status;
    },
    enterPage(item) {
      console.log('item--->', item);
      this.closeFn(false);
      this.$router.replace({
        path: 'detail',
        query: {
          physician_id: item.physician_id_cooperation,
          selectedLabel: 'persona',
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.relation {
  .relation-chart {
    position: relative;
  }
  .relation-list {
    border-top: none;
  }
  .activity-list {
    padding-bottom: 10px;
    background-color: #fff;
  }
  .cube-select {
    // width: 31% !important;
    text-align: left;
  }
  img {
    width: 100%;
  }
  .relation-main {
    width: 100%;
    height: 375px;
    // padding-top: 40px;
    .no-data {
      font-size: 12px;
      color: #dddddd;
      margin-top: 20px;
    }
  }
  .loading {
    width: 100vw;
    height: 100vh;
    position: relative;
  }
  .title {
    position: relative;
  }
}
.relaxion-select {
  padding-bottom: 10px;
}
.business-box {
  padding-top: 10px;
  border-radius: 12px;
  .business-box-title {
    padding: 10px;
    font-size: 16px;
  }
  .guan-title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    font-size: 14px;
    padding: 5px 10px 15px 10px;
  }
  .show-all {
    font-size: 11px;
    padding: 5px 10px;
    background: #aa341b;
    border-radius: 10px;
    color: #fff;
    margin-left: 5px;
  }
}
.doctor-relation {
  margin-bottom: 10px;
  border-top: none;
  .doctor-relation-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    & + .doctor-relation-item {
      margin-top: 5px;
    }
  }
  .doctor-title {
    font-size: 16px;
    text-align: left;
    color: #000;
    padding: 10px 0;
  }
  .doctor-detail {
    font-size: 12px;
    color: #999;
    text-align: left;
    .doctor-detail-name {
      font-size: 14px;
      color: #333333;
      font-weight: bold;
    }

    span {
      margin-left: 5px;
    }
  }
}
.slide-scroll {
  width: 100%;
  height: 22px;
  position: relative;
  overflow: hidden;
  .horizontal-scroll-list-wrap {
    // border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    .cube-scroll-content {
      display: inline-block;
    }
    .list-wrapper {
      padding: 0 10px;
      line-height: 20px;
      font-size: 12px;
      white-space: nowrap;
    }
    .list-item {
      display: inline-block;
      width: 60px;
      background-color: #dddddd;
      margin-right: 10px;
      border-radius: 3px;
    }
  }
}
.relation .loading {
  .cube-loading-spinners {
    position: absolute !important;
    top: 30%;
    left: 49%;
  }
}
.relation-legend {
  // position: absolute;
  // top: -10px;
  width: 100%;
  text-align: center;

  .dots {
    span {
      display: inline-block;
      font-size: 10px;
      margin-right: 10px;
      .dot {
        display: inline-block;
        vertical-align: baseline;
        width: 16px;
        height: 8px;
        border-radius: 4px;
        background-color: #ccc;
        margin-right: 2px;

        &.publication {
          background-color: #ab351c;
        }
        &.clinical-trial {
          background-color: #aa341b;
        }
        &.education {
          background-color: #7ec0ee;
        }
        &.colleague {
          background-color: #77787b;
        }
        &.keshi {
          background-color: #77787b;
        }
        &.xiehui {
          background-color: #77787b;
        }
        &.xueke {
          background-color: #77787b;
        }
        &.daqu {
          background-color: #77787b;
        }
        &.daqufenxing {
          background-color: #77787b;
        }
      }
    }
  }
  .comments {
    margin-top: 10px;
    color: #666;
    font-size: 10px;
  }
}
.module-update-time {
  padding-bottom: 10px;
}
.change-button {
  width: 60px;
  height: 22px;
}
</style>
