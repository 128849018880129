import { render, staticRenderFns } from "./TabTags.vue?vue&type=template&id=5783d666&scoped=true"
import script from "./TabTags.vue?vue&type=script&lang=js"
export * from "./TabTags.vue?vue&type=script&lang=js"
import style0 from "./TabTags.vue?vue&type=style&index=0&id=5783d666&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5783d666",
  null
  
)

export default component.exports