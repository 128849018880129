<template>
  <div class="home-content">
    <Tabs v-model="activeTab" color="#aa341b" @click="tabOnClick">
      <Tab v-for="(tb, index) in tabLabels" :key="index" :title="tb.label" />
    </Tabs>
    <div class="home-container">
      <keep-alive>
        <MeetingList v-if="activeTab === 0" />
        <TrainingList v-if="activeTab === 1" />
      </keep-alive>
    </div>
  </div>
</template>
<script>
import MeetingList from './meeting-list.vue';
import TrainingList from './training-list.vue';
import { Tab, Tabs } from 'vant';
export default {
  components: {
    Tab,
    Tabs,
    MeetingList,
    TrainingList,
  },
  data() {
    return {
      tabLabels: [
        {label: '学术会议', active: 0},
        {label: '医学培训', active: 1},
      ],
      activeTab: 0,
    };
  },
  created() {
    this.activeTab = Number(this.$route.query.selectedLabel) || 0;
  },
  methods: {
    tabOnClick(index) {
      this.$router.push({
        query: {
          selectedLabel: index,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.home-content {
  width: 100%;
  height: 100%;
  .home-container {
    width: calc(100% - 20px);
    height: calc(100% - 64px);
  }
}
</style>
