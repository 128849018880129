<template>
  <div>
    <div v-if="loading">
      <img
        class="logo"
        src="@/assets/lundbeck/lundbeck-logo-1280x1280.png"
        alt="Lundbeck Logo"
      />
      <img class="spinner" src="/image/spinner.gif" />
    </div>
    <div v-if="!loading && error" class="bind-success">
      <div class="banner">
        <img src="@/assets/lundbeck/error.png" alt="success" />
        <div class="success-title">出错啦</div>
      </div>

      <img
        class="logo"
        src="@/assets/lundbeck/lundbeck-logo-1280x1280.png"
        alt="Lundbeck Logo"
      />
      <div class="success-text">
        {{ msg }}<br />
        请稍后重试或联系系统管理员。
      </div>
    </div>
    <div v-if="showContent">
      <pdf v-if="fileLocalLink" :src="fileLocalLink" />
      <iframe
        v-else
        :src="fileLocalLink"
        style="width: 100vw; height: 100vh; border: none"
        scrolling="yes"
      ></iframe>
    </div>
  </div>
</template>
<script>
import {getLocalStorage} from '@/utils/index';
import {getWeChatConfig} from '@/api/wechat';
import {
  getLogContentSend,
  getPhysicianWechatDetail,
  getContent,
  logContentOpen,
  logContentOpenDuration,
  logContentForward,
} from '@/api/content';
import pdf from 'vue-pdf';
import {getPhysicianWechatDetailByOpenId} from '../../api/content';
import {weixinOASDK} from '@/utils/weixinOA';
import * as py from '@/api/Physicians';
import {getMimeTypeBySuffix} from '@/utils/index';
// import swipeDialog from '@/views/layout/swipeDialog';

export default {
  components: {
    pdf,
    // swipeDialog,
  },
  data() {
    return {
      open_id: '',
      send_id: '',
      loading: true,
      msg: '对不起，验证失败',
      error: false,
      contentSend: null,
      content: null,
      showContent: false,
      toPhysician: null, // physician_weixin from log content send
      openPhysician: null,
      isPdf: false,
      logOpenId: '',
      ticks: 0,
      intervalId: null,
      s3Key: '',
      fileLocalLink: '',
    };
  },
  created() {
    this.open_id = getLocalStorage('open_id');
    const urlParams = new URLSearchParams(window.location.search);
    this.send_id = urlParams.get('send_id');
    if (this.open_id) {
      // 打开文章
      getPhysicianWechatDetailByOpenId(this.open_id).then((res) => {
        if (res && res.data && res.data.physician_id) {
          // 存在医生
          this.openPhysician = res.data;
        } else {
          this.openPhysician = {
            physician_id: null,
            open_id: this.open_id,
            union_id: null,
          };
        }
        if (this.send_id) {
          // load
          getLogContentSend(this.send_id)
            .then((res) => {
              this.contentSend = res.data;
              if (this.contentSend && this.contentSend.receiver_physician_id) {
                getPhysicianWechatDetail(this.contentSend.receiver_physician_id)
                  .then((res) => {
                    if (res && res.data && res.data.physician_id) {
                      // 医生已绑定，判断open_id是否一致
                      this.toPhysician = res.data;
                      // this.loading = false;
                      console.log(
                        this.toPhysician.open_id,
                        this.open_id,
                        this.toPhysician,
                      );
                      // 医生是否有绑定过（openid为空）
                      if (this.toPhysician.open_id) {
                        if (this.toPhysician.open_id === this.open_id) {
                          this.openPhysician = this.toPhysician;
                          // open_id一致的话，判断是否签署了知情同意
                          if (this.toPhysician.is_consent) {
                            // Success
                            this.loadContent();
                          } else {
                            this.authorize();
                          }
                        } else {
                          // this.loadContent();
                          this.loading = false;
                          this.error = true;
                          this.msg = '用户不一致';
                        }
                      } else {
                        if (
                          this.openPhysician &&
                          this.openPhysician.physician_id
                        ) {
                          // 已注册的医生，直接打开
                          this.loadContent();
                        } else {
                          // 从未注册的医生，绑定到当前客户
                          // 医生未绑定，openid为空
                          if (!this.toPhysician.is_consent) {
                            // 医生未绑定，继续绑定流程
                            this.authorize();
                          } else {
                            // 已签署过知情同意，直接绑定
                            this.authorize();
                          }
                        }
                      }
                    } else {
                      // 医生未绑定，继续绑定流程
                      this.authorize();
                    }
                  })
                  .catch((e) => {
                    // 医生未绑定，继续绑定流程
                    this.authorize();
                  });
              } else {
                this.loading = false;
                this.error = true;
                this.msg = '内容格式错误';
              }
            })
            .catch((e) => {
              this.loading = false;
              this.error = true;
            });
        } else {
          this.loading = false;
          this.error = true;
          this.msg = '错误：无效的内容链接';
        }
      });
    } else {
      this.authorize();
    }
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  unmounted() {
    // Vue 3 中使用 unmounted
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    authorize() {
      getWeChatConfig().then((res) => {
        let appid = res?.data?.appid;
        let redirect_uri = encodeURIComponent(
          window.location.origin +
            '/public/authorize' +
            (this.send_id ? '?send_id=' + this.send_id : ''),
        );
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=#wechat_redirect`;
      });
    },
    loadContent() {
      console.log(this.openPhysician);
      let logOpenParams = {
        log_content_send_id: this.send_id,
        content_id: this.contentSend.content_id,
        reader_physician_id: this.openPhysician.physician_id,
        reader_openid: this.openPhysician.open_id,
        reader_unionid: this.openPhysician.union_id,
      };
      logContentOpen(logOpenParams).then((res) => {
        if (res && res.data) {
          this.logOpenId = res.data;
        }
      });

      this.loading = true;
      getContent(this.send_id).then((res) => {
        this.loading = false;
        this.showContent = true;
        this.content = res.data;

        // 设置窗口标题为内容的标题
        if (this.content.content_name) {
          document.title = this.content.content_name;
        }

        if (this.content.s3_file_name.indexOf('.pdf') > 0) {
          this.getS3FileUrl(this.content.s3_file_name);
          // this.isPdf = false;
        }

        weixinOASDK.register({
          title: this.content.content_name,
          url: window.location.href,
          // shareCallback: () => {
          //   let forwardParam = {
          //     "log_content_send_id": this.send_id,
          //     "content_id": this.contentSend.content_id,
          //     "sender_type": this.openPhysician.physician_id ? 'physician' : 'unknown',
          //     "sender_physician_id": this.openPhysician.physician_id,
          //     "sender_openid": this.openPhysician.open_id,
          //     "sender_unionid": this.openPhysician.union_id,
          //   };
          //   logContentForward(forwardParam).then(res => {
          //     console.log('forward logged');
          //   });
          // },
        });

        this.intervalId = setInterval(() => {
          this.ticks++;
          if (this.ticks > (60 * 60) / 5) {
            // Over 1 hour
            if (this.ticks % 12 !== 0) {
              return;
            }
          }
          logContentOpenDuration({log_content_open_id: this.logOpenId}).then();
        }, 5000);
      });
    },
    getS3FileUrl(key) {
      py.getS3ByKey(key).then((res) => {
        let currentMimeType = getMimeTypeBySuffix(
          key.split('.').pop().toLowerCase(),
        );
        let blob = new Blob([res.data], {
          type: currentMimeType,
        });
        this.s3Key = key;
        this.fileLocalLink = window.URL.createObjectURL(blob);
        console.log('this.fileLocalLink---->', this.fileLocalLink);
        this.loading = false;
        this.$store.dispatch('setDialogStatus', true);
        this.isPdf = false;
      });
    },
    closePdfFn() {
      this.$store.dispatch('setDialogStatus', false);
      this.fileLocalLink = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  display: block;
  width: 60px;
  height: 60px;
  margin: 40px auto;
}
.spinner {
  display: block;
  width: 24px;
  height: 24px;
  margin: 60px auto 120px auto;
}
.bind-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  .banner {
    background-color: #aa341b;
    width: 100%;
    height: 280px;
    text-align: center;
  }
  img {
    width: 85px;
    height: 80px;
    margin-top: 80px;
  }

  .success-title {
    font-size: 18px;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 40px;
  }

  .logo {
    width: 60px;
    height: 60px;
    margin: 40px 0;
  }

  .success-text {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
    margin: 0px 0;
  }

  .close-btn {
    width: 40%;
    height: 44px;
    background: #ccc;
    color: #666;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    margin-top: 40px;

    &:active {
      opacity: 0.9;
    }
  }
}
</style>
