<template>
  <div class="kinds-content">
    <img v-if="false" :src="categoryImg" width="100%" />
    <div v-if="false">
      <CommonHeader title="NBA策略地图" source="用户提供" :no-feedback="true" />
      <flow-chart :type="fcType" />
    </div>
    <div>
      <van-uploader
        v-if="false"
        :max-size="30 * 1024 * 1024"
        :after-read="afterReadFile"
        :max-count="9"
        :preview-image="false"
        accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.mp4"
        @oversize="onOversize"
      >
        <van-button icon="plus" type="primary">上传文件</van-button>
      </van-uploader>
    </div>
    <van-tabs
      v-if="showProduct.length > 1"
      type="card"
      color="#aa341b"
      class="product-tabs"
      @click="setCurrentTab"
    >
      <van-tab
        v-if="showProductText.indexOf('Azilect') > -1"
        title="Azilect"
        class="product-tab"
      >
        <azilect-vue
          v-if="currentTab === 'Azilect'"
          :product-id="getProductIdForText('Azilect')"
        />
      </van-tab>
      <van-tab
        v-if="showProductText.indexOf('Brintellix') > -1"
        title="Brintellix"
        class="product-tab"
      >
        <BrintellixVue
          v-if="currentTab === 'Brintellix'"
          :product-id="getProductIdForText('Brintellix')"
        />
      </van-tab>
      <van-tab
        v-if="showProductText.indexOf('Ebixa') > -1"
        title="Ebixa"
        class="product-tab"
      >
        <ebixa-vue
          v-if="currentTab === 'Ebixa'"
          :product-id="getProductIdForText('Ebixa')"
        />
      </van-tab>
      <van-tab
        v-if="showProductText.indexOf('Lexapro') > -1"
        title="Lexapro"
        class="product-tab"
      >
        <lexapro-vue
          v-if="currentTab === 'Lexapro'"
          :product-id="getProductIdForText('Lexapro')"
        />
      </van-tab>
    </van-tabs>
    <div v-else-if="showProduct.length == 1" class="product-tabs">
      <div class="product-one">{{ showProduct[0].product_name }}</div>
      <div class="product-tab">
        <azilect-vue
          v-if="currentTab === 'Azilect'"
          :product-id="showProduct[0].product_id"
        />
        <BrintellixVue
          v-if="currentTab === 'Brintellix'"
          :product-id="showProduct[0].product_id"
        />
        <ebixa-vue
          v-if="currentTab === 'Ebixa'"
          :product-id="showProduct[0].product_id"
        />
        <lexapro-vue
          v-if="currentTab === 'Lexapro'"
          :product-id="showProduct[0].product_id"
        />
      </div>
    </div>
    <Nodata v-else :empty-txt="$t('no_data')" />
  </div>
</template>
<script>
import Nodata from "@/components/Nodata";
import * as py from "@/api/Physicians";
// import SegType from '@/components/SegType';
import CommonHeader from "@/components/CommonHeader";
import sortDynamicMixins from "@/mixins/sortDynamicMixins";
import FlowChart from "./flowChart.vue";
import { Tabs, Tab, Uploader, Button } from "vant";
import AzilectVue from "./NBA/Azilect.vue";
import LexaproVue from "./NBA/Lexapro.vue";
import BrintellixVue from "./NBA/Brintellix.vue";
import EbixaVue from "./NBA/Ebixa.vue";
import { setLocalStorage, getLocalStorage } from "@/utils/index";
import { s3Service } from "@/utils/aws/index";
export default {
  name: "ProfileDetailsTyping",
  components: {
    CommonHeader,
    FlowChart,
    AzilectVue,
    LexaproVue,
    EbixaVue,
    BrintellixVue,
    vanTabs: Tabs,
    vanTab: Tab,
    vanUploader: Uploader,
    vanButton: Button,
    Nodata,
  },
  mixins: [sortDynamicMixins],
  data() {
    return {
      categoryImg: require("../../../assets/lundbeck/category.png"),
      showProduct: [],
      showProductText: "",
      currentTab: "",
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getShowProduct();
  },
  methods: {
    getShowProduct() {
      let userId = "";
      if (getLocalStorage("user_id")) {
        userId = getLocalStorage("user_id");
      }
      if (!userId) {
        this.$router.push({
          path: "/error",
          query: {},
        });
      }
      let is_sales = getLocalStorage('is_sales');
      let params = {
        user_id: getLocalStorage('user_id'),
        physician_id: this.$route.query.physician_id,
      };
      if (is_sales === '1') {
        py.getUserPhysicianProduct(params).then((res) => {
          this.showProduct = [];
          if (res.value && res.value.length) {
            this.showProduct = res.value.map((item) => {
              return {
                product_id: item.product_id,
                product_name: item.product_name,
              };
            });
          }
          this.showProductText = JSON.stringify(this.showProduct);
          if (this.showProductText) {
            this.getCurrentTab();
          }
        });
      } else {
        // py.getUserProduct(getLocalStorage("user_id")).then((res) => {
        //   this.showProduct = [];
        //   if (res.value && res.value.length) {
        //     this.showProduct = res.value.map((item) => {
        //       return {
        //         product_id: item.product_id,
        //         product_name: item.product_name,
        //       };
        //     });
        //   }
        //   this.showProductText = JSON.stringify(this.showProduct);
        //   if (this.showProductText) {
        //     this.getCurrentTab();
        //   }
        // });

        Promise.all([
          py.getUserProduct(getLocalStorage("user_id")),
          py.getProductPhysicians(this.$route.query.physician_id),
        ]).then((res) => {
          this.showProduct = [];
          res[0].value.map((item) => {
            res[1].value.map((el) => {
              if (item.product_id === el.product_id) {
                this.showProduct.push({
                  product_id: item.product_id,
                  product_name: item.product_name
                });
              }
            });
          });
          this.showProductText = JSON.stringify(this.showProduct);
          if (this.showProductText) {
            this.getCurrentTab();
          }
        });
      }
    },
    getProductIdForText(text) {
      return this.showProduct.find((e) => e.product_name === text).product_id;
    },
    getCurrentTab() {
      if (this.showProductText.indexOf("Azilect") > -1) {
        this.currentTab = "Azilect";
      } else if (this.showProductText.indexOf("Brintellix") > -1) {
        this.currentTab = "Brintellix";
      } else if (this.showProductText.indexOf("Ebixa") > -1) {
        this.currentTab = "Ebixa";
      } else if (this.showProductText.indexOf("Lexapro") > -1) {
        this.currentTab = "Lexapro";
      }
    },
    setCurrentTab(name, title) {
      this.currentTab = title;
    },
    afterReadFile(file) {
      // 此时可以自行将文件上传至服务器
      file.status = "uploading";
      s3Service
        .upload(file.file, {
          dirPath: "LFile/nba_content",
          filename:
            file.file.name.substring(0, file.file.name.lastIndexOf(".")) +
            "_" +
            Date.parse(new Date()),
        })
        .then((res) => {
          console.log("res", res);
          if (res.is_success) {
            file.status = "done";
          } else {
            file.status = "failed";
          }
        });
    },
    onOversize() {
      this.$toast("文件大小不能超过 10M");
    },
  },
};
</script>
<style scoped lang="scss">
.product-tabs {
  background-color: #ffffff;
  padding: 20px 10px;
  .product-tab {
    margin-top: 20px;
  }
  .van-tab--active {
    margin: -1px;
  }
  .product-one {
    margin-top: 0;
  }
}
</style>
