var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-share"},[_c('van-search',{attrs:{"shape":"round","placeholder":"请输入搜索关键词"},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}}),_c('div',{staticClass:"c-serach-box"},[_c('div',{staticClass:"c-serach-item"},[_c('div',{staticClass:"c-s-label"},[_vm._v("标签筛选")]),_c('div',{staticClass:"flex flex-wrap",staticStyle:{"margin-bottom":"-10px"}},_vm._l((_vm.nbaKeyMessagesLabels),function(se,index){return _c('div',{key:index,staticClass:"c-s-item",class:{active: se.active},staticStyle:{"margin-bottom":"10px","font-size":"11px"},on:{"click":function($event){se.active = !se.active}}},[_c('div',{staticClass:"c-s-flex"},[_vm._v(_vm._s(se.title))])])}),0)])]),(_vm.nbaKeyMessages.length)?_c('div',[_c('div',{staticClass:"action-plan"},_vm._l((_vm.nbaKeyMessages),function(item,index){return _c('div',{key:index},[_c('p',{staticClass:"action-text flex"},[_c('span',[_c('span',{staticClass:"key-message-labels"},_vm._l((_vm.newLabelShow(
                  item.cnf_key_message_content?.labels?.split(','),
                )),function(label){return _c('span',{key:label},[_vm._v(" "+_vm._s(label)+" ")])}),0),_vm._v(" "+_vm._s(item.cnf_key_message_content?.key_messages)+" ")]),_c('span',[_c('img',{staticStyle:{"margin-left":"4px"},attrs:{"src":require("@/assets/lundbeck/NBA/preview.png"),"alt":"","width":"26"},on:{"click":function($event){return _vm.previewImage(
                  item.cnf_key_message_content?.slides,
                  item.cnf_key_message_content?.key_messages,
                )}}})]),_c('span',[_c('img',{staticStyle:{"margin-left":"4px"},attrs:{"src":require("@/assets/lundbeck/NBA/forward.png"),"alt":"","width":"26"},on:{"click":function($event){return _vm.choosePhysician(
                  item.cnf_key_message_content?.slides,
                  item.cnf_key_message_content?.key_messages,
                )}}})])])])}),0)]):_vm._e(),(_vm.pdfLink)?_c('swipeDialog',{attrs:{"backtxt":"返回","title":'预览'},on:{"closeFn":_vm.closePdfFn}},[_c('pdf',{directives:[{name:"show",rawName:"v-show",value:(_vm.pdfLink),expression:"pdfLink"}],attrs:{"src":_vm.pdfLink}})],1):_vm._e(),(_vm.showDoctorSelect)?_c('DoctorListSelectAllAndNew',{attrs:{"multipleMode":false,"defaultSelectedList":_vm.doctors},on:{"close":function($event){_vm.showDoctorSelect = false},"select":_vm.handleSelectDoctor}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }