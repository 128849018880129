<template>
  <Tabbar v-model="active" :safe-area-inset-bottom="true" @change="changeTab" active-color="#aa341b">
    <TabbarItem icon="friends">医生</TabbarItem>
    <TabbarItem icon="chat">拜访</TabbarItem>
    <!-- <TabbarItem icon="good-job">建议</TabbarItem> -->
    <TabbarItem icon="column">内容</TabbarItem>
    <TabbarItem icon="graphic">报告</TabbarItem>
  </Tabbar>
</template>

<script>
import {Tabbar, TabbarItem} from 'vant';
import {getLocalStorage} from '@/utils/index';
export default {
  name: 'TabBar',
  components: {
    Tabbar,
    TabbarItem,
  },
  data() {
    return {
      active: 0,
    };
  },
  created() {
    // 初始化时根据路由设置 active 值
    this.updateActiveTab(this.$route.name);

    // 监听路由变化
    this.$router.afterEach((to) => {
      this.updateActiveTab(to.name);
    });
  },
  methods: {
    updateActiveTab(routeName) {
      switch (routeName) {
        case 'doctorListHome':
        case 'listHome':
        case 'list':
        case 'hcp':
          this.active = 0;
          break;
        case 'visitListHome':
          this.active = 1;
          break;
        // case 'nbaListHome':
        //   this.active = 2;
        //   break;
        case 'contentHome':
          this.active = 2;
          break;
        case 'reportHome':
          this.active = 3;
          break;
        default:
          this.active = -1; // 默认设置为第一个 Tab
          break;
      }
    },
    changeTab(e) {
      switch (e) {
        case 0:
          this.$router.push({
            path: '/hcp/home',
            query: {
              user_id: getLocalStorage('user_id_key'),
            },
          });
          break;
        case 1:
          this.$router.push({
            path: '/hcp/visit-list',
          });
          break;
        // case 2:
        //   this.$router.push({
        //     path: '/hcp/nba-list',
        //   });
        //   break;
        case 2:
          this.$router.push({
            path: '/hcp/content',
          });
          break;
        case 3:
          this.$router.push({
            path: '/hcp/report',
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .tab-bar {
  .van-tabbar__item--active {
    color: #aa341b;
  }
}
</style>
