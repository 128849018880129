import request from '@/utils/request';
import {getLocalStorage} from '@/utils/index';
import {BASE_URL} from '@/utils/constants.js';
import {
  getOdataFilterStringFromObject,
  getPageOdataString,
  attachOdataQueryString,
} from '@/utils/odata.js';

// 查询拜访列表
export function fetchPhysicianMslCalls(filter, skip = 0, top = 10) {
  let url = `/api/standard/odata/PhysicianMslCalls?$expand=physician($select=academic_title,academic_title_kvp,admin_title,admin_title_kvp,city,city_kvp,city_tier,degree,department_name,ex_data_support,institution_id,institution_name,institution_tier,institution_tier_kvp,institution_type,physician_id,physician_name,professional_title,professional_title_kvp,province,province_kvp)&$orderby=call_time desc&$skip=${skip}&$top=${top}`;

  let filterString = getOdataFilterStringFromObject(filter);
  if (filterString) {
    filterString += ' and physician_id ne null';
  } else {
    filterString += 'physician_id ne null';
  }
  url = attachOdataQueryString(url, [filterString]);

  return request({
    url,
    method: 'get',
  });
}

// 查询单个拜访/协防
export function fetchOnePhysicianMslCalls(id) {
  return request({
    url: `/api/standard/odata/PhysicianMslCalls('${id}')?$expand=physician($select=academic_title,academic_title_kvp,admin_title,admin_title_kvp,city,city_kvp,city_tier,degree,department_name,ex_data_support,institution_id,institution_name,institution_tier,institution_tier_kvp,institution_type,physician_id,physician_name,professional_title,professional_title_kvp,province,province_kvp),physician_msl_call__physician_msl_call_companions,`,
    method: 'get',
  });
}

// 洞见列表
export function fetchPhysicianMslInsights(filter = {}, skip = 0, top = 10) {
  let url = `/api/standard/odata/PhysicianMslInsights?$expand=physician_msl_insight__physician_msl_insight_questions,physician($select=academic_title,academic_title_kvp,admin_title,admin_title_kvp,city,city_kvp,city_tier,degree,department_name,ex_data_support,institution_id,institution_name,institution_tier,institution_tier_kvp,institution_type,physician_id,physician_name,professional_title,professional_title_kvp,province,province_kvp)&$orderby=insight_time desc&$skip=${skip}&$top=${top}`;

  let filterString = getOdataFilterStringFromObject(filter);
  if (filterString) {
    filterString += ' and physician_id ne null';
  } else {
    filterString += 'physician_id ne null';
  }
  url = attachOdataQueryString(url, [filterString]);

  return request({
    url,
    method: 'get',
  });
}

// 获取单个洞见
export const fetchOnePhysicianMslInsights = (id) => {
  const url = `/api/standard/odata/PhysicianMslInsights('${id}')?$expand=physician($select=academic_title,academic_title_kvp,admin_title,admin_title_kvp,city,city_kvp,city_tier,degree,department_name,ex_data_support,institution_id,institution_name,institution_tier,institution_tier_kvp,institution_type,physician_id,physician_name,professional_title,professional_title_kvp,province,province_kvp),physician_msl_insight__physician_msl_insight_questions`;
  return request({
    url,
    methos: 'get',
  });
};

// 创建/修改拜访记录
export const createVisitRecord = (data) => {
  let url = '/api/standard/odata/PhysicianMslCalls';
  const id = data?.physician_msl_call_id;
  if (id) {
    url += `('${id}')`;
    delete data['@odata.context'];
    data = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== null),
    );
  }

  return request({
    url,
    method: id ? 'patch' : 'post',
    data: data,
  });
};

// 创建/修改洞见记录
export const createEditInsightRecord = ({
  physician_msl_insight__physician_msl_insight_questions,
  ...data
}) => {
  let url = '/api/standard/odata/PhysicianMslInsights';
  const id = data.physician_msl_insight_id;

  if (id) {
    url += `('${id}')`;
    delete data['@odata.context'];
    data = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== null),
    );
  }
  return request({
    url,
    method: id ? 'patch' : 'post',
    data: data,
  });
};

export const fetchPhysicianMslInsightQuestions = (id) => {
  let url = '/api/standard/odata/PhysicianMslInsightQuestions';

  if (id) {
    url += `?$filter=physician_msl_insight_id eq '${id}'`;
  }

  return request({
    url,
  });
};

export const createEditPhysicianMslInsightQuestions = (data) => {
  let url = '/api/standard/odata/PhysicianMslInsightQuestions';
  const id = data.physician_msl_insight_id;
  // if (id) {
  //   url += `('${id}')`;
  //   delete data['@odata.context'];
  //   data = Object.fromEntries(
  //     Object.entries(data).filter(([_, value]) => value !== null),
  //   );
  // }
  return request({
    url,
    method: 'post',
    data: data,
  });
};

export const deletePhysicianMslInsightQuestions = async (ids = []) => {
  let url = '/api/standard/odata/PhysicianMslInsightQuestions';

  await Promise.all(
    ids.map((id) =>
      request({
        url: `${url}('${id}')`,
        method: 'delete',
      }),
    ),
  );
};

// 删除MSL拜访记录
export const deleteMslCall = (id) => {
  const url = `/api/standard/odata/PhysicianMslCalls('${id}')`;

  return request({
    url,
    method: 'delete',
  });
};
// 删除MSL洞见记录
export const deleteMslInsight = (id) => {
  const url = `/api/standard/odata/PhysicianMslInsights('${id}')`;

  return request({
    url,
    method: 'delete',
  });
};

// 获取洞见标签列表
export const fetchInsightQuestionLabels = (id) => {
  let url = '/api/standard/odata/InsightQuestionLabels';
  if (id) {
    url += `?$filter=insight_question_id eq '${id}'`;
  }
  return request({
    url,
    methos: 'get',
  }).then((res) => res.value);
};

// // 获取洞见问题 KIT
export const fetchInsightTopics = (insight_topic_id) => {
  const tas = getLocalStorage('tas');
  let taQuery = '';
  if (tas) {
    const taList = tas.split(',');
    taQuery = taList.map((ta_id) => `ta_id eq '${ta_id}'`).join(' or ');
  }
  const now = new Date().toISOString();
  const url =
    '/api/standard/odata/InsightTopics?$expand=insight_topic__insight_questions,insight_dimension' +
    `&$filter=(start_date le ${now} and end_date ge ${now})` +
    (taQuery ? ` and (${taQuery})` : '') +
    (insight_topic_id ? ` or insight_topic_id eq '${insight_topic_id}'` : '');
  return request({
    url,
    methos: 'get',
  }).then((res) => res.value);
};

// 获取重点KIQ

// 获取洞见问题 KIQ
export const fetchHighlightedInsightQuestions = () => {
  const tas = getLocalStorage('tas');
  let taQuery = '';
  if (tas) {
    const taList = tas.split(',');
    taQuery = taList
      .map((ta_id) => `insight_topic/ta_id eq '${ta_id}'`)
      .join(' or ');
  }

  let url =
    '/api/standard/odata/InsightQuestions?$expand=insight_topic&$filter=is_highlighted eq 1' +
    (taQuery ? ` and (${taQuery})` : '');
  return request({
    url,
    methos: 'get',
  }).then((res) => res.value);
};

// 获取洞见问题 KIQ
export const fetchInsightQuestions = (filter = {}, pagination = {}) => {
  let url = '/api/standard/odata/InsightQuestions';

  const queryStringList = [];
  const filterString = getOdataFilterStringFromObject(filter);
  if (filterString) {
    queryStringList.push(filterString);
  }
  const paginationString = getPageOdataString(pagination);
  if (paginationString) {
    queryStringList.push(paginationString);
  }
  const queryString = queryStringList.join('&');
  if (queryString) {
    url += `?${queryString}`;
  }

  return request({
    url,
    methos: 'get',
  }).then((res) => res.value);
};

// 获取单个医生信息
export const fetchOnePhysician = (id) => {
  const url = `/api/standard/odata/physicians('${id}')`;
  return request({
    url,
    methos: 'get',
  });
};

export const fetchPhysicianEngagements = (
  physicianId,
  {engagement_type} = {},
  {page, pageSize} = {page: 0, pageSize: 10},
) => {
  // const url = `https://lundbeck-kol360-mobile-dev.lekinsights.cn/api/standard/OData/PhysicianEngagements?$filter=physician_id eq '${physicianId}'`;
  let filterList = [`physician_id eq '${physicianId}'`];
  if (engagement_type?.length) {
    filterList.push(
      `(${engagement_type
        .map((item) => `engagement_type eq '${item}'`)
        .join(' or ')})`,
    );
  }
  const filterString = filterList.join(' and ');
  const url = `/api/standard/OData/PhysicianEngagements?${
    filterString ? `$filter=${filterString}&` : ''
  }$skip=${page}&$top=${pageSize}&$orderby=engagement_date desc`;

  return request({
    url,
  }).then((res) => res.value);
};

// 新增PhysicianTitleSupplements
export const createPhysicianTitleSupplements = (data) => {
  const url = '/api/standard/odata/PhysicianTitleSupplements';
  return request({
    url,
    method: 'post',
    data,
  });
};

// 查询PhysicianTitleSupplements
export const fetchUserSelfPhysicianTitleSupplements = (data) => {
  const url = `/api/standard/odata/PhysicianTitleSupplements?$filter=physician_id eq '${data.physician_id}' and user_id eq '${data.user_id}' and is_public eq 0`;
  return request({
    url,
    method: 'get',
  });
};
export const fetchPublicPhysicianTitleSupplements = (data) => {
  const url = `/api/standard/odata/PhysicianTitleSupplements?$filter=physician_id eq '${data.physician_id}' and is_public eq 1`;
  return request({
    url,
    method: 'get',
  });
};

// 新增PhysicianAssociationSupplements
export const createPhysicianAssociationSupplements = (data) => {
  const url = '/api/standard/odata/PhysicianAssociationSupplements';
  return request({
    url,
    method: 'post',
    data,
  });
};
// 修改
export const editPhysicianAssociationSupplements = (id, data) => {
  const url = `/api/standard/odata/PhysicianAssociationSupplements('${id}')`;
  return request({
    url,
    method: 'patch',
    data,
  });
};
// 查询PhysicianTitleSupplements
export const fetchUserSelfPhysicianAssociationSupplements = (data) => {
  const url = `/api/standard/odata/PhysicianAssociationSupplements?$filter=physician_id eq '${data.physician_id}' and user_id eq '${data.user_id}' and is_public eq 0`;
  return request({
    url,
    method: 'get',
  });
};
export const fetchPublicPhysicianAssociationSupplements = (data) => {
  const url = `/api/standard/odata/PhysicianAssociationSupplements?$filter=physician_id eq '${data.physician_id}' and is_public eq 1`;
  return request({
    url,
    method: 'get',
  });
};
// 新增PhysicianAssociationSupplements
export const createPhysicianJournalSupplements = (data) => {
  const url = '/api/standard/odata/PhysicianJournalSupplements';
  return request({
    url,
    method: 'post',
    data,
  });
};
// 修改
export const editPhysicianJournalSupplements = (id, data) => {
  const url = `/api/standard/odata/PhysicianJournalSupplements('${id}')`;
  return request({
    url,
    method: 'patch',
    data,
  });
};
// 查询PhysicianTitleSupplements
export const fetchUserSelfPhysicianJournalSupplements = (data) => {
  const url = `/api/standard/odata/PhysicianJournalSupplements?$filter=physician_id eq '${data.physician_id}' and user_id eq '${data.user_id}' and is_public eq 0`;
  return request({
    url,
    method: 'get',
  });
};
export const fetchPublicPhysicianJournalSupplements = (data) => {
  const url = `/api/standard/odata/PhysicianJournalSupplements?$filter=physician_id eq '${data.physician_id}' and is_public eq 1`;
  return request({
    url,
    method: 'get',
  });
};

// 新增PhysicianEducationSupplements
export const createPhysicianEducationSupplements = (data) => {
  const url = '/api/standard/odata/PhysicianEducationSupplements';
  return request({
    url,
    method: 'post',
    data,
  });
};
// 修改
export const editPhysicianEducationSupplements = (id, data) => {
  const url = `/api/standard/odata/PhysicianEducationSupplements('${id}')`;
  return request({
    url,
    method: 'patch',
    data,
  });
};
// 查询PhysicianTitleSupplements
export const fetchUserSelfPhysicianEducationSupplements = (data) => {
  const url = `/api/standard/odata/PhysicianEducationSupplements?$filter=physician_id eq '${data.physician_id}' and user_id eq '${data.user_id}' and is_public eq 0`;
  return request({
    url,
    method: 'get',
  });
};
export const fetchPublicPhysicianEducationSupplements = (data) => {
  const url = `/api/standard/odata/PhysicianEducationSupplements?$filter=physician_id eq '${data.physician_id}' and is_public eq 1`;
  return request({
    url,
    method: 'get',
  });
};

export const fetchKvpKey = (filter) => {
  let url = '/api/standard/odata/KvpKeys';

  const queryList = [getOdataFilterStringFromObject(filter)].filter(Boolean);

  if (queryList.length) {
    url += `?${queryList.join('&')}`;
  }

  return request({
    url,
  });
};

// 获取用餐同事
export const fetchMealCompanion = (searchKey, territoryType, roleName, pagination) => {
  let url = '/api/standard/odata/VUserMmses';
  const queryList = [getPageOdataString(pagination)];
  let filterString = `$filter=true`;
  if (territoryType === 'MR') {
    filterString = `$filter=(territory_type ne 'DM' and territory_type ne 'AM')`;
  } else if (territoryType === 'DM') {
    filterString = `$filter=(territory_type ne 'AM')`;
  } else if (roleName === 'RPM') {
    filterString = `$filter=(territory_type ne 'AM' and not (contains(position,'市场经理') or contains(position,'市场总监')))`;
  }
  if (searchKey) {
    filterString += ` and contains(user_name,'${searchKey}')`;
  }
  queryList.push(filterString);
  url = attachOdataQueryString(url, queryList);

  return request({
    url,
  }).then((res) => res.value);
};

// 获取协访同事
export const fetchVUsers = (searchKey, pagination) => {
  let url = '/api/standard/odata/VUserMses';
  const queryList = [getPageOdataString(pagination)];
  if (searchKey) {
    queryList.push(`$filter=contains(user_name,'${searchKey}')`);
  }
  url = attachOdataQueryString(url, queryList);

  return request({
    url,
  }).then((res) => res.value);
};

// 获取拜访同行人
export const fetchVUserMsls = (searchKey, pagination) => {
  let url = '/api/standard/odata/VUserMsls';
  const queryList = [getPageOdataString(pagination)];
  if (searchKey) {
    queryList.push(`$filter=contains(user_name,'${searchKey}')`);
  }
  url = attachOdataQueryString(url, queryList);

  return request({
    url,
  }).then((res) => res.value);
};

// 创建访问同行人
export const createMslCallCompanion = (data) => {
  const url = '/api/standard/odata/PhysicianMslCallCompanions';

  return request({
    url,
    method: 'post',
    data,
  });
};

// 删除访问同行人
export const deleteMslCallCompanion = (id) => {
  const url = `/api/standard/odata/PhysicianMslCallCompanions('${id}')`;

  return request({
    url,
    method: 'delete',
  });
};

// 获取动态统���数据
export const fetchPhysicianDataStats = (physician_id) => {
  const url = `/api/standard/odata/PhysicianDataStats?$filter=physician_id eq '${physician_id}'&$top=1`;
  return request({
    url,
  }).then((res) => res.value?.[0]);
};

// 获取拜访统计
export const fetchMslCallState = (fiilter) => {
  let url = '/api/standard/odata/MslCallStates';
  url = attachOdataQueryString(url, [getOdataFilterStringFromObject(fiilter)]);
  return request({url}).then((res) => res.value[0] ?? {});
};

// 获取拜访医生统计
export const fetchPhysicianMslCallState = (fiilter) => {
  let url =
    '/api/standard/odata/PhysicianMslCallStates?$expand=physician($select=academic_title,academic_title_kvp,admin_title,admin_title_kvp,city,city_kvp,city_tier,degree,department_name,ex_data_support,institution_id,institution_name,institution_tier,institution_tier_kvp,institution_type,physician_id,physician_name,professional_title,professional_title_kvp,province,province_kvp)';
  url = attachOdataQueryString(url, [getOdataFilterStringFromObject(fiilter)]);
  return request({url}).then((res) => res.value);
};

// 工作-学术会议
export const fetchMedicalMeetings = (data) => {
  let url = `/api/standard/OData/MedicalMeetings?$filter=user_id eq '${data.user_id}'&$orderby=meeting_date asc&$top=20&$count=true`;
  return request({url, method: 'get'});
};

// 工作-医学培训
export const fetchMedicalTrainings = (data) => {
  let url = `/api/standard/OData/MedicalTrainings?$filter=user_id eq '${data.user_id}'&$orderby=training_date asc&$top=20&$count=true`;
  return request({url, method: 'get'});
};

// 添加-学术会议
export const addMedicalMeetings = (data) => {
  let url = `/api/standard/OData/MedicalMeetings`;
  return request({url, method: 'post', data});
};

// 修改-学术会议
export const editMedicalMeetings = (id, data) => {
  let url = `/api/standard/OData/MedicalMeetings('${id}')`;
  return request({url, method: 'patch', data});
};

// 删除-学术会议
export const deleteMedicalMeetings = (id) => {
  let url = `/api/standard/OData/MedicalMeetings('${id}')`;
  return request({url, method: 'delete'});
};

// 获取学术会议详情
export const fetchMedicalMeetingDetail = (id) => {
  let url = `/api/standard/OData/MedicalMeetings('${id}')`;
  return request({url, method: 'get'});
};

// 添加-医学培训
export const addMedicalTrainings = (data) => {
  let url = `/api/standard/OData/MedicalTrainings`;
  return request({url, method: 'post', data});
};

// 修改-医学培训
export const editMedicalTrainings = (id, data) => {
  let url = `/api/standard/OData/MedicalTrainings('${id}')`;
  return request({url, method: 'patch', data});
};

// 删除-医学培训
export const deleteMedicalTrainings = (id) => {
  let url = `/api/standard/OData/MedicalTrainings('${id}')`;
  return request({url, method: 'delete'});
};

// 获取医学培训详情
export const fetchMedicalTrainingDetail = (id) => {
  let url = `/api/standard/OData/MedicalTrainings('${id}')`;
  return request({url, method: 'get'});
};
