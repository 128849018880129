import {getWechatSdkSign, getWeChatConfig} from '@/api/wechat';
import wx from 'weixin-js-sdk';

// 自定停止录音提前时间，防止未能调用停止录音时间获取语音文件失败
export const RECORD_AUTOSTOP_AHEAD_TIME = 3000;

class WeixinOA {
  async register(content) {
    this.content = content;
    const sign = await getWechatSdkSign();
    const res = await getWeChatConfig();
    let appid = res?.data?.appid;

    window.wx = wx;
    let wxParams = {
      debug: false,
      appId: appid, // 必填，当前用户企业所属企业ID
      timestamp: sign.timestamp,
      nonceStr: sign.noncestr,
      signature: sign.sign,
      jsApiList: [
        'updateAppMessageShareData', // 分享到好友
        'updateTimelineShareData', // 分享到朋友圈
      ],
    };
    window.wx.config(wxParams);

    // 配置完成后，监听 ready 事件
    window.wx.ready(() => {
      console.log('wx ready');

      // 设置“分享给朋友”的自定义分享内容
      window.wx.updateAppMessageShareData({
        title: content.title, // 分享标题
        desc: '来自灵北的文章', // 分享描述
        link: content.url, // 分享链接
        imgUrl: 'https://hcp360-mobile.lundbecksys.cn/image/lundbeck-logo.png', // 分享图标
        success: () => {
          console.log('AppMessage share success');
          this.onShareSuccess('appMessage');
        },
        fail: (err) => {
          console.error('AppMessage share failed:', err);
        },
      });
    });

    // 处理错误
    window.wx.error((err) => {
      console.error('wx config error:', err);
    });
  }

  // 自定义分享成功的回调处理逻辑
  onShareSuccess(shareType) {
    console.log(`Share success: ${shareType}`);
    // 微信已经不支持监听转发行为
    // if (this.content && this.content.shareCallback) {
    //   this.content.shareCallback();
    // }
  }

  closeWindow() {
    if (typeof window.WeixinJSBridge !== 'undefined' && window.WeixinJSBridge.call) {
      window.WeixinJSBridge.call('closeWindow');
    } else if (window.close) {
      window.close();
    } else {
      console.warn('无法关闭页面，请确保在微信浏览器中运行');
    }
  }
}

export const weixinOASDK = new WeixinOA();
