var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view-wrapper"},[[_c('div',{staticClass:"search-bar-list pd-btm0"},[(_vm.showHeaderBar)?_c('swipeBack',{attrs:{"title":_vm.title,"backtxt":"返回"},on:{"backFn":_vm.backFn}}):_vm._e(),_c('div',{staticClass:"search-filter"},[_c('div',{staticClass:"search-filter-input"},[_c('van-search',{attrs:{"shape":"round","background":"#ffffff","placeholder":"输入医院/科室/医生搜索","clearable":false},on:{"input":_vm.searchDoctor},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('div',{staticClass:"search-filter1"},[_c('van-button',{staticClass:"search-filter-button",attrs:{"round":"","type":"info","disabled":_vm.searchDisable,"loading":_vm.loading && _vm.resetDisable},on:{"click":_vm.searchDoctor}},[_vm._v(" 搜索 ")]),_c('van-button',{staticClass:"search-filter-button",attrs:{"round":"","plain":"","type":"info","disabled":_vm.resetDisable,"loading":_vm.loading && _vm.searchDisable},on:{"click":_vm.reset}},[_vm._v(" 重置 ")])],1)])],1),_c('div',{staticClass:"index-list-wrapper custom",class:{
        'no-list': _vm.list.length == 0,
        'index-list-wrapper-nosales': !_vm.isSales,
      },staticStyle:{"background-color":"#f5f7f8"}},[_c('van-list',{attrs:{"finished":_vm.finished,"immediate-check":false,"finished-text":_vm.$t('no_more_txt')},on:{"load":_vm.onLoad},model:{value:(_vm.listLoading),callback:function ($$v) {_vm.listLoading=$$v},expression:"listLoading"}},_vm._l((_vm.list),function(item,$index){return _c('CardItem',{key:item.product_id + '_' + item.physician_id + $index,ref:$index == 0 ? 'AIMREF' : '',refInFor:true,style:(_vm.selectedItems?.includes?.(item.physician_id)
              ? {border: '1px solid #aa341b'}
              : {}),attrs:{"id":'ITEM' + $index,"is-favorite":true,"gen-status":false,"show-draw":false,"item":item,"parting":item.result,"physician-segment":item.physician_segment},on:{"refreshFn":_vm.pageFn,"clickItem":function($event){return _vm.selectItem(item)}}})}),1),_c('div',{staticStyle:{"font-size":"14px","color":"#a7a6a6","margin-top":"60px"}},[_c('div',{staticStyle:{"margin-bottom":"30px"}},[_vm._v("请通过搜索寻找您要添加的医生")]),_c('div',{staticStyle:{"width":"40%","margin":"auto","border-top":"2px solid #eee"}}),_c('div',{staticStyle:{"margin-top":"30px"}},[_vm._v(" 如果无法找到，请手工填写添加新医生 ")]),_c('van-button',{staticStyle:{"margin-top":"30px"},attrs:{"type":"info","round":"","size":"small","plain":"","color":"#aa341b"},on:{"click":function($event){_vm.showAddDoctor = true}}},[_vm._v(" 添加医生 ")])],1),_c('van-dialog',{attrs:{"theme":"round-button","confirm-button-color":"#aa341b","show-confirm-button":false},model:{value:(_vm.showAddDoctor),callback:function ($$v) {_vm.showAddDoctor=$$v},expression:"showAddDoctor"}},[_c('div',{staticClass:"diglog-title",attrs:{"slot":"title"},slot:"title"},[_c('h3',{staticClass:"diglog-title-text"},[_vm._v("新增医生")]),_c('div',{staticClass:"diglog-title-close",on:{"click":() => {
                _vm.showAddDoctor = !_vm.showAddDoctor;
              }}},[_vm._v(" × ")])]),_c('div',{staticClass:"diglog-questions"},[_c('Field',{staticClass:"field-border",attrs:{"label":"医生姓名","required":"","border":false,"placeholder":"请输入姓名"},model:{value:(_vm.newDoctor.physician_name),callback:function ($$v) {_vm.$set(_vm.newDoctor, "physician_name", $$v)},expression:"newDoctor.physician_name"}}),_c('Field',{staticClass:"field-border",attrs:{"label":"所在医院","required":"","border":false,"placeholder":"请输入医院"},model:{value:(_vm.newDoctor.institution_name),callback:function ($$v) {_vm.$set(_vm.newDoctor, "institution_name", $$v)},expression:"newDoctor.institution_name"}}),_c('Field',{staticClass:"field-border",attrs:{"label":"所在科室","required":"","border":false,"placeholder":"请输入科室"},model:{value:(_vm.newDoctor.department_name),callback:function ($$v) {_vm.$set(_vm.newDoctor, "department_name", $$v)},expression:"newDoctor.department_name"}}),_c('Field',{staticClass:"field-border",staticStyle:{"margin-bottom":"20px"},attrs:{"label":"医生职称","required":"","border":false,"placeholder":"请输入职称"},model:{value:(_vm.newDoctor.professional_title),callback:function ($$v) {_vm.$set(_vm.newDoctor, "professional_title", $$v)},expression:"newDoctor.professional_title"}}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('Button',{staticStyle:{"height":"30px","width":"80px"},attrs:{"round":"","type":"info","color":"#D9D9D9"},on:{"click":() => {
                  _vm.showAddDoctor = !_vm.showAddDoctor;
                }}},[_vm._v(" 取消 ")]),_c('Button',{staticStyle:{"height":"30px","width":"80px"},attrs:{"round":"","type":"info","color":"#aa341b"},on:{"click":function($event){return _vm.saveOffice(0)}}},[_vm._v(" 添加 ")])],1)],1)]),_c('div',{staticStyle:{"height":"50px"}})],1)],(_vm.showDescStatus)?_c('popup',{attrs:{"title":_vm.searchDesc},on:{"closeFn":function($event){_vm.showDescStatus = false}}},[_c('div',{staticClass:"box",staticStyle:{"max-height":"400px","overflow-y":"auto"}},[_c('div',{staticClass:"seg-type-desc box"},[_c('div',{staticClass:"seg-type-list"},[(_vm.searchDesc == '搜索说明')?_c('p',[_c('span',{staticClass:"td-value"},[_vm._v(" 可输入医院名称、科室名称和医生姓名，以及选择产品和医生分型进行搜索 ")])]):_vm._e(),(_vm.searchDesc == '排序说明')?[_c('p',[_c('span',{staticClass:"td-title"},[_vm._v("按姓名：")]),_c('span',{staticClass:"td-value"},[_vm._v("按照医生姓名的汉语拼音顺序排序")])]),_c('p',[_c('span',{staticClass:"td-title"},[_vm._v("按医院名称：")]),_c('span',{staticClass:"td-value"},[_vm._v("按照医院名称的汉语拼音顺序排序")])]),_c('p',[_c('span',{staticClass:"td-title"},[_vm._v("按医生等级：")]),_c('span',{staticClass:"td-value"},[_vm._v(" 按照医生的ABC等级排序（仅支持在选择了产品搜索时使用） ")])])]:_vm._e()],2)])])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }