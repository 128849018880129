<template>
  <div>
    <div style="margin: 0 20px; padding-bottom: 60px; padding-top: 20px">
      <van-tabs v-model="active" type="card" color="#aa341b">
        <van-tab :title="'外部动态'">
          <div class="summary-padding">
            <div class="flex flex-wrap">
              <div
                v-for="(item, index) in labels"
                :key="index"
                class="summary-item"
              >
                <span class="label">{{ item }}</span>
                <span class="value">
                  <label class="v-bd">{{ values ? values[index] : '-' }}</label>
                </span>
              </div>
            </div>

            <DataRequest
              v-if="externalDataLastUpdate"
              :external-data-last-update="externalDataLastUpdate"
            />
          </div>

          <CommonHeader title="动态详情" :no-feedback="true" />
          <div class="c-serach-box">
            <div class="c-serach-item">
              <div class="c-s-label">类型筛选</div>
              <div class="flex flex-wrap" style="margin-bottom: -10px">
                <div
                  v-for="(se, index) in externalFilterList"
                  :key="index"
                  style="margin-bottom: 10px; font-size: 12px"
                  class="c-s-item"
                  :class="{active: se.active}"
                  @click="se.active = !se.active"
                >
                  <div class="c-s-flex">{{ se.title }}</div>
                </div>
              </div>
            </div>
          </div>
          <ul class="activity-list">
            <li v-for="(item, index) in newsList" :key="index">
              <!-- 论文发表 -->
              <template>
                <!-- <span class="type type1">{{ $t('publication') }}</span> -->
                <div v-if="item.isNew" class="new-icon">NEW</div>
                <span
                  class="type type1"
                  :style="{backgroundColor: labelColorMap[item.activity_type]}"
                  >{{ item.activity_type }}</span
                >
                <label style="font-weight: initial" v-html="item.title"></label>
                <br />
                <div style="display: flex; justify-content: space-between">
                  <span> </span>
                  <span
                    v-if="item.url"
                    style="color: #aa341b"
                    @click="goUrl(item.url)"
                    >查看原文</span
                  >
                </div>
                <div
                  v-if="item.activity_type || item.platform"
                  class="info-details flex-detail"
                >
                  <span v-if="item.activity_type" class="journal e-ellipsis-l1">
                    <label
                      style="font-weight: initial"
                      v-html="item.activity_type"
                    ></label>
                    <span v-if="item.platform" class="journal_if">
                      :
                      <label
                        style="font-weight: initial"
                        v-html="item.platform"
                      ></label>
                    </span>
                  </span>

                  <span class="time"> {{ item.date | dealNullData }}</span>
                </div>
                <Tags :tag-item="item.keywordsArr" />
              </template>
            </li>
          </ul>
          <Nodata
            v-if="!newsList.length"
            :loading="detailLoading"
            :empty-txt="$t('no_data')"
          />
          <physician-meeting-ex v-if="false" style="margin: 0 -10px" />
        </van-tab>
        <van-tab :title="'内部交互'">
          <div class="flex flex-wrap summary-padding">
            <div
              v-for="(item, index) in internalLabels"
              :key="index"
              class="summary-item"
            >
              <span class="label">{{ item }}</span>
              <span class="value">
                <label class="v-bd">{{
                  internalValues ? internalValues[index] : '-'
                }}</label>
              </span>
            </div>
          </div>

          <CommonHeader
            title="会议与拜访详情"
            :no-feedback="true"
            icon-url="/lundbeck/NBA/physician.png"
          />

          <div class="c-serach-box">
            <div class="c-serach-item">
              <div class="c-s-label">类型筛选</div>
              <div
                v-for="(se, index) in internalFilterList"
                :key="index"
                class="c-s-item"
                :class="{active: se.active}"
                @click="se.active = !se.active"
              >
                <div class="c-s-flex">{{ se.title }}</div>
              </div>
            </div>
          </div>
          <AsyncList ref="internalAsyncListRef" :fn="fetchPhysicianEngagements">
            <template v-slot="{list}">
              <van-steps direction="vertical" :active="-1">
                <van-step
                  v-for="(itemList, index) in partitionNewsList(list)"
                  :key="index"
                >
                  <div style="text-align: left; color: #333">
                    {{ itemList?.[0]?.formatDate }}
                  </div>
                  <ul class="activity-list">
                    <li
                      v-for="(item, index) in itemList?.map((item) => ({
                        label: item.engagement_type,
                        content: item.engagement_title,
                        date: item.formatDate,
                      }))"
                      :key="index"
                    >
                      <span
                        class="type type1"
                        :style="{
                          backgroundColor:
                            labelColorMap[engagementTypeMap[item.label]],
                        }"
                        >{{ engagementTypeMap[item.label] }}</span
                      >
                      <label style="font-weight: initial">{{
                        item.content
                      }}</label>
                      <br />
                      <div
                        class="info-details flex-detail flex justify-between"
                      >
                        <span>{{ item.meeting }}</span>
                      </div>
                    </li>
                  </ul>
                </van-step>
              </van-steps>
            </template>
          </AsyncList>

          <!-- <Nodata
            v-if="!meetingsVisitList.length"
            :loading="internalDetailLoading"
            :empty-txt="$t('no_data')"
          /> -->
        </van-tab>

        <van-tab v-if="showInsight" :title="'医学洞见'">
          <PhysicianInsightList />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import CommonHeader from '@/components/CommonHeader';
import {Tabs, Tab, Loading, Step, Steps} from 'vant';
import physicianMeetingEx from '@/views/hcp/components/physicianMeetingEx.vue';
import * as moment from 'moment';
import Nodata from '@/components/Nodata';
import * as py from '@/api/Physicians';
import {fetchPhysicianEngagements} from '@/api/kol';
import {formatterDatenEn} from '@/filters/index';
import AsyncList from '@/components/AsyncList.vue';
import PhysicianInsightList from '@/views/kol/components/PhysicianInsightList.vue';
import Tags from '@/components/Tags';
import asyncData from '@/mixins/asyncData';
import {getLocalStorage} from '@/utils';
import {fetchPhysicianDataStats} from '@/api/kol';
import Vue from 'vue';
import {groupBy} from 'lodash';
import DataRequest from '@/views/hcp/components/dataRequest.vue';

Vue.use(Step);
Vue.use(Steps);

const engagementTypeMap = {
  mr拜访: '销售拜访',
  msl拜访: '医学拜访',
  会议: '内部会议',
};

export default {
  components: {
    Tags,
    PhysicianInsightList,
    Nodata,
    CommonHeader,
    vanTabs: Tabs,
    vanTab: Tab,
    physicianMeetingEx,
    AsyncList,
    DataRequest,
  },
  // mixins: [
  //   asyncData(
  //     () => fetchPhysicianDataStats(this.$route.query?.physician_id),
  //     'statistic',
  //   ),
  // ],
  props: {
    showInsight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      engagementTypeMap,
      detailLoading: false,
      internalDetailLoading: false,
      labelColorMap: {
        医学拜访: '#06B0F0',
        内部会议: '#FFBF00',
        销售拜访: '#0561B9',
        公众号: '#3DAA45',
        第三方会议: '#FFBF00',
        患教文章: '#13AEF1',
        患教直播: '#0561B9',
      },
      newsList: [],
      meetingsVisitList: [],
      externalFilterList: [
        {
          title: '公众号',
          value: '公众号',
          active: true,
          field: 'type',
          // children: [
          //   {
          //     title: '第一作者',
          //     value: [1, 3],
          //     active: false,
          //     field: 'author_seq_no',
          //   },
          //   {
          //     title: '其他',
          //     value: 0,
          //     active: false,
          //     field: 'author_seq_no',
          //   },
          // ],
        },
        {
          title: '第三方会议',
          value: '第三方会议',
          active: true,
          field: 'type',
        },
        {
          title: '患教文章',
          value: '患教文章',
          active: true,
          field: 'type',
        },
        {
          title: '患教直播',
          value: '患教直播',
          active: true,
          field: 'type',
        },
      ],
      internalFilterList: [
        {
          title: '销售拜访',
          value: 'mr拜访',
          active: true,
          field: 'type',
          // children: [
          //   {
          //     title: '第一作者',
          //     value: [1, 3],
          //     active: false,
          //     field: 'author_seq_no',
          //   },
          //   {
          //     title: '其他',
          //     value: 0,
          //     active: false,
          //     field: 'author_seq_no',
          //   },
          // ],
        },
        {
          title: '医学拜访',
          value: 'msl拜访',
          active: true,
          field: 'type',
        },
        {
          title: '内部会议',
          value: '会议',
          active: true,
          field: 'type',
        },
      ],
      active: 0,
      labels: ['最近动态', '近一年动态', '最近参会', '近一年参会'],
      internalLabels: [
        '近三月销售拜访',
        '近三月医学拜访',
        '近三月内部会议',
        '最近内部互动',
      ],
      values: null,
      internalValues: null,
      externalDataLastUpdate: '',
      // values: ['3月前', '8', '1年前', '4', '4天前', '32', '3天前', '7天前'],
    };
  },
  computed: {
    physician_id() {
      return this.$route.query?.physician_id;
    },
    internalFilterParams() {
      const filterValueList = this.internalFilterList
        .filter((item) => item.active)
        .map((item) => item.value);
      return {engagement_type: filterValueList};
    },
  },
  watch: {
    internalFilterList: {
      handler(value) {
        const filterValueList = value
          .filter((item) => item.active)
          .map((item) => item.value);
        if (filterValueList.length) {
          this.$refs.internalAsyncListRef?.run?.();
        } else {
          this.$refs.internalAsyncListRef?.clear?.();
        }
        console.log(filterValueList, 'filterValueList');
      },
      deep: true,
    },
    externalFilterList: {
      handler(value) {
        const filterValueList = value
          .filter((item) => item.active)
          .map((item) => item.value);
        if (!filterValueList.length) {
          this.newsList = [];
        } else {
          this.getList({activity_type: filterValueList});
        }
      },
      deep: true,
    },
  },
  mounted() {
    fetchPhysicianDataStats(this.physician_id).then((res) => {
      this.statistic = res;
      const {
        activity_last_update_text,
        activity_last_year_no,
        meeting_ex_last_update_text,
        meeting_ex_last_year_no,
      } = this.statistic || {};
      this.values = [
        activity_last_update_text,
        activity_last_year_no,
        meeting_ex_last_update_text,
        meeting_ex_last_year_no,
      ];
      const {
        call_last_3m_no,
        mslcall_last_3m_no,
        meeting_last_3m_no,
        engagement_last_update_text,
      } = this.statistic || {};
      this.internalValues = [
        call_last_3m_no,
        mslcall_last_3m_no,
        meeting_last_3m_no,
        engagement_last_update_text,
      ];
      this.externalDataLastUpdate = formatterDatenEn(
        this.statistic.external_data_last_update,
      );
    });
    this.getList();
  },
  methods: {
    formatterDatenEn,
    fetchPhysicianEngagements(pagination) {
      return fetchPhysicianEngagements(
        this.physician_id,
        this.internalFilterParams,
        pagination,
      );
    },
    getList(filter) {
      this.newsList = [];
      this.detailLoading = true;
      py.getVPhysicianActivities(this.physician_id, filter)
        .then((res) => {
          if (res && res.value.length) {
            res.value.forEach((ele) => {
              let labelsArr = [];
              if (ele.labels) {
                labelsArr = ele.labels.split(',');
              }
              this.newsList.push({
                platform: ele.platform,
                title: ele.title,
                url: ele.url,
                date:
                  ele.activity_date && moment(ele.activity_date).isValid()
                    ? moment(ele.activity_date).format('YYYY-MM-DD')
                    : ele.activity_date,
                data_sort:
                  ele.activity_date_sort &&
                  moment(ele.activity_date_sort).isValid()
                    ? moment(ele.activity_date_sort).format('YYYY-MM-DD')
                    : ele.activity_date_sort,
                isNew:
                  ele.activity_date &&
                  moment(ele.activity_date).isValid() &&
                  moment(ele.activity_date).isAfter(
                    moment().subtract(3, 'months'),
                  ),
                activity_type: ele.activity_type,
                keywordsArr: labelsArr,
              });
            });
          }
          this.detailLoading = false;
        })
        .catch((e) => {
          this.detailLoading = false;
        });
    },

    selectFn(index) {
      if (index || index === 0) {
        this.searchList.forEach((ele, $index) => {
          ele.active = false;
          if (index === $index) {
            this.activeIndex = $index;
            ele.active = true;
            this.activeChildren = ele.children;
          }
        });
        this.$emit(
          'selectedFn',
          this.searchList[this.activeIndex],
          this.selectTime,
        );
      }
    },

    goto(url) {
      if (url) {
        location.href = url;
      }
    },
    partitionNewsList(list) {
      const groupData = groupBy(
        list.map((item) => ({
          ...item,
          formatDate: formatterDatenEn(item.engagement_date),
        })),
        (item) => item.formatDate,
      );
      return Object.values(groupData);
    },
    goUrl(url) {
      window.open(url);
    },
  },
};
</script>

<style scoped lang="scss">
.summary-item {
  width: 25%;
  margin-bottom: 10px;
  .label {
    font-size: 10px;
    color: #666;
    display: block;
    margin-bottom: 4px;
  }
  .value {
    padding: 10px;
    padding-top: 4px;
    display: block;
    font-size: 15px;

    color: #ab351c;
    .v-bd {
      font-weight: initial;
    }
    // &.publication {
    //     color: #ab351c;
    // }
    // &.clinical-trial {
    //     color: #aa341b;
    // }
    // &.project {
    //     color: #7EC0EE;
    // }
    // &.award {
    //     color: #FFD700;
    // }
  }
}

.mx-20px {
  margin: 0 20px;
}

.summary-padding {
  padding-top: 15px;
  padding-bottom: 15px;
}

::v-deep .van-tabs__nav {
  border-radius: 50px;
  overflow: hidden;
}

::v-deep .van-tabs__wrap {
  margin-bottom: 10px;
}

.flex-detail {
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  justify-content: space-between;
  span {
    padding-right: 10px;
  }
}
.c-s-item {
  font-size: 11px !important;
}
.new-icon {
  display: inline-block;
  padding: 0 5px;
  height: 18px;
  text-align: center;
  font-size: 9px;
  font-weight: 800;
  border-radius: 50px;
  background-color: #fff;
  color: rgb(255, 191, 0);
  border: 2px solid rgb(255, 191, 0);
}

::v-deep .van-step--vertical:not(:last-child)::after {
  border-bottom: none;
}
</style>
