<template>
  <div class="parting">
    <div>
      <div>
        <CommonHeader
          title="医院划分"
          icon-url="/lundbeck/NBA/hospital.png"
          :no-feedback="true"
        >
          <div v-if="canEditStatus" slot="before">
            <img
              v-if="!isHospitalFirst"
              src="@/assets/lundbeck/NBA/edit.png"
              alt=""
              width="16"
              style="margin-top: 6px"
              @click="openHospitalDialog()"
            />
          </div>
        </CommonHeader>
        <div v-if="isHospitalFirst">
          <p class="tip-text">请先回答问题来确认医院划分</p>
          <template v-if="canEditStatus">
            <van-button
              class="tip-button"
              round
              type="info"
              @click="openHospitalDialog()"
            >
              立即进行问答
            </van-button>
          </template>
          <template v-else>
            <div style="height: 120px;"></div>
          </template>
        </div>
        <div v-else>
          <!-- 医院行动建议 -->
          <div v-if="hospitalActionShow" class="parting-type-action">
            <div class="action-title">
              <div class="title-flag"></div>
              <span class="title-text">当前行动&资源匹配建议</span>
            </div>
            <div>
              <div class="action-plan">
                <div>
                  <!-- 【强化推广型】医院:BB -->
                  <p
                    v-if="hospitalSelectedParting === 'BB'"
                    class="action-text"
                  >
                    <span class="action-flag"></span>
                    优先市场推广活动，稳步推动来士普在差异化竞争中赢得增长
                  </p>
                  <!-- 【合理用药型】医院：BA -->
                  <p
                    v-if="hospitalSelectedParting === 'BA'"
                    class="action-text"
                  >
                    <span class="action-flag"></span>
                    优先合理用药活动，协同CKA/GA，推动来士普管控状态往更积极的方向发展
                  </p>
                  <!-- 【Digital助力型】医院:AB -->

                  <p
                    v-if="hospitalSelectedParting === 'AB'"
                    class="action-text"
                  >
                    <span class="action-flag"></span>
                    优先线上相关活动，推进来士普向院外或线上渠道引流
                  </p>

                  <!-- 暂不作为重点 -->
                  <p
                    v-if="hospitalSelectedParting === 'AA'"
                    class="action-text"
                  >
                    <span class="action-flag"></span>
                    暂不作为重点
                  </p>
                </div>
              </div>
            </div>

            <!-- 医院分型历史 -->
            <div class="parting-type-history">
              <div class="history-title">
                <div class="title-flag"></div>
                <span class="title-text">医院分型历史</span>
              </div>
              <div class="history-content">
                <div
                  v-for="item in showHospitalPartingHistorys"
                  :key="item.physician_typing_institution_id"
                >
                  <div class="history-date">
                    <div class="date-flag"></div>
                    <span class="date-text">{{ getDate(item.create_time) }}</span>
                  </div>
                  <div class="history-text">
                    <p>{{ item.result }}</p>
                    <p class="modified-name">
                      {{
                        item.modify_user_name
                          ? item.modify_user_name
                          : item.modify_user_id
                      }}
                    </p>
                  </div>
                </div>
                <div v-if="showHospitalExpand">
                  <div
                    v-if="!hospitalHistoryExpandStatus"
                    class="expend-btn"
                    @click="expendHospitalHistory"
                  >
                    <span class="arrow-down-flag"></span>
                    展开
                  </div>
                  <div v-else class="expend-btn" @click="expendHospitalHistory">
                    <span class="arrow-up-flag"></span>
                    收起
                  </div>
                </div>
              </div>
            </div>

            <div class="action-title">
              <div class="title-flag"></div>
              <span class="title-text">市场机会判断</span>
            </div>
            <div>
              <!-- <div class="parting-type-history">
                <div class="history-title">
                  <div class="title-flag"></div>
                  <span class="title-text">{{ hospitalCompete }}</span>
                </div>
                <div class="history-text">
                  <div v-if="hospitalCompete === '品牌竞争'" class="history-text t-left">
                    该家医院艾司西酞普兰分子所占份额{{ parseInt(hospitalCompeteObj.Escitalopram_rating * 100) }}%≥该家医院来士普在艾司分子中的份额{{ parseInt(hospitalCompeteObj.Lexapro_Escitalopram_rating * 100) }}%
                  </div>
                  <div v-if="hospitalCompete === '分子竞争'" class="history-text t-left">
                    该家医院艾司西酞普兰分子所占份额{{ parseInt(hospitalCompeteObj.Escitalopram_rating * 100) }}%&lt;该家医院来士普在艾司分子中的份额{{ parseInt(hospitalCompeteObj.Lexapro_Escitalopram_rating * 100) }}%
                  </div>
                </div>
              </div> -->
              <div class="action-plan">
                <div>
                  <template v-if="hospitalCompete === '品牌竞争'">
                    <p class="action-text">
                      <span class="action-flag"></span>
                      该家医院艾司西酞普兰分子所占份额{{ parseInt(hospitalCompeteObj.Escitalopram_rating * 100) }}%≥该家医院来士普在艾司分子中的份额{{ parseInt(hospitalCompeteObj.Lexapro_Escitalopram_rating * 100) }}%
                    </p>
                    <!-- 品牌竞争	系统自动匹配信息X%≥Y% -->
                    <p class="action-text">
                      <span class="action-flag"></span>
                      该医院市场机会主要来源于艾司西酞普兰分子下品牌竞争
                    </p>
                  </template>
                  <template v-if="hospitalCompete === '分子竞争'">
                    <!-- 分子竞争	系统自动匹配信息X%<Y% -->
                    <p class="action-text">
                      <span class="action-flag"></span>
                      该家医院艾司西酞普兰分子所占份额{{ parseInt(hospitalCompeteObj.Escitalopram_rating * 100) }}%&lt;该家医院来士普在艾司分子中的份额{{ parseInt(hospitalCompeteObj.Lexapro_Escitalopram_rating * 100) }}%
                    </p>
                    <p class="action-text">
                      <span class="action-flag"></span>
                      该医院市场机会除艾司西酞普兰分子下品牌竞争以外；也可考虑分子竞争，做艾司西酞普兰分子教育
                    </p>
                  </template>
                </div>
              </div>
            </div>
            <!-- 【合理用药型】医院：BA -->
            <div v-if="hospitalSelectedParting === 'BA'" class="proof-link">
              <h4>建议使用资料</h4>
              <p class="link">
                <span class="action-flag"></span>
                <a
                  @click="
                    lookDetail(
                      'LFile/nba_content/综合集采政策，个体化原则，合理使用抗抑郁药_1664346133000.pdf'
                    )
                  "
                >
                  综合集采政策+个体化原则，合理使用抗抑郁药CNCT-001945
                </a>
              </p>
            </div>
          </div>

          <!-- 医院分型 -->
          <div>
            <p class="parting-type-y-top">管控政策</p>
            <div class="parting-type">
              <div class="parting-type-y">
                <div class="parting-type-y-axis">
                  <div class="parting-type-y-left">
                    <p class="parting-type-y-text">宽松</p>
                    <p class="parting-type-y-text">严格</p>
                  </div>
                  <img src="@/assets/svg/yAxis.svg" alt="" />
                </div>
              </div>
              <!-- 【Digital助力型】医院：AB -->
              <div class="parting-type-cake">
                <div
                  :class="{
                    'parting-type-selected c-height': hospitalSelectedParting === 'AB',
                    'parting-type-unselected c-height': hospitalSelectedParting !== 'AB',
                  }"
                >
                  <div>
                    <img
                      v-if="hospitalSelectedParting === 'AB'"
                      :src="star"
                      alt=""
                      width="9"
                    />
                    <p>【Digital助力型】医院</p>
                  </div>
                </div>
                <div class="">
                  <!-- 【强化推广型】医院:BB -->
                  <div
                    :class="{
                      'parting-type-selected': hospitalSelectedParting === 'BB',
                      'parting-type-unselected': hospitalSelectedParting !== 'BB',
                    }"
                  >
                    <div>
                      <img
                        v-if="hospitalSelectedParting === 'BB'"
                        :src="star"
                        alt=""
                        width="9"
                      />
                      <p>【强化推广型】医院</p>
                    </div>
                  </div>
                  <!-- 【合理用药型】医院 -->
                  <div
                    :class="{
                      'parting-type-selected': hospitalSelectedParting === 'BA',
                      'parting-type-unselected': hospitalSelectedParting !== 'BA',
                    }"
                  >
                    <div>
                      <img
                        v-if="hospitalSelectedParting === 'BA'"
                        :src="star"
                        alt=""
                        width="9"
                      />
                      <p>【合理用药型】医院</p>
                    </div>
                  </div>
                </div>
                <!-- 暂不作为重点：AA -->
                <div
                  v-if="false"
                  :class="{
                    'parting-type-selected': hospitalSelectedParting === 'AA',
                    'parting-type-unselected': hospitalSelectedParting !== 'AA',
                  }"
                >
                  <div>
                    <img
                      v-if="hospitalSelectedParting === 'AA'"
                      :src="star"
                      alt=""
                      width="9"
                    />
                    <p>暂不作为重点</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img src="@/assets/svg/xAxis.svg" alt="" style="width:100%" />
              <div class="parting-type-x">
                <p class="parting-type-x-text">风险</p>
                <p class="parting-type-x-text">良好</p>
              </div>
              <p class="parting-type-x-end">中标产品完成进度</p>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px">
        <CommonHeader
          title="医生分型"
          icon-url="/lundbeck/NBA/physician.png"
          :no-feedback="true"
        >
          <div v-if="canEditStatus" slot="before">
            <img
              v-if="!isPhysicianFirst"
              src="@/assets/lundbeck/NBA/edit.png"
              alt=""
              width="16"
              style="margin-top: 6px"
              @click="openPhysicianDialog()"
            />
          </div>
        </CommonHeader>
        <div v-if="isPhysicianFirst">
          <p class="tip-text">请先回答问题来确认医生分型</p>
          <template >
            <van-button
              v-if="canEditStatus"
              class="tip-button"
              round
              type="info"
              @click="openPhysicianDialog()"
            >
              立即进行问答
            </van-button>
          </template>
        </div>
        <div v-else>
          <!-- 医生分型 -->
          <div>
            <div class="parting-type">
              <div class="parting-type-cake">
                <div
                  style="margin-right: 15px"
                  :class="
                    physicianSelectedParting === 'A'
                      ? 'parting-type-selected'
                      : 'parting-type-unselected'
                  "
                >
                  <div v-if="physicianSelectedParting === 'A'">
                    <img :src="star" alt="" width="9" />
                    <p>低认可医生</p>
                  </div>
                </div>
                <div
                  :class="
                    physicianSelectedParting === 'B'
                      ? 'parting-type-selected'
                      : 'parting-type-unselected'
                  "
                >
                  <div v-if="physicianSelectedParting === 'B'">
                    <img :src="star" alt="" width="9" />
                    <p>高认可医生</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img src="@/assets/svg/xAxis.svg" alt="" style="width: 100%;" />
              <div class="parting-type-physician-x">
                <p class="parting-type-x-text">不认可原研来士普</p>
                <p class="parting-type-x-text">认可原研来士普</p>
              </div>
            </div>
          </div>
          <!-- 医生分型历史 -->
          <div class="parting-type-history">
            <div class="history-title">
              <div class="title-flag"></div>
              <span class="title-text">分型历史</span>
            </div>
            <div class="history-content">
              <div
                v-for="item in showPhysicianPartingHistorys"
                :key="item.physician_typing_physician_id"
              >
                <div class="history-date">
                  <div class="date-flag"></div>
                  <span class="date-text">{{ getDate(item.create_time) }}</span>
                </div>
                <div class="history-text">
                  <p>{{ item.result }}</p>
                  <p class="modified-name">
                    {{
                      item.modify_user_name
                        ? item.modify_user_name
                        : item.modify_user_id
                    }}
                  </p>
                </div>
              </div>
              <div v-if="showExpand">
                <div
                  v-if="!physicianHistoryExpandStatus"
                  class="expend-btn"
                  @click="expendPhysicianHistory"
                >
                  <span class="arrow-down-flag"></span>
                  展开
                </div>
                <div v-else class="expend-btn" @click="expendPhysicianHistory">
                  <span class="arrow-up-flag"></span>
                  收起
                </div>
              </div>
            </div>
          </div>
          <!-- 医生行动建议-->
          <div class="parting-type-action">
            <CommonHeader
              title="下一步行动建议"
              icon-url="/lundbeck/NBA/light-bulb.png"
              :no-feedback="true"
            />
            <div v-if="false" class="action-title">
              <div class="title-flag"></div>
              <span class="title-text">行动建议</span>
            </div>
            <div v-if="false">
              <div class="action-plan">
                <!-- 高认可医生 -->
                <div v-if="physicianSelectedParting === 'B'">
                  <p class="action-text">
                    <span class="action-flag"></span>
                    满足安全感：及时沟通目前医院艾司西酞普兰VBP目标完成进度。若进度良好，提醒可放心处方来士普；若进度风险，提醒需完成目标（合理处方其他原研药）
                  </p>
                  <p class="action-text">
                    <span class="action-flag"></span>
                    情感联结：积极拜访、客情维护；情感联结相关活动可邀请其作为讲者，为来士普发声
                  </p>
                  <p v-if="hospitalCompete === '分子竞争'" class="action-text">
                    <span class="action-flag"></span>
                    分子教育：可邀请其作为RTD的讲者，传达艾司西酞普兰在抑郁伴焦虑方面区隔于帕罗西汀的优势
                  </p>
                </div>

                <!-- 低认可医生 -->
                <div v-if="physicianSelectedParting === 'A'">
                  <p class="action-text">
                    <span class="action-flag"></span>
                    满足安全感：及时沟通目前医院艾司西酞普兰VBP目标完成进度。若进度良好，提醒可放心处方来士普；若进度风险，提醒需完成目标（合理处方其他原研药）
                  </p>
                  <p class="action-text">
                    <span class="action-flag"></span>
                    情感联结：可邀请其作为情感联结相关活动的听众，增强其参与感
                  </p>
                  <p v-if="hospitalCompete === '分子竞争'" class="action-text">
                    <span class="action-flag"></span>
                    分子教育：日常拜访中需传递艾司西酞普兰在抑郁伴焦虑方面区隔于帕罗西汀的优势
                  </p>
                </div>

              </div>
            </div>
            <div style="height: 10px"></div>
            <div class="action-title">
              <div class="title-flag"></div>
              <span class="title-text">内容推荐</span>
            </div>
            <div class="proof-link">
              <!-- <h4>情感联结：对应【来士普上市16周年主题活动】相关资料</h4> -->
              <p class="link">
                <span class="action-flag"></span>
                <a
                  @click="
                    lookDetail(
                      'LFile/nba_content/【来士普致敬视频】16年经典相伴，致敬特别的你CNCT-002071_1663939876000.mp4'
                    )
                  "
                >
                  【来士普致敬视频】16年经典相伴，致敬特别的你CNCT-002071
                </a>
              </p>
              <p class="link">
                <span class="action-flag"></span>
                <a
                  @click="
                    lookDetail(
                      'LFile/nba_content/来士普上市16周年庆典幻灯CNCT-002064_1663939926000.pdf'
                    )
                  "
                >
                  来士普上市16周年庆典幻灯CNCT-002064 RTD
                </a>
              </p>
              <p class="link">
                <span class="action-flag"></span>
                <a
                  @click="
                    lookDetail(
                      'LFile/nba_content/艾司西酞普兰治疗抑郁伴焦虑症状-0519_20220826102838_1663939911000.pdf'
                    )
                  "
                >
                  艾司西酞普兰治疗抑郁伴焦虑症状CNCT-001801
                </a>
              </p>
              <!-- <p class="link">
                <span class="action-flag"></span>
                <a
                  @click="
                    lookDetail(
                      'LFile/nba_content/对于卒中后抑郁的新认识CNCT-001677.pdf'
                    )
                  "
                >
                  对于卒中后抑郁的新认识CNCT-001677
                </a>
              </p> -->
            </div>

          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- 医院划分问答 -->
      <van-dialog
        v-model="hospitalDialogShow"
        title="医院划分问答"
        theme="round-button"
        confirm-button-color="#aa341b"
        :before-close="beforeHospitalClose"
      >
        <div class="diglog-close" @click="closeDiglog()">×</div>
        <div>
          <div class="diglog-questions">
          <div class="question">
            <p class="question-text">
              1. 预估当前艾司西酞普兰目标完成进度
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="hospitalQuestionAnswers.firstAnswer"
              direction="horizontal"
              class="answer"
            >
              <van-radio name="A" style="margin-right: 30%">良好</van-radio>
              <van-radio name="B">风险</van-radio>
            </van-radio-group>
            <p v-if="hospitalFirstAnswerTips" style="color: #cc4b47">
              这是必答题，请选择答案！
            </p>
          </div>
          <div class="question">
            <p class="question-text">
              2. 当前对于来士普的管控状态 <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="hospitalQuestionAnswers.secondAnswer"
              direction="vertical"
              @change="hospitalQuestionAnswers.thirdAnswer = null"
            >
              <van-radio name="A" class="answer-checkbox">
                停药中/未列名/被剔除
              </van-radio>
              <van-radio name="B" class="answer-checkbox">
                限制数量或金额
              </van-radio>
              <van-radio name="C" class="answer-checkbox">
                限制科室或医生
              </van-radio>
              <van-radio name="D" class="answer-checkbox">
                限制金额比例
              </van-radio>
              <van-radio name="E" class="answer-checkbox">
                限制天数（可处方天数）
              </van-radio>
              <van-radio name="F" class="answer-checkbox">
                限制数量比例
              </van-radio>
              <van-radio name="G" class="answer-checkbox"> 无限制 </van-radio>
            </van-radio-group>
            <p v-if="hospitalSecondAnswerTips" style="color: #cc4b47">
              这是必答题，请选择答案！
            </p>
          </div>
          <div
            v-if="hospitalQuestionAnswers.secondAnswer === 'B'"
            class="question"
          >
            <p class="question-text">
              2-B.限制数量或金额
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="hospitalQuestionAnswers.thirdAnswer"
              direction="vertical"
            >
              <van-radio name="A" class="answer-checkbox">
                1件（162盒）及以下
              </van-radio>
              <van-radio name="B" class="answer-checkbox"> 1-3件 </van-radio>
              <van-radio name="C" class="answer-checkbox">
                3件（486盒）及以上
              </van-radio>
            </van-radio-group>
            <p v-if="hospitalThirdAnswerTips" style="color: #cc4b47">
              这是必答题，请选择答案！
            </p>
          </div>
          <div
            v-if="hospitalQuestionAnswers.secondAnswer === 'C'"
            direction="vertical"
            class="question"
          >
            <p class="question-text">
              2-C.限制科室或医生
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="hospitalQuestionAnswers.thirdAnswer"
              direction="vertical"
            >
              <van-radio name="A" class="answer-checkbox">限制核心科室或医生（不可处方）</van-radio>
              <van-radio name="B" class="answer-checkbox">限制非核心科室或医生（不可处方）</van-radio>
            </van-radio-group>
            <p v-if="hospitalThirdAnswerTips" style="color: #cc4b47">
              这是必答题，请选择答案！
            </p>
          </div>
          <div
            v-if="hospitalQuestionAnswers.secondAnswer === 'D'"
            direction="vertical"
            class="question"
          >
            <p class="question-text">
              2-D.限制金额比例
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="hospitalQuestionAnswers.thirdAnswer"
              direction="vertical"
            >
              <van-radio name="A" class="answer-checkbox">9:1 </van-radio>
              <van-radio name="B" class="answer-checkbox">8:2</van-radio>
              <van-radio name="C" class="answer-checkbox">
                7:3及更高比例
              </van-radio>
            </van-radio-group>
            <p v-if="hospitalThirdAnswerTips" style="color: #cc4b47">
              这是必答题，请选择答案！
            </p>
          </div>
          <div
            v-if="hospitalQuestionAnswers.secondAnswer === 'E'"
            class="question"
          >
            <p class="question-text">
              2-E.限制天数（可处方天数）
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="hospitalQuestionAnswers.thirdAnswer"
              direction="vertical"
            >
              <van-radio name="A" class="answer-checkbox">
                1周及以下
              </van-radio>
              <van-radio name="B" class="answer-checkbox">1周-2周 </van-radio>
              <van-radio name="C" class="answer-checkbox">2周及以上</van-radio>
            </van-radio-group>
            <p v-if="hospitalThirdAnswerTips" style="color: #cc4b47">
              这是必答题，请选择答案！
            </p>
          </div>
          <div
            v-if="hospitalQuestionAnswers.secondAnswer === 'F'"
            class="question"
          >
            <p class="question-text">
              2-F.限制数量比例
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="hospitalQuestionAnswers.thirdAnswer"
              direction="vertical"
            >
              <van-radio name="A" class="answer-checkbox">9:1 </van-radio>
              <van-radio name="B" class="answer-checkbox">8:2</van-radio>
              <van-radio name="C" class="answer-checkbox">
                7:3及更高比例
              </van-radio>
            </van-radio-group>
            <p v-if="hospitalThirdAnswerTips" style="color: #cc4b47">
              这是必答题，请选择答案！
            </p>
          </div>
        </div>
        </div>
      </van-dialog>
      <!-- 医生问答 -->
      <van-dialog
        v-model="physicianDialogShow"
        title="医生分型问答"
        theme="round-button"
        confirm-button-color="#aa341b"
        :before-close="beforePhysicianClose"
      >
        <div class="diglog-close" @click="closeDiglog()">×</div>
        <div class="diglog-questions">
          <div class="question">
            <p class="question-text">
              1. 该医生是否认可原研来士普优于其他艾司西酞普兰仿制药？
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="physicianQuestionAnswers.firstAnswer"
              direction="horizontal"
              class="answer"
            >
              <van-radio name="A" style="margin-right: 30%">认可</van-radio>
              <van-radio name="B"> 不认可 </van-radio>
            </van-radio-group>
            <p v-if="physicianFirstAnswerTips" style="color: #cc4b47">
              这是必答题，请选择答案！
            </p>
          </div>
          <div class="question">
            <p class="question-text">
              2.
              该医生在哪些情景下，会优先处方来士普，而非其他艾司西酞普兰仿制药？
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group v-model="physicianQuestionAnswers.secondAnswer">
              <van-radio name="A" class="answer-checkbox">
                较少数情景（仅患者有支付能力、患者主动表达想用好药）
              </van-radio>
              <van-radio name="B" class="answer-checkbox">
                较多数情景
              </van-radio>
            </van-radio-group>
            <p v-if="physicianSecondAnswerTips" style="color: #cc4b47">
              这是必答题，请选择答案！
            </p>
          </div>
        </div>
      </van-dialog>
    </div>
    <swipeDialog v-if="detailStatus" backtxt="返回NBA" title="详情" @closeFn="closeFn">
      <!-- <iframe v-if="pdfLink" :src="pdfLink" frameborder="0"></iframe> -->
      <van-loading v-if="fileLoading" class="loading">加载中...</van-loading>
      <pdfComponent v-if="pdfLink" :url="pdfLink" :pdf-key="pdfKey" />
      <!-- <div v-if="!fileLoading">
        <a :href="pdfLink" download>
          <img class="download" src="@/assets/nba/down.png" width="20" alt />
        </a>
        <pdf v-for="i in numPages" :key="i" :src="pdfLink" :page="i" />
      </div> -->
      <video-player
        v-if="playerOptions.sources[0].src"
        ref="videoPlayer"
        class="vedio"
        :playsinline="true"
        :options="playerOptions"
      />
    </swipeDialog>
  </div>
</template>

<script>
import CommonHeader from "@/components/CommonHeader";
import * as moment from "moment";
import * as py from "@/api/Physicians";
import {
  Button,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  Field,
  Loading,
} from "vant";
import { getLocalStorage } from "@/utils";
import { setTimeout } from "timers";
import pdf from "vue-pdf";
import swipeDialog from "@/views/layout/swipeDialog";
import { s3Service } from "@/utils/aws/index";
import pdfComponent from "../pdfComponent.vue";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import { getMimeTypeBySuffix } from "@/utils/index";
export default {
  name: "Lexapro",
  components: {
    CommonHeader,
    vanButton: Button,
    vanRadioGroup: RadioGroup,
    vanRadio: Radio,
    // vanCheckbox: Checkbox,
    // vanCheckboxGroup: CheckboxGroup,
    // vanField: Field,
    // pdf,
    swipeDialog,
    vanLoading: Loading,
    pdfComponent,
    videoPlayer,
  },
  props: {
    productId: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      loading: false,
      isHospitalFirst: true,
      isPhysicianFirst: true,
      hospitalDialogShow: false,
      physicianDialogShow: false,
      hospitalPartings: [],
      physicianPartings: [],
      hospitalSelectedParting: "",
      hospitalCompete: "",
      hospitalCompeteObj: {},
      physicianSelectedParting: "",
      hospitalQuestionAnswers: {
        firstAnswer: null,
        secondAnswer: [],
        thirdAnswer: null,
      },
      hospitalFirstAnswerTips: false,
      hospitalSecondAnswerTips: false,
      hospitalThirdAnswerTips: false,
      physicianQuestionAnswers: {
        firstAnswer: null,
        secondAnswer: null,
      },
      physicianFirstAnswerTips: false,
      physicianSecondAnswerTips: false,
      showPhysicianPartingHistorys: [],
      showHospitalPartingHistorys: [],
      showExpand: false,
      showHospitalExpand: false,
      physicianHistoryExpandStatus: false,
      hospitalHistoryExpandStatus: false,
      isKeyCustomer: true,
      hospitalActionShow: true, // 销售不能看到修改为销售也能看
      star: require("@/assets/lundbeck/star.png"),
      keyCustomer: require("@/assets/lundbeck/key_customer.png"),
      noKeyCustomer: require("@/assets/lundbeck/no_key_customer.png"),
      numPages: 0,
      pdfLink: "",
      pdfKey: "",
      detailStatus: false,
      fileLoading: false,
      vedioLink: "",
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: false,
        muted: false,
        loop: false,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "15:6",
        fluid: true,
        sources: [
          {
            type: "",
            src: "",
          },
        ],
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true,
        },
      },
      canEditStatus: false,
      ajaxStatus: false
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  mounted() {
    // MR是代表，DM是地区经理
    if (getLocalStorage('territory_type') === 'MR') {
      this.canEditStatus = true;
    }
    this.getPhysicianTyping();
    this.getInstitutionTyping();
    if (
      getLocalStorage("territory_type") &&
      getLocalStorage("territory_type") !== "MR"
    ) {
      this.hospitalActionShow = true;
    }
  },
  methods: {
    getPhysicianTyping() {
      let params = {
        physician_id: this.$route.query.physician_id,
        product_id: this.productId,
        territory_id: getLocalStorage("territory_id"),
      };
      py.getPhysicianTyping(params).then((res) => {
        if (res && res.value.length) {
          this.physicianPartings = res.value;
          if (this.physicianPartings.length > 0) {
            this.isPhysicianFirst = false;
            this.physicianQuestionAnswers = JSON.parse(
              this.physicianPartings[0].content
            );
            if (
              !this.physicianHistoryExpandStatus &&
              this.physicianPartings.length > 2
            ) {
              this.showPhysicianPartingHistorys = this.physicianPartings.slice(
                0,
                2
              );
              this.showExpand = true;
            } else {
              this.showPhysicianPartingHistorys = this.physicianPartings;
              this.showExpand = false;
            }
            this.getPhysicianPartingResult();
          } else {
            this.isPhysicianFirst = true;
          }
        }
      });
    },
    getInstitutionTyping() {
      let params = {
        institution_id: getLocalStorage("institution_id"),
        product_id: this.productId,
        territory_id: getLocalStorage("territory_id"),
      };
      py.getInstitutionTyping(params).then((res) => {
        if (res && res.value.length) {
          this.hospitalPartings = res.value;
          if (this.hospitalPartings.length > 0) {
            this.isHospitalFirst = false;
            this.hospitalQuestionAnswers = JSON.parse(
              this.hospitalPartings[0].content
            );
            if (
              !this.hospitalHistoryExpandStatus &&
              this.hospitalPartings.length > 2
            ) {
              this.showHospitalPartingHistorys = this.hospitalPartings.slice(
                0,
                2
              );
              this.showHospitalExpand = true;
            } else {
              this.showHospitalPartingHistorys = this.hospitalPartings;
              this.showHospitalExpand = false;
            }
            this.getHospitalPartingResult();
          } else {
            this.isHospitalFirst = true;
          }
          this.getInstitutionCpa();
        }
      });
    },
    getInstitutionCpa() {
      if (getLocalStorage("institution_id")) {
        this.hospitalCompeteObj = {};
        py.getInstitutionCpa(getLocalStorage("institution_id")).then((res) => {
          if (res && res.value.length) {
            this.hospitalCompeteObj = res.value[0];
            this.hospitalCompete =
              res.value[0].Escitalopram_rating >=
              res.value[0].Lexapro_Escitalopram_rating
                ? "品牌竞争"
                : "分子竞争";
          }
        });
      }
    },
    openHospitalDialog() {
      this.hospitalFirstAnswerTips = false;
      this.hospitalSecondAnswerTips = false;
      if (this.isHospitalFirst) {
        this.hospitalQuestionAnswers = {
          firstAnswer: null,
          secondAnswer: [],
          thirdAnswer: {
            answer1: null,
            answer2: null,
            answer3: null,
            answer4: null,
            answer5: null,
            answer6: null,
          },
        };
      }
      if (this.hospitalPartings.length) {
        this.hospitalQuestionAnswers = JSON.parse(
          this.hospitalPartings[0].content
        );
      }
      this.hospitalDialogShow = true;
    },
    getHospitalPartingResult() {
      let secondAnswerString =
        this.hospitalQuestionAnswers.secondAnswer.toString();
      let result = "";
      if (this.hospitalQuestionAnswers.firstAnswer === "A") {
        if (
          secondAnswerString.indexOf("A") > -1 ||
          secondAnswerString.indexOf("B") > -1 ||
          secondAnswerString.indexOf("C") > -1 ||
          secondAnswerString.indexOf("D") > -1
        ) {
          result = "【合理用药型】医院";
          this.hospitalSelectedParting = "BA";
        } else {
          result = "【强化推广型】医院";
          this.hospitalSelectedParting = "BB";
        }
      } else {
        result = "【Digital助力型】医院";
        // if (
        //   secondAnswerString.indexOf("A") > -1 ||
        //   secondAnswerString.indexOf("B") > -1 ||
        //   secondAnswerString.indexOf("C") > -1 ||
        //   secondAnswerString.indexOf("D") > -1
        // ) {
        //   // result = "暂不作为重点";
        //   this.hospitalSelectedParting = "AA";
        // } else {
        // }
        this.hospitalSelectedParting = "AB";
      }
      return result;
    },
    beforeHospitalClose(action, done) {
      if (action === "confirm") {
        if (this.ajaxStatus) {
          return;
        }
        if (this.hospitalQuestionAnswers.firstAnswer == null) {
          this.hospitalFirstAnswerTips = true;
          done(false);
          return;
        }
        if (this.hospitalQuestionAnswers.secondAnswer == null) {
          this.hospitalSecondAnswerTips = true;
          done(false);
          return;
        }
        if (
          this.hospitalQuestionAnswers.thirdAnswer == null &&
          this.hospitalQuestionAnswers.secondAnswer !== "A" &&
          this.hospitalQuestionAnswers.secondAnswer !== "G"
        ) {
          this.hospitalThirdAnswerTips = true;
          done(false);
          return;
        }

        let params = {
          institution_id: getLocalStorage("institution_id"),
          content: JSON.stringify(this.hospitalQuestionAnswers),
          user_id: getLocalStorage("user_id"),
          territory_id: getLocalStorage("territory_id"),
          create_time: Date.parse(new Date()),
          product_id: this.productId,
          result: this.getHospitalPartingResult(),
          modify_user_id: getLocalStorage("user_id"),
          modify_user_name: getLocalStorage("user_name"),
        };
        this.ajaxStatus = true;
        py.setInstitutionTyping(params).then((res) => {
          if (res.is_success) {
            this.$toast.success("提交成功");
            setTimeout(() => {
              done();
              this.getInstitutionTyping();
            }, 2);
          } else {
            this.$toast.success("提交失败，请重试");
            done(false);
          }
          this.ajaxStatus = false;
        });
      } else {
        done();
      }
    },
    beforePhysicianClose(action, done) {
      if (action === "confirm") {
        if (this.ajaxStatus) {
          return;
        }
        if (this.physicianQuestionAnswers.firstAnswer == null) {
          this.physicianFirstAnswerTips = true;
          done(false);
          return;
        }
        if (this.physicianQuestionAnswers.secondAnswer == null) {
          this.physicianSecondAnswerTips = true;
          done(false);
          return;
        }
        let uName = getLocalStorage("user_name");
        let params = {
          physician_id: this.$route.query.physician_id,
          content: JSON.stringify(this.physicianQuestionAnswers),
          user_id: getLocalStorage("user_id"),
          territory_id: getLocalStorage("territory_id"),
          create_time: Date.parse(new Date()),
          product_id: this.productId,
          result: this.getPhysicianPartingResult(),
          modify_user_id: getLocalStorage("user_id"),
          modify_user_name: uName !== 'null' ? uName : getLocalStorage("user_id"),
        };
        this.ajaxStatus = true;
        py.setPhysicianTyping(params).then((res) => {
          if (res.is_success) {
            this.$toast.success("提交成功");
            setTimeout(() => {
              done();
              this.getPhysicianTyping();
            }, 2);
          } else {
            this.$toast.success("提交失败，请重试");
            done(false);
          }
          this.ajaxStatus = false;
        });
      } else {
        done();
      }
    },
    openPhysicianDialog() {
      this.physicianFirstAnswerTips = false;
      this.physicianSecondAnswerTips = false;
      if (this.isPhysicianFirst) {
        this.physicianQuestionAnswers = {
          firstAnswer: null,
          secondAnswer: null,
        };
      }
      if (this.physicianPartings.length > 0) {
        this.physicianQuestionAnswers = JSON.parse(
          this.physicianPartings[0].content
        );
      }
      this.physicianDialogShow = true;
    },
    getPhysicianPartingResult() {
      let result = "";
      if (
        this.physicianQuestionAnswers.firstAnswer === "A" &&
        this.physicianQuestionAnswers.secondAnswer === "B"
      ) {
        result = "高认可医生";
        this.physicianSelectedParting = "B";
        this.isKeyCustomer = true;
      } else {
        result = "低认可医生";
        this.physicianSelectedParting = "A";
        this.isKeyCustomer = false;
      }
      return result;
    },
    closeDiglog() {
      this.hospitalDialogShow = false;
      this.physicianDialogShow = false;
    },
    getDate(date) {
      return moment(new Date(+date)).format("YYYY-MM-DD");
    },
    expendPhysicianHistory() {
      if (
        this.physicianHistoryExpandStatus &&
        this.physicianPartings.length > 2
      ) {
        this.showPhysicianPartingHistorys = this.physicianPartings.slice(0, 2);
      } else {
        this.showPhysicianPartingHistorys = this.physicianPartings;
      }
      this.physicianHistoryExpandStatus = !this.physicianHistoryExpandStatus;
    },
    expendHospitalHistory() {
      if (
        this.hospitalHistoryExpandStatus &&
        this.hospitalPartings.length > 2
      ) {
        this.showHospitalPartingHistorys = this.hospitalPartings.slice(0, 2);
      } else {
        this.showHospitalPartingHistorys = this.hospitalPartings;
      }
      this.hospitalHistoryExpandStatus = !this.hospitalHistoryExpandStatus;
    },
    lookDetail(key) {
      this.$store.dispatch("setDialogStatus", true);
      this.detailStatus = true;
      this.fileLoading = true;
      if (this.detailStatus) {
        this.getPictureUrl(key);
      }
    },
    closeFn() {
      this.$store.dispatch("setDialogStatus", false);
      this.detailStatus = false;
      this.pdfLink = null;
      this.pdfKey = null;
      this.playerOptions.sources[0].src = "";
    },
    getPictureUrl(key) {
      py.getS3ByKey(key).then((res) => {
        let currentMimeType = getMimeTypeBySuffix(
          key.split(".").pop().toLowerCase()
        );
        let blob = new Blob([res.data], {
          type: currentMimeType,
        });
        if (key.indexOf("mp4") > -1) {
          this.blobToBase64(blob).then((res) => {
            this.playerOptions.sources[0].type = currentMimeType;
            this.playerOptions.sources[0].src = res;
            this.fileLoading = false;
          });
        } else {
          this.pdfKey = key;
          this.pdfLink = window.URL.createObjectURL(blob);
          this.playerOptions.sources[0].src = "";
          this.fileLoading = false;
        }
        // if (this.pdfLink) {
        //   this.getNumPages(this.pdfLink);
        // }
      });
    },
    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          resolve(e.target.result);
        };
        // readAsDataURL
        fileReader.readAsDataURL(blob);
        fileReader.onerror = () => {
          reject(new Error("文件流异常"));
        };
      });
    },
  },
};
</script>

<style lang="scss">
.video-player {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.video-js .vjs-big-play-button {
  border-width: 3px;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  line-height: 50px;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.action-flag {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #aa341b;
  opacity: 1;
  border-radius: 4px;
  margin: 0 2px 2px 0;
}
.action-text {
  font-size: 14px;
  font-family: Arial;
  font-weight: 400;
  color: #2c3034;
  opacity: 1;
  text-align: left;
  line-height: 1.2;
  margin-bottom: 15px;
}
.proof-link {
  /* background: #f5f7f8; */
  border-radius: 4px;
  padding: 10px 20px;
  margin-bottom: 20px;
  .h4-style {
    font-size: 14px;
    font-family: Arial;
    font-weight: bold;
    color: #2c3034;
    margin: 10px 0 20px;
    padding: 0;
  }
  .link {
    font-size: 14px;
    font-family: Arial;
    font-weight: 400;
    color: #aa341b;
    text-align: left;
    line-height: 1.2;
    margin-bottom: 10px;
  }
}
.parting-type-history {
  margin-top: 10px;
  .history-title {
    display: flex;
    align-items: baseline;
    height: 20px;
  }
  .action-title {
    display: flex;
    align-items: baseline;
  }
  .history-date {
    display: flex;
    align-items: center;
    .date-text {
      height: 16px;
      font-size: 14px;
      font-family: Arial;
      font-weight: 700;

      color: #2c3034;
      opacity: 1;
      margin-left: 5px;
      margin-top: 5px;
    }
  }
  .date-flag {
    width: 14px;
    height: 14px;
    background: #ffffff;
    border: 1px solid #aa341b;
    border-radius: 50%;
    opacity: 1;
  }
  .history-content {
    padding: 10px;
  }
  .history-text {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: 1px dashed #dce0e6;
    font-size: 14px;
    font-family: Arial;
    font-weight: 400;

    color: #2c3034;
    margin-bottom: 5px;
    margin-left: 7px;
    p {
      margin-left: 10px;
    }
    .modified-name {
      font-size: 12px;
      color: #838a95;
    }
  }
  .expend-btn {
    color: #aa341b;
    border-bottom: 1px solid #aa341b;
    width: 50px;
    padding-bottom: 3px;
    margin-left: 5px;
  }
  .arrow-down-flag {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-top: 1px solid #aa341b;
    border-left: 1px solid #aa341b;
    transform: rotate(225deg);
    margin-right: 5px;
    margin-bottom: 2px;
  }
  .arrow-up-flag {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-top: 1px solid #aa341b;
    border-left: 1px solid #aa341b;
    transform: rotate(45deg);
    margin-right: 5px;
    margin-bottom: -2px;
  }
}
</style>

<style lang="scss" scoped>
.parting {
  .tip-text {
    font-size: 14px;
    font-family: Arial;
    font-weight: 400;
    color: #2c3034;
    opacity: 1;
    margin: 40px auto 20px;
  }
  .tip-button {
    width: 160px;
    height: 40px;
    background: #aa341b;
    border: 1px solid #aa341b;
    opacity: 1;
    border-radius: 500px;
    margin-bottom: 20px;
  }
  .diglog-close {
    position: absolute;
    font-size: 30px;
    top: 25px;
    right: 20px;
  }
  .diglog-questions {
    max-height: 400px;
    overflow-y: auto;
    padding: 20px 20px 0 20px;
    .question {
      margin-bottom: 20px;
      .question-text {
        font-size: 14px;
        font-family: Arial;
        font-weight: 700;
        line-height: 1;
        color: #2c3034;
        opacity: 1;
        text-align: left;
        margin-bottom: 10px;
      }
      .answer {
        display: flex;
        justify-content: center;
      }
      .answer-checkbox {
        margin: 5px;
        ::v-deep .van-radio__label {
          text-align: left;
        }
      }
    }
  }
  .parting-type-y-top {
    height: 14px;
    font-size: 10px;
    font-family: Arial;
    font-weight: 400;

    color: #aa341b;
    opacity: 1;
    text-align: left;
    margin-top: 20px;
  }
  .parting-type {
    display: flex;
    align-items: center;
    justify-content: center;
    .parting-type-cake {
      display: flex;
      flex-wrap: wrap;
      .parting-type-unselected {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 124px;
        height: 56px;
        background: linear-gradient(46deg, #f8f6f5 0%, #f3e6e3 100%);
        opacity: 1;
        border-radius: 4px;
        margin: 3px;
        &.c-height {
          height: 115px;
        }
        p {
          line-height: 1.5;
          font-size: 10px;
          color: #2c3034;
        }
      }
      .parting-type-selected {
        width: 124px;
        height: 56px;
        background: linear-gradient(46deg, #aa341b 0%, #aa341b 100%);
        opacity: 1;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 3px;
        &.c-height {
          height: 115px;
        }
        p {
          line-height: 1.5;
          font-size: 10px;
          color: #ffffff;
        }
      }
    }
    .parting-type-y {
      margin-right: 5px;
      .parting-type-y-axis {
        display: flex;
        .parting-type-y-text {
          width: 18px;
          height: 28px;
          font-size: 10px;
          font-family: Arial;
          font-weight: 400;
          line-height: 14px;
          color: #838a95;
          opacity: 1;
          margin-top: 30px;
        }
      }
    }
  }
  .parting-type-x {
    display: flex;
    justify-content: center;
    width: 90%;
    .parting-type-x-text {
      height: 14px;
      font-size: 10px;
      font-family: Arial;
      font-weight: 400;

      color: #838a95;
      opacity: 1;
      margin: 5px 55px;
    }
  }
  .parting-type-physician-x {
    display: flex;
    justify-content: space-around;
    .parting-type-x-text {
      height: 14px;
      font-size: 10px;
      font-family: Arial;
      font-weight: 400;
      color: #838a95;
    }
  }
  .parting-type-x-end {
    height: 14px;
    font-size: 10px;
    font-family: Arial;
    font-weight: 400;

    color: #aa341b;
    opacity: 1;
    float: right;
    margin-top: -20px;
    margin-right: -10px;
  }
  .parting-type-action {
    margin-top: 10px;
    .action-title {
      display: flex;
      align-items: baseline;
      height: 20px;
    }
    .action-plan {
      padding: 20px 10px;
    }
    .action-progress {
      display: flex;
      align-items: center;
      height: 30px;
      background: rgba(236,233,223,.5);
      border-radius: 4px;
      margin-bottom: 20px;
      p {
        color: #aa341b;
        font-size: 14px;
        font-family: Arial;
        font-weight: 700;
        margin-left: 10px;
      }
    }
  }

  .title-flag {
    width: 6px;
    height: 14px;
    background: #aa341b;
    opacity: 1;
    border-radius: 4px;
  }
  .title-text {
    height: 22px;
    font-size: 16px;
    font-family: Arial;
    font-weight: 700;
    color: #2c3034;
    opacity: 1;
    margin-left: 5px;
  }
  .key-customers {
    width: 70px;
    height: 20px;
    background: rgba(239, 156, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }
  .key-customers-text {
    height: 14px;
    font-size: 10px;
    font-family: Arial;
    font-weight: 700;
    color: #ef9c00;
    margin: 5px 0 0 5px;
  }
  .no-key-customers {
    width: 100px;
    height: 20px;
    background: rgba(135, 190, 204, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }
  .no-key-customers-text {
    height: 14px;
    font-size: 10px;
    font-family: Arial;
    font-weight: 700;
    color: #4fb0c9;
    margin: 5px 0 0 5px;
  }
  .loading {
    margin-top: 55%;
  }
  .download {
    z-index: 1;
    position: absolute;
    top: 330px;
    right: 45px;
    border: 1px solid rgb(218, 217, 217);
    border-radius: 100%;
    padding: 5px;
  }
  .proof-link {
    h4 {
      font-size: 14px;
      font-family: Arial;
      font-weight: bold;
      color: #2c3034;
      margin: 10px 0 20px;
      padding: 0;
    }
  }
}
.t-left {
  text-align: left;
}
</style>
