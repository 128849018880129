<template>
  <div>
    <van-tabs v-model="activeTab" color="#aa341b">
      <van-tab title="待审批">
        <van-loading
          v-if="loading"
          style="margin-top: 80px; margin-bottom: 40px"
          type="spinner"
          color="#aa341b"
        />
        <van-list
          v-model="loading"
          :finished="finished"
          class="approval-list"
          @load="loadPendingApprovals"
        >
          <van-cell
            v-for="item in pendingApprovals"
            :key="item.id"
            :title="item.title"
            :label="`${getStatusLabel(item.status)} | ${item.submit_user_name} 申请于 ${item.submit_time?.substr(0, 10)} `"
            :class="{'highlight': item.status === 2}"
          >
            <template #title>
              <span :style="{ color: item.status === 2 ? '#aa341b' : 'inherit', fontWeight: item.status === 2 ? 'bold' : 'normal' }">{{ item.title }}</span>
            </template>
            <template #label>
              <van-icon v-if="item.status === 2" name="warning-o" color="#aa341b" />
              <span :style="{ color: getStatusColor(item.status), fontWeight: item.status === 2 ? 'bold' : 'normal' }">{{ getStatusLabel(item.status) }}</span>
              <br /> {{ item.submit_user_name }} 申请于 {{ item.submit_time?.substr(0, 10) }}
            </template>
            <template #right-icon>
              <van-button type="primary" size="small" @click="goToDetail(item)">详情</van-button>
            </template>
          </van-cell>
        </van-list>
        <van-empty v-if="!loading && !pendingApprovals?.length" description="暂无内容" />
      </van-tab>
      <van-tab title="已申请">
        <van-loading
          v-if="loadingMy"
          style="margin-top: 80px; margin-bottom: 40px"
          type="spinner"
          color="#aa341b"
        />
        <van-list
          v-model="loadingMy"
          :finished="finishedMy"
          class="approval-list"
          @load="loadSubmittedApprovals"
        >
          <van-cell
            v-for="item in submittedApprovals"
            :key="item.id"
            :title="item.title"
            :label="`${getStatusLabel(item.status)} | ${item.submit_user_name} 申请于 ${item.submit_time?.substr(0, 10)} `"
            :class="{'highlight': item.status === 2}"
          >
            <template #title>
              <span>{{ item.title }}</span>
            </template>
            <template #label>
              <span :style="{ color: getStatusColor(item.status), fontWeight: item.status === 2 ? 'bold' : 'normal' }">{{ getStatusLabel(item.status) }}</span>
              <br /> {{ item.submit_user_name }} 申请于 {{ item.submit_time?.substr(0, 10) }}
            </template>
            <template #right-icon>
              <van-button type="primary" size="small" @click="goToDetail(item)">详情</van-button>
            </template>
          </van-cell>
        </van-list>
        <van-empty v-if="!loadingMy && !submittedApprovals?.length" description="暂无内容" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { getPendingApprovals, getSubmittedApprovals } from '@/api/approval';
import {Button, Tabs, Tab, List, Cell, Loading, Empty, Icon} from 'vant';
import {
  getLocalStorage,
} from '@/utils/index';

export default {
  components: {
    vanButton: Button,
    vanTabs: Tabs,
    vanTab: Tab,
    vanList: List,
    vanCell: Cell,
    vanLoading: Loading,
    vanEmpty: Empty,
    vanIcon: Icon,
  },
  data() {
    return {
      activeTab: 0,
      loading: false,
      finished: false,
      loadingMy: false,
      finishedMy: false,
      pendingApprovals: [],
      submittedApprovals: [],
    };
  },
  methods: {
    loadPendingApprovals() {
      this.loading = true;
      getPendingApprovals(getLocalStorage('user_id')).then((res) => {
        this.pendingApprovals = res.value;
        this.loading = false;
        this.finished = true;
      });
    },
    loadSubmittedApprovals() {
      this.loadingMy = true;
      getSubmittedApprovals(getLocalStorage('user_id')).then((res) => {
        this.submittedApprovals = res.value;
        this.loadingMy = false;
        this.finishedMy = true;
      });
    },
    goToDetail(item) {
      if (item.approval_type === 'unbind_weixin' || item.approval_type === 'unsign_consent') {
        this.$router.push({ path: `/hcp/detail`, query: { physician_id: item.source_id, selectedLabel: 'wechat' } });
      }
    },
    getStatusLabel(status) {
      switch (status) {
        case 2:
          return '待审批';
        case 0:
          return '拒绝';
        case 1:
          return '批准';
        case -1:
          return '驳回';
        default:
          return '未知状态';
      }
    },
    getStatusColor(status) {
      switch (status) {
        case 2:
          return '#aa341b';
        case 0:
          return '#000';
        case 1:
          return 'green';
        case -1:
          return 'orange';
        default:
          return 'black';
      }
    },
  },
};
</script>

<style scoped>
.approval-list {
  text-align: left;
  margin-top: 5px;
}
.highlight {
  font-weight: bold;
}
</style>
