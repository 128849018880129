<template>
  <div class="bind-success">
    <div class="banner">
      <img src="@/assets/lundbeck/Success.jpg" alt="success" />
      <div class="success-title">验证完成</div>
    </div>

    <img class="logo" src="@/assets/lundbeck/logo.png" alt="Lundbeck Logo" />
    <div class="success-text">
      您已成为灵北的伙伴，<br />
      感谢您对于我们的信任。
    </div>
    <button class="close-btn" @click="handleClose">关闭本页面</button>
  </div>
</template>

<script>
export default {
  name: 'BindSuccess',
  methods: {
    handleClose() {
      window.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.bind-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  .banner {
    background-color: #b92505;
    width: 100%;
    height: 300px;
    text-align: center;
  }
  img {
    width: 85px;
    height: 80px;
    margin-top: 100px;
  }

  .success-title {
    font-size: 18px;
    color: #fff;
    margin-bottom: 40px;
  }

  .logo {
    width: 100px;
    height: 45px;
    margin: 40px 0;
  }

  .success-text {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
    margin: 30px 0;
  }

  .close-btn {
    width: 40%;
    height: 44px;
    background: #ccc;
    color: #666;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    margin-top: 40px;

    &:active {
      opacity: 0.9;
    }
  }
}
</style>
