<template>
  <div class="wrapper-item bac-white" @click="clickItem">
    <div class="custom-item clearfix">
      <img src="./../../../assets/lundbeck/department.png" class="avatar" />
      <div class="custom-item-right">
        <div class="header clearfix">
          <div class="name" style="flex: 1">
            <span class="name-w e-ellipsis-l1">{{ item.department_name }}</span>
            <!-- <span v-if="item.institution_tier" class="institution_tier">{{ item.institution_tier }}</span> -->
          </div>
        </div>
        <p class="department">
          <span>医生数：<span class="count-num">{{ item.physician_no }}</span></span>
          <!-- <span>科室数：<label class="count-num">100</label></span> -->
        </p>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
import beforeFavoriteMixins from "@/mixins/beforeFavoriteMixins";
export default {
  components: {},
  mixins: [beforeFavoriteMixins],
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    parting: {
      type: String,
      default() {
        return "";
      },
    },
    physicianSegment: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    clickItem(item) {
      this.$emit("clickItem", item);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/var.scss";
.box {
  border-top: none;
}
.custom-item {
  display: flex;
  align-items: center;
  // background-color: #ffffff;
  padding: 10px 10px 10px 10px;
  // margin-bottom: 10px;
  .avatar {
    width: 50px;
    height: 50px;
  }
  .custom-item-right {
    flex: 1;
    font-size: 12px;
    text-align: left;
    padding-left: 10px;
    @include suggest-detail-header();
    .count {
      // float: right;
      font-size: 19px;
      color: #b2b2b2;
      .count-color {
        color: #ab351c;
        min-width: 32px;
      }
    }
    .name {
      display: flex;
      align-items: center;
      .name-w {
        flex: 1;
      }
    }
    .department {
      display: flex;
      justify-content: space-between;
      // font-size: 14px;
      padding: 6px 0 4px 0;
      .count-num {
        color: #aa341b;
        font-weight: bold;
        border-bottom: 1px solid #aa341b;
      }
    }
    .address {
      color: #b2b2b2;
      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      .hospital {
        flex: 1;
        min-width: 0;
        width: 0;
        line-height: 20px;
        span {
          display: block;
        }
      }
      .cube-btn {
        // width: 84px;
        padding: 4px 8px;
        font-size: 11px;
        border-radius: 20px;
      }
    }
  }
}
.institution_tier {
  font-size: 12px;
}
.wrapper-item {
  border-radius: 10px;
  padding: 10px 0;
  border-radius: 5px;
  margin-top: 10px;
  &.top {
    border: 1px solid #80d3ae;
    background: #f1f7f5;
  }
  .score-more {
    display: block;
    font-size: 10px;
    transition: transform 0.3s;
    &.selected {
      transform: rotate(180deg);
    }
    .more-icon {
      color: #d9d9d9;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
.title {
  font-size: 14px;
  color: #000;
  text-align: left;
  padding: 10px;
}
::v-deep .cube-popup-content {
  width: 94%;
}
</style>
