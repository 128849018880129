<template>
  <div class="box" style="padding: 0 10px">
    <ul class="box position-list body-background">
      <!-- 任职状态 - 协会 -->
      <li v-for="(item, index) in changeOfficeList" :key="index">
        <div style="display: flex; align-items: center; width: 80%">
          <span class="icon icon-association">
            <font-awesome-icon
              class="icon"
              :icon="['fa', 'briefcase-medical']"
            />
          </span>
          <span style="margin-left: 5px; line-height: 20px">
            <span v-if="type === 'association'">
              <Tag
                v-if="item.association_tier"
                size="medium"
                round
                plain
                type="primary"
              >{{ item.association_tier }}</Tag>
              {{ item.association_name }}
            </span>
            <span v-if="type === 'journal'">
              <Tag
                v-if="item.journal_tier"
                size="medium"
                round
                plain
                type="primary">{{
                  item.journal_tier
                }}</Tag>
              {{ item.journal_name }}
            </span>
            <span v-if="type === 'school'">{{ item.school_name }}</span>
          </span>
        </div>

        <div class="info-details">
          <span v-if="type === 'association'">
            {{ item.association_title }}
          </span>
          <span v-if="type === 'journal'">
            {{ item.journal_title }}
          </span>
          <span v-if="type === 'school'" style="margin-right: 10px">
            {{ item.degree }}
          </span>
          <div
            v-if="isEdit"
            style="margin-left: 10px"
            @click="deleteItem(index)"
          >
            <font-awesome-icon
              class="delete-icon"
              :icon="['fa', 'fa-minus-circle']"
            />
          </div>
        </div>
      </li>
      <div v-if="isEdit" class="flex">
        <Field
          v-model="dataOne"
          style="width: 50%"
          :border="true"
          class="field-border"
          placeholder="机构"
        />
        <Field
          v-model="dataTwo"
          style="width: 30%"
          :border="true"
          class="field-border"
          placeholder="职位"
        />
        <Icon name="add" class="add-icon" size="24" @click="add" />
      </div>
    </ul>
    <div class="flex" style="margin-top: 20px" v-if="isEdit">
      <Button
        round
        type="info"
        color="#D9D9D9"
        style="height: 30px; width: 80px"
        @click="closeModal"
      >
        取消
      </Button>
      <Button
        round
        type="info"
        color="#aa341b"
        style="height: 30px; width: 80px"
        @click="saveAdd(0)"
      >
        保存
      </Button>
      <Button
        v-if="false"
        round
        type="info"
        color="#aa341b"
        style="height: 30px"
        @click="saveAdd(1)"
      >
        提交公开
      </Button>
    </div>
  </div>
</template>
<script>
import {Field, Icon, Button, Tag} from 'vant';
import Tags from '@/components/Tags';
import * as kol from '@/api/kol';
export default {
  components: {
    Field,
    Icon,
    Button,
    Tag,
  },
  props: {
    educationList: {
      type: Array,
      default() {
        return [];
      },
    },
    baseInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: String,
      default() {
        return '';
      },
    },
    isEdit: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    educationList: {
      handler(val) {
        this.changeOfficeList = [...val];
        this.oldList = [...val];
      },
      immediate: true,
    },
  },
  data() {
    return {
      oldList: [],
      changeOfficeList: [],
      newList: [],
      dataOne: '',
      dataTwo: '',
    };
  },
  mounted() {},
  methods: {
    back() {
      this.$emit('officeStatusFn', false);
    },
    add() {
      switch (this.type) {
        case 'association': {
          this.changeOfficeList.push({
            association_name: this.dataOne,
            association_title: this.dataTwo,
          });
          this.newList.push({
            association_name: this.dataOne,
            association_title: this.dataTwo,
          });
          break;
        }
        case 'journal': {
          this.changeOfficeList.push({
            journal_name: this.dataOne,
            journal_title: this.dataTwo,
          });
          this.newList.push({
            journal_name: this.dataOne,
            journal_title: this.dataTwo,
          });
          break;
        }
        case 'school': {
          this.changeOfficeList.push({
            school_name: this.dataOne,
            degree: this.dataTwo,
          });
          this.newList.push({
            school_name: this.dataOne,
            degree: this.dataTwo,
          });
          break;
        }
        default:
          break;
      }
      this.dataOne = '';
      this.dataTwo = '';
    },
    deleteItem(index) {
      this.changeOfficeList.splice(index, 1);
    },
    async saveAdd(is_public) {
      switch (this.type) {
        case 'association': {
          // 把新添的数据保存进去
          const addPromiseList = this.newList.map(async (item) => {
            let data = {
              ...this.baseInfo,
              ...{
                association_name: item.association_name,
                association_title: item.association_title,
                is_public,
              },
            };
            console.log('开始添加');
            return await kol.createPhysicianAssociationSupplements(data);
          });
          await Promise.all(addPromiseList);
          // 把删除的数据保存进去
          const deletedItems = this.oldList.filter(
            (item) =>
              !this.changeOfficeList.some(
                (el) => el.association_name === item.association_name,
              ),
          );
          const removePromiseList = deletedItems.map(async (item) => {
            console.log('开始模拟删除', item);
            // 如果是老的数据相当于给添加新的一条
            if (item.view_key) {
              let data = {
                ...this.baseInfo,
                ...{
                  association_name: item.association_name,
                  association_title: item.association_title,
                  is_public,
                  is_remove: 1,
                  source_id_to_remove: item.view_key,
                },
              };
              return await kol.createPhysicianAssociationSupplements(data);
            } else {
              return await kol.editPhysicianAssociationSupplements(
                item.physician_association_supplement_id,
                {
                  is_remove: 1,
                  source_id_to_remove: item.physician_association_supplement_id,
                },
              );
            }
          });
          await Promise.all(removePromiseList);
          this.$emit('saveSuccess');
          break;
        }
        case 'journal': {
          // 把新添的数据保存进去
          const addPromiseList = this.newList.map(async (item) => {
            let data = {
              ...this.baseInfo,
              ...{
                journal_name: item.journal_name,
                journal_title: item.journal_title,
                is_public,
              },
            };
            return await kol.createPhysicianJournalSupplements(data);
          });
          await Promise.all(addPromiseList);
          // 把删除的数据保存进去
          const deletedItems = this.oldList.filter(
            (item) =>
              !this.changeOfficeList.some(
                (el) => el.journal_name === item.journal_name,
              ),
          );
          const removePromiseList = deletedItems.map(async (item) => {
            console.log('开始模拟删除', item);
            // 如果是老的数据相当于给添加新的一条
            if (item.view_key) {
              let data = {
                ...this.baseInfo,
                ...{
                  journal_name: item.journal_name,
                  journal_title: item.journal_title,
                  is_public,
                  is_remove: 1,
                  source_id_to_remove: item.view_key,
                },
              };
              return await kol.createPhysicianJournalSupplements(data);
            } else {
              return await kol.editPhysicianJournalSupplements(
                item.physician_journal_supplement_id,
                {
                  is_remove: 1,
                  source_id_to_remove: item.physician_journal_supplement_id,
                },
              );
            }
          });
          await Promise.all(removePromiseList);
          this.$emit('saveSuccess');
          break;
        }
        case 'school': {
          // 把新添的数据保存进去
          const addPromiseList = this.newList.map(async (item) => {
            let data = {
              ...this.baseInfo,
              ...{
                school_name: item.school_name,
                degree: item.degree,
                is_public,
              },
            };
            await kol.createPhysicianEducationSupplements(data);
          });
          await Promise.all(addPromiseList);
          // 把删除的数据保存进去
          const deletedItems = this.oldList.filter(
            (item) =>
              !this.changeOfficeList.some(
                (el) => el.school_name === item.school_name,
              ),
          );
          const removePromiseList = deletedItems.map(async (item) => {
            console.log('开始模拟删除', item);
            // 如果是老的数据相当于给添加新的一条
            if (item.view_key) {
              let data = {
                ...this.baseInfo,
                ...{
                  school_name: item.school_name,
                  degree: item.degree,
                  is_public,
                  is_remove: 1,
                  source_id_to_remove: item.view_key,
                },
              };
              await kol.createPhysicianEducationSupplements(data);
            } else {
              await kol.editPhysicianEducationSupplements(
                item.physician_education_supplement_id,
                {
                  is_remove: 1,
                  source_id_to_remove: item.physician_education_supplement_id,
                },
              );
            }
            await kol.editPhysicianEducationSupplements(
              item.physician_education_supplement_id,
              {is_remove: 1},
            );
          });
          await Promise.all(removePromiseList);
          this.$emit('saveSuccess');
          break;
        }
        default:
          break;
      }
    },
    closeModal() {
      this.$emit('closeModal', this.type);
    },
    resetData() {
      this.oldList = [];
      this.changeOfficeList = [];
      this.newList = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.swipe-wrapper {
  $height: 44px;
  $scroll-height: calc(100% - 44px);
  overflow-y: auto;
  .header {
    position: relative;
    height: $height;
    line-height: $height;
    text-align: center;
    background-color: #edf0f4;
    box-shadow: 0 1px 6px #ccc;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 5;
    .h-title {
      font-size: 14px;
    }
    .cubeic-back {
      font-size: 16px;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 15px;
      color: #aa341b;
    }
  }
  .scroll-container {
    height: $scroll-height;
  }
}
.position-list {
  li {
    padding: 10px 0;
    margin-top: 5px;
    background: #fff;
    font-size: 12px;
    color: #6e6e6d;
    border-radius: 0;
    text-align: left;
    // line-height: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      display: inline-block;
      // color: #b2b2b2;
    }
    .info-details {
      display: flex;
      align-items: center;
      // display: inline-block;
    }
    .icon {
      display: none;
      color: #fff !important;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      text-align: center;

      &.icon-wechat {
        background: #ab351c;
      }
      &.icon-interaction {
        background: #e34457;
      }
      &.icon-meeting {
        background: #b41bb3;
      }
      &.icon-association {
        background: #6c6c6c;
      }

      .icon {
        color: #fff;
        margin-top: 4px;
        width: 10px;
        height: 10px;
        display: inline-block;
      }
    }
    .delete-icon {
      color: #22201f !important;
      width: 19px;
      height: 19px;
    }
  }
}
.add-icon {
  color: #ab351c !important;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.field-border {
  border: 1px solid #dce0e6;
  border-radius: 4px;
  padding: 5px 10px;
  :v-deep .van-field__control {
    -webkit-user-select: auto;
  }
}
</style>
