<template>
  <SwipeDialog title="医生选择" @closeFn="$emit('close')">
    <Tabs
      v-model="tabIndex"
      color="#aa341b"
      style="position: sticky; top: 0px; z-index: 9"
    >
      <Tab
        v-for="item in ['目标医生', '其他医生']"
        :key="item"
        :title="item"
      />
    </Tabs>
    <DoctorList
      v-if="tabIndex === 0"
      style="padding-bottom: 44px"
      :on-select-item="handleSelectItem"
      :selected-items="selectedIdList"
    />
    <DoctorListAllAndNew
      v-if="tabIndex === 1"
      style="padding-bottom: 44px"
      :on-select-item="handleSelectItem"
      :selected-items="selectedIdList"
      @close="$emit('close')"
    />
    <van-button
      type="info"
      block
      style="position: fixed; bottom: 0"
      @click="handleConfirm"
      >确定</van-button
    >
  </SwipeDialog>
</template>

<script>
import DoctorList from '@/views/kol/list.vue';
import DoctorListAllAndNew from './DoctorListAllAndNew.vue';
import SwipeDialog from '@/views/layout/swipeDialog';
import {Button, Tab, Tabs} from 'vant';
import Vue from 'vue';

Vue.use(Button);

export default {
  components: {
    DoctorList,
    SwipeDialog,
    DoctorListAllAndNew,
    Tab,
    Tabs,
  },
  props: {
    multipleMode: {
      type: Boolean,
      default() {
        return false;
      },
    },
    defaultSelectedList: [],
  },
  data() {
    return {
      selectedList: [],
      tabIndex: 0,
    };
  },
  computed: {
    selectedIdList() {
      return this.selectedList?.map((item) => item.physician_id);
    },
  },
  created() {
    this.selectedList = this.defaultSelectedList;
  },
  methods: {
    handleConfirm() {
      this.$emit('select', this.selectedList);
    },
    handleSelectItem(item) {
      const id = item.physician_id;
      if (item.type === 'new') {
        this.selectedList.push(item);
        return;
      }
      if (this.multipleMode) {
        const selectedIndex = this.selectedList.findIndex(
          (item) => item.physician_id === id,
        );

        if (selectedIndex > -1) {
          this.selectedList.splice(selectedIndex, 1);
        } else {
          this.selectedList.push(item);
        }
      } else {
        this.selectedList = [item];
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
