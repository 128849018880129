<template>
  <div class="wrapper-item bac-white">
    <p v-if="item.userName" class="user-name clearfix">{{ item.userName }}</p>
    <div class="custom-item clearfix" @click="clickItem">
      <img :src="(item.sex === '男' ? portraitMale : (item.sex === '女' ? portraitFemale : portrait))" class="avatar" />
      <div class="custom-item-right">
        <div class="header clearfix">
          <div class="name" style="flex: 1">
            <span class="name-w">{{ item.physician_name }}</span>
            <span v-if="false && item.ex_data_support" class="tag-yellow">外</span>
            <span v-if="item.is_sync" class="tag-yellow t-update">更</span>
            <span v-if="item.is_wechat">
              <img class="weixin" src="@/assets/svg/weixin.svg" alt="" />
            </span>
            <span v-if="item.territory_id_agent" class="tag-yellow t-white">
              代管{{ item.territory_id_agent }}
            </span>
          </div>
        </div>
        <p class="department">
          {{ item.department_name }} {{ item.professional_title }}
        </p>
        <div class="address">
          <div class="hospital">
            <span class="e-ellipsis-l1">{{ item.institution_name }}</span>
          </div>
        </div>
      </div>
      <div class="parting-label">
        <div
          v-if="physicianSegment && item.product_name == 'Brintellix'"
          class="parting-label-child"
        >
          <div class="p-relative">
            {{ physicianSegment[0] }}
            <span class="p-abs">
              {{ physicianSegment.substring(1, physicianSegment.length) }}
            </span>
          </div>
          <!-- <div class="p-hidden">{{ physicianSegment.substring(1, physicianSegment.length) }}</div> -->
        </div>
        <div
          v-if="physicianSegment && item.product_name != 'Brintellix'"
          class="parting-label-child"
        >
          {{ physicianSegment }}
        </div>
        <div v-if="parting" class="parting-label-child">
          {{ parting }}
        </div>
      </div>
      <div v-if="item.is_favorite" class="fav-hcp">
        <Icon name="star" />
      </div>
    </div>
    <div class="suggestion">
      <AzilectSuggestion :physician-id="item.physician_id" :product-id="'Azilect_000001'" :show-actions="true" :show-offline-actions="physicianSegment.indexOf('A') === 0" />
    </div>

    <slot></slot>
  </div>
</template>
<script>
// import BrintellixSuggestion from '@/views/hcp/components/NBA/BrintellixSuggestion.vue';
import AzilectSuggestion from '@/views/hcp/components/NBA/Azilect.vue';
import beforeFavoriteMixins from '@/mixins/beforeFavoriteMixins';
import {Icon} from 'vant';
export default {
  components: {
    Icon,
    AzilectSuggestion,
    // BrintellixSuggestion,
  },
  mixins: [beforeFavoriteMixins],
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    parting: {
      type: String,
      default() {
        return '';
      },
    },
    physicianSegment: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      portrait: require("@/assets/lundbeck/portrait-white.png"),
      portraitMale: require("@/assets/lundbeck/portrait-male-white.png"),
      portraitFemale: require("@/assets/lundbeck/portrait-female-white.png"),
    };
  },
  computed: {
    isKol() {
      if (
        this.$route.name === 'KolHome' ||
        this.$route.name === 'doctorListHome'
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    clickItem(item) {
      this.$emit('clickItem', item);
    },
    enterVisit(item) {
      this.$router.push({
        path: `/kol/visit-create?physician_id=${item.physician_id}`,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/var.scss';
.box {
  border-top: none;
}
.custom-item {
  display: flex;
  border-radius: 10px 10px 0 0;
  align-items: flex-start;
  position: relative;
  background-color: #aa341b;
  background-image: linear-gradient(to bottom, #aa341b, #aa341b);
  padding: 20px 10px 10px 10px;
  color: #eee;
  // margin-bottom: 10px;
  .name {
    color: #fff !important;
  }
  .avatar {
    width: 40px;
    height: 40px;
    margin: 5px 10px;
    border-radius: 50%;
  }
  .custom-item-right {
    flex: 1;
    font-size: 12px;
    text-align: left;
    padding-left: 10px;
    @include suggest-detail-header();
    .count {
      // float: right;
      font-size: 19px;
      color: #b2b2b2;
      .count-color {
        color: #ab351c;
        min-width: 32px;
      }
    }
    .department {
      padding: 6px 0 4px 0;
    }
    .address {
      color: #eee;
      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      .hospital {
        flex: 1;
        min-width: 0;
        width: 0;
        line-height: 20px;
        span {
          display: block;
        }
      }
      .cube-btn {
        // width: 84px;
        padding: 4px 8px;
        font-size: 11px;
        border-radius: 20px;
      }
    }
  }
}
.wrapper-item {
  border-radius: 10px;
  padding: 0 0 10px 0;
  margin-top: 10px;
  margin-bottom: 40px;
  &.top {
    border: 1px solid #80d3ae;
    background: #f1f7f5;
  }
  .score-more {
    display: block;
    font-size: 10px;
    transition: transform 0.3s;
    &.selected {
      transform: rotate(180deg);
    }
    .more-icon {
      color: #d9d9d9;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
.title {
  font-size: 14px;
  color: #000;
  text-align: left;
  padding: 10px;
}
::v-deep .cube-popup-content {
  width: 94%;
}
@import '@/styles/card-item-popup.scss';
.badge-icon {
  display: block;
  font-size: 10px;
  line-height: 16px;
}
.btn-follow {
  float: right;
  padding: 2px 13px;
  background-color: #aa341b;
  border-radius: 10px;
  color: #fff;
}
.cube-follow-btn {
  width: initial !important;
  padding: 4px 8px !important;
  font-size: 12px;
  border-radius: 20px;
  & + .cube-follow-btn {
    margin-left: 10px;
  }
  .dimension-icon {
    margin-right: 2px;
  }
}
.Badge-tui {
  ::v-deep {
    .van-badge {
      padding: 2px 8px;
      top: -12px;
      right: -42px;
      -webkit-transform-origin-x: 0;
      transform: scale(0.8);
      // right: initial;
    }
  }
}
.name-suffix {
  display: inline-block;
  margin-left: 4px;
}
.user-name {
  font-size: 15px;
  text-align: left;
  padding: 5px 15px;
}
.parting-label {
  display: flex;
  position: relative;
  top: -17px;
  right: -7px;
  .parting-label-child {
    padding: 5px 10px;
    background: #fff;
    border-radius: 0px 8px 0px 8px;
    font-size: 13px;
    font-family: Arial;
    font-weight: 700;
    color: #aa341b;
    max-width: 4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    & + .parting-label-child {
      margin-left: 5px;
    }
  }
}
.tag-yellow {
  padding: 0 1px;
  color: white;
  font-weight: bold;
  -webkit-transform: skewX(-12deg);
  transform: skewX(-12deg);
  border-radius: 1px;
  display: inline-block;
  font-size: 10px;
  background-color: #ffbe47;
  margin-left: 5px;
  &.t-update {
    background-color: #89d329;
  }
  &.t-white {
    color: #000;
    background-color: #ffffff;
  }
}
.p-relative {
  // display: flex;
  // position: relative;
  // width: 60px;
  // text-align: left;
  .p-hidden {
    // visibility: hidden;
    // scale: 0.7;
    display: none;
  }
  .p-abs {
    vertical-align: sub;
    font-size: 10px;
    // scale: 0.6;
    // position: absolute;
    // bottom: -3px;
    // left: 3px;
  }
}
.weixin {
  width: 14px;
  height: 14px;
  margin-left: 5px;
}
.name-d {
  margin-left: 5px;
}
.fav-hcp {
  position: absolute;
  right: 10px;
  top: 35px;
  font-size: 24px;
  color: #ffbe47;
}
.fav-kol {
  margin-top: -10px;
  text-align: right;
  font-size: 22px;
  color: #ffbe47;
}
.suggestion {
  padding: 0;
  margin: 0;
}
.buttons {
  margin: 0 auto;
  padding-bottom: 20px;
}
</style>
