import request from '@/utils/request';

// 获取医生微信绑定状态
// export function getPhysicianWeixin(physician_id) {
//   return request({
//     url: `/api/standard/odata/PhysicianWeixins?$filter=physician_id eq '${physician_id}'`,
//     method: 'get',
//   });
// }

// 微信内容相关统计
export function getContentStats(physician_id) {
  return request({
    url: `/api/standard/odata/VStatPhysicianContentLogs?$filter=physician_id eq '${physician_id}'`,
    method: 'get',
  });
}

// 记录发送内容
export function logContentSend(data) {
  return request({
    url: `/api/gateway/ContentLog/Send`,
    method: 'post',
    data: data,
  });
}

// 记录打开内容
export function logContentOpen(data) {
  return request({
    url: `/api/gateway/ContentLog/Open`,
    method: 'post',
    data: data,
  });
}

// 记录持续打开内容
export function logContentOpenDuration(data) {
  return request({
    url: `/api/gateway/ContentLog/RefreshOpenDuration`,
    method: 'post',
    data: data,
  });
}

// 记录转发内容
export function logContentForward(data) {
  return request({
    url: `/api/gateway/ContentLog/Forward`,
    method: 'post',
    data: data,
  });
}

// 查询医生微信详情
export function getPhysicianWechatDetail(physician_id) {
  return request({
    url:
      '/api/gateway/PhysicianWeChat/GetPhysicianWeChat?physician_id=' +
      physician_id,
    method: 'get',
  });
}

// 查询医生微信详情
export function getPhysicianWechatDetailByOpenId(open_id) {
  return request({
    url: '/api/gateway/PhysicianWeChat/GetPhysicianWeChat?open_id=' + open_id,
    method: 'get',
  });
}

// 查询内容发送详情
export function getLogContentSend(send_id) {
  return request({
    url: `/api/gateway/ContentLog/GetLogSend?log_content_send_id=` + send_id,
    method: 'get',
  });
}

// 查询内容详情
export function getContent(send_id) {
  return request({
    url: `/api/gateway/ContentLog/GetContent?log_content_send_id=` + send_id,
    method: 'get',
  });
}

// 绑定医生
export function weixinBind(data) {
  return request({
    url: `/api/gateway/PhysicianWechat/Bind`,
    method: 'post',
    data: data,
  });
}

// 签署consent
export function signConsent(data) {
  return request({
    url: `/api/gateway/PhysicianWechat/Consent`,
    method: 'post',
    data: data,
  });
}

// 解除绑定医生
export function weixinUnbind(data) {
  return request({
    url: `/api/gateway/PhysicianWechat/Unbind`,
    method: 'post',
    data: data,
  });
}

// 请求审批解除绑定医生
export function weixinUnbindApply(id) {
  return request({
    url: `/api/standard/odata/PhysicianWeixins('${id}')`,
    method: 'patch',
    data: {unbind_pending_approval: 1},
  });
}

// 审批解除绑定医生完成
export function weixinUnbindFinishApplication(id) {
  return request({
    url: `/api/standard/odata/PhysicianWeixins('${id}')`,
    method: 'patch',
    data: {unbind_pending_approval: 0},
  });
}

// 取消签署consent
export function unsignConsent(data) {
  return request({
    url: `/api/gateway/PhysicianWechat/Unconsent`,
    method: 'post',
    data: data,
  });
}

// 请求审批取消签署consent
export function weixinUnconsentApply(id) {
  return request({
    url: `/api/standard/odata/PhysicianWeixins('${id}')`,
    method: 'patch',
    data: {unconsent_pending_approval: 1},
  });
}

// 审批取消签署consent完成
export function weixinUnconsentFinishApplication(id) {
  return request({
    url: `/api/standard/odata/PhysicianWeixins('${id}')`,
    method: 'patch',
    data: {unconsent_pending_approval: 0},
  });
}

// 查询发送给医生的内容
export function getAllContentSend(physician_id) {
  return request({
    url: `/api/standard/odata/LogContentSends?$filter=receiver_physician_id eq '${physician_id}'&$orderby=send_time desc&$expand=content`,
    method: 'get',
  });
}

// 查询医生打开的内容
export function getAllContentOpen(physician_id) {
  return request({
    url: `/api/standard/odata/LogContentOpens?$filter=reader_physician_id eq '${physician_id}'&$orderby=open_time desc&$expand=content`,
    method: 'get',
  });
}
